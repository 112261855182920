import ServerGridConfig from "../../../config/ServerGridConfig";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import useStore from "../../../store";
import { observer } from "mobx-react";
import { vsmCommon } from "../../../config/messages";
import { CurrencyFormat, DateComparator } from "../../../utils/GlobalFunction";
import Unauthorized from "../../../page/commonpage/Unauthorized";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import { PageHeader } from "antd";


const HistoryListComponent = observer((props) => {
	const {
		BookingSalesReceiptReportStore: { list_data, setupGrid, setPageSize, per_page },
		AUTH: { checkPrivileges },
	} = useStore();

	const gridOptions = {
		columnDefs: [
			{
				headerName: "Z form No",
				field: "booking_id",
				filter: "agNumberColumnFilter",
				pinned: "left",
				minWidth: 120,
				width: 120,
			},
			{
				headerName: "Receipt No",
				field: "receipt_no",
			},
			{
				headerName: "Receipt Date",
				field: "date",
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator,
					browserDatePicker: true
				},
			},
			{
				headerName: "Location Name",
				field: "booking.location.name",
				valueGetter: (params) => (params.data && params.data.booking?.location) ? params.data.booking?.location?.name : "N/A",
				
			},
			{
				headerName: "Customer Name",
				field: "booking.booking_customer.full_name",
				valueGetter: (params) => (params.data && params.data.booking?.booking_customer.changed_name) ? params.data.booking?.booking_customer.changed_name : params.data.booking?.booking_customer?.full_name
			},
			{
				headerName: "Amount",
				field: "amount",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data?.amount} />
				},
			},
			{
				headerName: "Amount Type",
				field: "payment_mode.name",
				valueGetter: (params) => (params.data && params.data.payment_mode) ? params.data.payment_mode?.name : "N/A"
				
			},
			{
				headerName: " Doc NO",
				field: "cheque_no",
				valueGetter: (params) => (params.data && params.data.cheque_no) ? params.data.cheque_no : "N/A"
			},
			{
				headerName: "Remarks",
				field: "remarks",
				valueGetter: (params) => (params.data && params.data.remarks) ? params.data.remarks : "N/A"
			},
			{
				headerName: "Cheque Bank Name",
				field: "bank.name",
				valueGetter: (params) => (params.data && params.data.bank) ? params.data.bank.name : "N/A"
			},		
				
			{
				headerName: "Order No",
				field: "booking.co_no",
				valueGetter: (params) => (params.data && params.data.booking) ? params.data.booking?.co_no : "N/A"
			},

			{
				headerName: "Sales Executive Name",
				field: "booking.sales_consultant.name",
				valueGetter: (params) => (params.data && params.data.booking?.sales_consultant) ? params.data.booking?.sales_consultant?.name : "N/A"
			},
			{
				headerName: "Deposit Bank",
				field: "bank_account.bank.name",
				valueGetter: (params) => (params.data && params.data.bank_account) ? params.data.bank_account?.bank?.name : "N/A"
			},
			{
				headerName: "Acc. No",
				field: "bank_account.acc_no",
				filter: "agNumberColumnFilter",
				valueGetter: (params) => (params.data && params.data.bank_account) ? params.data.bank_account?.acc_no : "N/A"
				
			},
			{
				headerName: "Reco Date",
				field: "reco_date",
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator,
					browserDatePicker: true
				},
			},
		],
	};
	ServerGridConfig.options.paginationPageSize = 5000
	ServerGridConfig.options.cacheBlockSize = 5000
	return (
		<>
			{!checkPrivileges("#53006#") ? (
				<Unauthorized />
			) : (
				<PageHeader
					title={BreadcrumbConfig.ReceiptReportRequest.title}
					className="tableAreaSec"
					extra={
						<BreadcrumbComponent items={BreadcrumbConfig.ReceiptReportRequest.path} />
					}
				>
					<div className="listCountNew">
						{/* {AUTH.checkPrivileges("#6305#") && ( */}
						<RecordPerPage
							key="4"
							style={{ width: "150px" }}
							defaultValue={per_page + " per page"}
							onChange={setPageSize}
						/>
					</div>
					<div className="ag-theme-alpine grid_wrapper">
						<AgGridReact
							rowHeight={ServerGridConfig.rowHeight}
							headerHeight={ServerGridConfig.headerHeight}
							rowData={list_data}
							modules={AllModules}
							columnDefs={gridOptions.columnDefs}
							defaultColDef={ServerGridConfig.defaultColDef}
							columnTypes={ServerGridConfig.columnTypes}
							overlayNoRowsTemplate={vsmCommon.noRecord}
							onGridReady={setupGrid}
							gridOptions={ServerGridConfig.options}
						/>
					</div>
				</PageHeader>
			)}
		</>
	);
});

export default HistoryListComponent;
