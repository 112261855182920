import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import ServerGridConfig from "../../../../config/ServerGridConfig";
// import { convertTextToID } from "../../../utils/GlobalFunction";

export default class AccessoryDiscReqHistoryStore {
	AUTH = null
	agGrid = null;
	per_page = 5000;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	// type = {
	// 	pending: "Pending",
	// 	history: "History"
	// }
	// defaultType = this.type.history;




	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		const { api } = params
		var datasource = this.createDatasource(ServerGridConfig.options)
		api.setServerSideDatasource(datasource)
		let name = localStorage.getItem("name");
		if (name && name !== "") {
			const filter = {'location.name': {  filterType: 'text', type :"contains", filter: name }}
			this.agGrid.api.setFilterModel(filter);
			localStorage.removeItem("name");
		}

	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};


	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel
		var final_sort = params.sortModel
		if (final_filter["is_ghost"]) {
			let values_changed = []
			final_filter["is_ghost"].values.forEach(x => {
				values_changed.push(x === "No" ? 0 : 1)
			})
			final_filter["is_ghost"].values = values_changed
		}
		// let loc_short_name = localStorage.getItem("loc_short_name");
		// if (loc_short_name && loc_short_name !== "") {
		// 	final_filter = {
		// 		'location.short_name': {  filterType: 'text', type :"contains", filter: loc_short_name }
		// 	};

		// }
		// localStorage.removeItem("loc_short_name");
		return { final_filter, final_sort }
	}


	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request)

				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					// per_page: params.request.endRow - params.request.startRow,
					per_page: 5000,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}


				this.getList(payload).then((data) => {
					if (data.data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.data.data, data.data.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.colId)
						})
					}
				})
			}
		}
	}

	// call api to get records
	getList = (payload) => {
		return Axios.post(`/reports/booking_sales/fin_pipe_details`, payload).then(({ data }) => {
			if (data.data.data.length) {
				data.data.data.map((item, index) => {
					item.srno = index + 1;
					item.date = item.date ? moment(item.date).format("DD/MM/YYYY") : "N/A";
					item.is_ghost = item.is_ghost === 1 ? "Yes" : "No";
					return null;
				});
			}
			this.list_data =
				data.data && data.data.data.length ? data.data.data : null;
			this.total = data.data.total;
			this.current_page = data.data.current_page;
			return data
		});
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

}

decorate(AccessoryDiscReqHistoryStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	type: observable,
	defaultType: observable,
});
