import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";

export default class ActiveInquiriesStore {
	agGrid = null;
	per_page = LocalGridConfig.options.paginationPageSize;
	dropdown_supplier_list = null;
	dropdown_varify_list = null;
	dropdown_view_list = null;
	current_page = 1;
	list_data = null;
	deleteValues = null;
	viewValues = null;
	total = 0;
	allColumnIds = [];


	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};


	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel();
			var sort = this.agGrid.api.getSortModel();
		}
		this.list_data = null;
		return Axios.get(`/reports/booking_sales/fin_pipe_summary`).then(({ data }) => {
			if (data.data.data.length) {
				data.data.data.map((item, index) => {
					item.srno = index + 1;
					item.status_name = item.status === 1 ? "Yes" : "No";
					return null;
				});
			}

			this.list_data = data.data ? data.data.data : null;
			this.total = data.data.total;
			this.current_page = data.data.current_page;
			var allColumnIds = [];
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.agGrid) {
				this.agGrid.api.setFilterModel(filter);
				this.agGrid.api.setSortModel(sort);
			}
		});
	};
	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	};
}

decorate(ActiveInquiriesStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	viewValues: observable,
	dropdown_supplier_list: observable,
	dropdown_varify_list: observable,
	dropdown_view_list: observable,
	setupGrid: action,
	setPageSize: action,
	setViewValues: action,
	getList: action,
	DeleteData: action,
	onFilterChanged: action,
});
