import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

const NotificationWidgetStructure = observer((props) => {
	const history = useHistory();
	const { className, title, message, createdDate
		, redirectLink
	} = props;

	const handleRedirect = () => {
		if (redirectLink) {
			history.push(redirectLink);
		}
	}

	return (
		<>
			<li className={`widget_row ${className}`}
				onClick={() => handleRedirect()}
			>
				<div className="widget_top">
					<p className="title">{title}</p>
					<p className="date">{createdDate}</p>
				</div>
				<div className="widget_bottom">
					<p>{message}</p>
				</div>
			</li>
		</>
	)
})

export default NotificationWidgetStructure
