import React from "react";
import { Form, Row, Col, } from "antd";
import { observer } from "mobx-react";
import InputComponent from "../../../../component/InputComponent";
import debounce from "lodash/debounce";
import { vsmBharatPassing } from "../../../../config/messages";

const FormComponent = observer((props) => {

	const {
		form, setDisabled,
		id, 
		handleSubmit,
	} = props;

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);


	return (
		<Form
			form={form}
			id={id}
			onFinish={handleSubmit}
			labelCol={{ span: 24 }}
		>
			<Row gutter={30}>				
				<Col xs={{ span: 24 }} sm={{ span: 12 }}>
					<InputComponent
						type="text"
						required
						onChange={handleChange}
						label="Base Price From"
						placeholder="Base Price From"
						name="base_price_from"
						rules={vsmBharatPassing.validation.base_price_from}
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }}>
					<InputComponent
						type="text"
						required
						onChange={handleChange}
						label="Base Price Up To"
						placeholder="Base Price Up To"
						name="base_price_to"
						rules={vsmBharatPassing.validation.base_price_to}
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }}>
					<InputComponent
						type="text"
						required
						onChange={handleChange}
						label="RTO Rate (%)"
						placeholder="RTO Rate"
						name="rto_per"
						rules={vsmBharatPassing.validation.rto_per}
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }}>
					<InputComponent
						type="text"
						required
						onChange={handleChange}
						label="Fixed Amount"
						placeholder="Fixed Amount"
						name="fix_amt"
						rules={vsmBharatPassing.validation.fix_amt}
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }}>
					<InputComponent
						type="text"
						required
						onChange={handleChange}
						label="Hypo. Charges"
						placeholder="Hypo. Charges"
						name="hypothecation_charge"
						rules={vsmBharatPassing.validation.hypothecation_charge}
					/>
				</Col>
			</Row>
		</Form>
	);
});

export default FormComponent;