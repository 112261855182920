import React, { useState, useCallback, useEffect } from "react";
import { Form, Row, Col, Divider, Spin, Button, Checkbox } from "antd";
import { vsmInsuranceQuotation, vsmNotify } from "../../../config/messages";
import { observer } from "mobx-react";
import InputComponent from "../../../component/InputComponent";
import useStore from "../../../store";
import debounce from "lodash/debounce";
import ViewAddOnComponent from "./ViewAddOnComponent";
import { addonInsurance, addonNormalInsurance, add_on_categoryID, CurrencyFormat, insurance_quotation_type, passingTypeGoodsCaring, tpInsurance } from '../../../utils/GlobalFunction'
import differenceBy from 'lodash/differenceBy'
import UploadNCBProof from "../offers/component/UploadNCBProof";

const InsuranceQuotationFormComponent = observer((props) => {
	const { isView = false, setDisabled = () => { }, type, typeID, visibleDetails = true,
		setvisibleDetails = () => { }, form, addOns, setaddon_verified, extraAddons = null, setextraAddons, fileList = [], cat_id } = props;
	const [fetchNCB, setFetchNCB] = useState(true);
	const [fetchCompany, setFetchCompany] = useState(true);
	const [fetchProduct, setFetchProduct] = useState(true);
	const [fetchCategory, setFetchCategory] = useState(true);
	const [accessories, setaccessories] = useState([])
	const [tpPeriod, settpPeriod] = useState();
	const [nilDepAddOn, setnilDepAddOn] = useState(null);
	const [quoteValues, setQuoteValues] = useState();


	const {
		InsuranceQuotationStore,
		InsuranceOfferStore,
		InsuranceQuotationStore: {
			generatedValues,
			getInsCategoryList,
			getInsProductList,
			getInsCompanyList,
			getInsNCBList,
		},
	} = useStore();
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce((type = null) => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
		calculate(form.getFieldsValue(), type);
	}, 500);

	const resetProduct = () => {
		InsuranceQuotationStore.dropdown_ins_product_list = null
		setFetchProduct(true)
		form.setFieldsValue({
			prod_id: null,
		})

	}

	const handleTPPeriodChange = () => {
		const tp_period = form?.getFieldValue("tp_period");
		if (tp_period !== undefined) {
			let tpInsurance = tpPeriod.filter(x => x.years === tp_period)[0]["rate"];
			props?.form?.setFieldsValue({
				tp_insurance: tpInsurance
			})
		}
	}

	const calculate = (data, type) => {
		let idv_ex_showroom = 0
		if (data && data.ex_showroom) {
			idv_ex_showroom = data.ex_showroom - (data.ex_showroom * data.dep_rate_exs) / 100
		}

		let idv_non_ele_acc = 0
		if (data && data?.non_ele_cost) {
			idv_non_ele_acc = data.non_ele_cost - (data.non_ele_cost * data.dep_rate_nea) / 100
		}

		let idv_ele_acc = 0
		if (data && data?.ele_acc_cost) {
			idv_ele_acc = data.ele_acc_cost - (data.ele_acc_cost * data.dep_rate_ea) / 100
		}

		let idv_total = 0
		idv_total = idv_ex_showroom + idv_ele_acc + idv_non_ele_acc + data.idv_cng;


		let acc_cng_idv = 0;
		acc_cng_idv = idv_non_ele_acc + idv_ele_acc + data.idv_cng;

		let ins_acc_cng_premium = 0
		ins_acc_cng_premium = (acc_cng_idv * data.ins_acc_rate) / 100;


		let ins_premium = 0;
		ins_premium = (idv_ex_showroom * data.ins_rate) / 100;

		let od = 0
		od = ins_acc_cng_premium + ins_premium;

		let od_disc_load = 0;
		od_disc_load = ((ins_premium) * data.od_per) / 100;


		let ncb_disc = 0;
		if (data.od_disc_load_flag === 10) {
			ncb_disc = ((ins_premium - od_disc_load) * data.ncb_per) / 100;
		}
		if (data.od_disc_load_flag === 20) {
			ncb_disc = ((ins_premium + od_disc_load) * data.ncb_per) / 100;
		}

		//-----  IF Antitheft is optional then Anti Theft Disc will be zero ------//

		if (!data.optional_antitheft) {
			data.anti_theft_per = 0;
		}
		else if (data.optional_antitheft) {
			if (props.type === 'add' && generatedValues) {
				data.anti_theft_per = generatedValues.quote.anti_theft_per;
			}
			if (props.type === 'edit' && quoteValues) {
				data.anti_theft_per = quoteValues.anti_theft_per;
			}
		}
		//----- ----------------------------------------------------  ------//

		let anti_theft_disc = 0;
		if (data.od_disc_load_flag === 10) {
			anti_theft_disc = ((ins_premium - od_disc_load - ncb_disc) * data.anti_theft_per) / 100;
		}
		if (data.od_disc_load_flag === 20) {
			anti_theft_disc = ((ins_premium + od_disc_load - ncb_disc) * data.anti_theft_per) / 100;
		}

		if (anti_theft_disc > 500) {
			anti_theft_disc = 500;
		}

		let normal_insurance = 0;
		if (data.od_disc_load_flag === 10) {
			normal_insurance = (od - ncb_disc - anti_theft_disc) - od_disc_load;
		}
		if (data.od_disc_load_flag === 20) {
			normal_insurance = (od - ncb_disc - anti_theft_disc) + od_disc_load;
		}
		let nil_dep = 0;
		if (nilDepAddOn) {
			nil_dep = nilDepAddOn.add_on_usage.charge_type === 10 ? nilDepAddOn.rate : (idv_total * nilDepAddOn.rate) / 100
		}

		let nil_dep_insurance = 0;
		nil_dep_insurance = data.fixed_insurance + nil_dep;

		let od_final = 0;
		if (addonInsurance.includes(cat_id)) {
			od_final = normal_insurance + nil_dep_insurance;
		}
		else {
			od_final = normal_insurance;
		}

		data.add_on_charges = 0;
		if (data?.add_on_quotes?.length > 0) {
			data.add_on_quotes.map(obj => {
				if (obj.charge_type === 10) {
					obj.amount = parseInt(obj.rate);
				}
				if (obj.charge_type === 20) {
					obj.amount = parseInt((idv_ex_showroom * obj.rate) / 100);
				}
				if (obj.is_selected === 1 || obj.is_selected === true) {
					data.add_on_charges += Number(obj.amount);
				}
				return null;
			})
		}

		let net_premium = 0;
		let tp_period = Number(data.tp_period);
		// console.log('data', data, quoteValues, InsuranceOfferStore.insurance_detail?.ins_vehicle?.passengers);
		if (type === 'tp_period' && tp_period !== undefined) {

			let passengers = InsuranceOfferStore.insurance_detail?.ins_vehicle?.passengers;

			data.tp_cng_insurance = (quoteValues.tp_cng_rate * tp_period); //CNG Insurance * TP Period

			let tp_liability = data.tp_liability;

			let ll_driver = data.ll_driver;

			let cpa = data.cpa;

			let pad = data.pad;

			let pap = data.pap;

			if ([0, 1].includes(tp_period)) {
				ll_driver = quoteValues.ll_rate;
				pad = quoteValues.pad_rate;
				pap = quoteValues.pap_rate;
				cpa = quoteValues.cpa_rate;
			}
			else {
				ll_driver = quoteValues.ll_rate * tp_period;
				cpa = quoteValues.cpa_rate_3;
				pad = quoteValues.pad_rate * tp_period;
				pap = quoteValues.pap_rate * tp_period;
			}

			if (passengers > 6) {
				tp_liability = quoteValues.tp_liability_above_6 * passengers * tp_period;
			}
			else if (passengers <= 6) {
				tp_liability = quoteValues.tp_liability_upto_6 * passengers * tp_period;
			}

			data.tp_liability = tp_liability;

			data.ll_driver = ll_driver;

			data.cpa = cpa;

			data.pad = pad;

			data.pap = pap;

		}

		//-----  IF optional_cng_insurance is optional then tp_cng_insurance will be zero ------//
		if (tpInsurance.includes(form?.getFieldValue("cat_id")) && !data.optional_cng_insurance) {
			data.tp_cng_insurance = 0;
		}
		else if (data.idv_cng <= 0 && addonNormalInsurance.includes(form?.getFieldValue("cat_id"))) {
			data.tp_cng_insurance = 0;
		}
		else if (props.type === 'add' && generatedValues && generatedValues?.quote && !data.tp_cng_insurance) {
			data.tp_cng_insurance = generatedValues.quote.tp_cng_insurance || 0;
		}

		//----- ----------------------------------------------------  ------//

		if (addonNormalInsurance.includes(cat_id)) {
			net_premium = od_final + data.tp_insurance + data.tp_cng_insurance + data.tp_liability + data.ll_driver + data.cpa + data.pad + data.pap + data.add_on_charges;
			if (passingTypeGoodsCaring === InsuranceQuotationStore?.editValues?.ins_vehicle?.pass_cat_id) {
				net_premium = net_premium + data.gc_crossed_weight_charge + data.nfpp + data.ll_cleaner;
			}
		}
		else if (tpInsurance.includes(cat_id)) {
			net_premium = data.tp_insurance + data.tp_cng_insurance + data.ll_driver + data.tp_liability + data.cpa + data.pad + data.pap;
		}

		let gst = 0;
		gst = (net_premium * data.gst_per) / 100;

		let total_premium = 0;
		total_premium = net_premium + gst;



		let disc_premium = 0;
		disc_premium = total_premium - data.passback_req;

		form.setFieldsValue({
			ex_showroom: data.ex_showroom,
			dep_rate_exs: data?.dep_rate_exs,
			idv_ex_showroom: idv_ex_showroom ? Math.round(idv_ex_showroom).toFixed(0) : idv_ex_showroom, // ex_Showroom - (ex_showroom * dep_rate_exs) / 100,
			non_ele_cost: data?.non_ele_cost,
			dep_rate_nea: data?.dep_rate_nea,
			idv_non_ele_acc: idv_non_ele_acc ? Math.round(idv_non_ele_acc).toFixed(0) : idv_non_ele_acc, //  non_ele_cost - (non_ele_cost * dep_rate_nea) / 100
			ele_acc_cost: data?.ele_acc_cost,
			dep_rate_ea: data?.dep_rate_ea,
			idv_ele_acc: idv_ele_acc ? Math.round(idv_ele_acc).toFixed(0) : idv_ele_acc, //  ele_acc_cost - (ele_acc_cost * dep_rate_ea) / 100
			idv_cng: data.idv_cng,
			idv_total: idv_total ? Math.round(idv_total).toFixed(0) : idv_total, // Ex-Showroom IDV + Ele. Accessory IDV + Non-Ele. Accessory IDV + CNG IDV
			ins_rate: data.ins_rate,
			ins_premium: ins_premium,
			ins_acc_rate: data.ins_acc_rate,
			ins_acc_cng_premium: ins_acc_cng_premium ? parseFloat(ins_acc_cng_premium).toFixed(2) : ins_acc_cng_premium,  // (Acc. & CNG IDV * Insurance Rate for Accessory & CNG ) / 100
			od: od ? parseFloat(od).toFixed(2) : od,  // Insurance Premium + Acc. & CNG Premium
			ncb_per: data.ncb_per,
			ncb_disc: ncb_disc ? parseFloat(ncb_disc).toFixed(2) : ncb_disc, //If OD Type = Discount NCB Disc = ((Insurance Premium - OD Disc) * NCB Percentage) / 100 | If OD Type = Loading NCB Disc = ((Insurance Premium + OD Disc) * NCB Percentage) / 100
			anti_theft_per: data.anti_theft_per,
			anti_theft_disc: anti_theft_disc ? parseFloat(anti_theft_disc).toFixed(2) : anti_theft_disc, // If OD Type = Discount ((Insurance Premium - OD Disc - NCB Disc) * anti_thept_per) / 100 | If OD Type = Loading ((Insurance Premium + OD Disc - NCB Disc) * anti_thept_per) / 100 | If Anti-Theft Discount > 500 Then 500 Max fixed
			od_disc_load_flag: data.od_disc_load_flag,
			od_per: data.od_per,
			add_on_quotes: data.add_on_quotes,
			od_disc_load: od_disc_load ? parseFloat(od_disc_load).toFixed(2) : od_disc_load,    // (Insurance Premium * OD Percentage) / 100
			normal_insurance: normal_insurance,
			fixed_insurance: data.fixed_insurance,
			nil_dep: nil_dep,
			nil_dep_insurance: parseInt(nil_dep_insurance), //Fixed Insurance Cost of Product + Nil_Dep
			od_final: od_final,
			tp_period: data.tp_period,

			ins_gc_gvw: data.ins_gc_gvw,
			ins_gc_crossed_kgs: data.ins_gc_crossed_kgs,
			ins_gc_additional_charges: data.ins_gc_additional_charges,
			gc_additional_weight: data.gc_additional_weight,
			gc_crossed_kgs_factor: data.gc_crossed_kgs_factor,
			gc_crossed_weight_charge: data.gc_crossed_weight_charge,
			nfpp: data.nfpp,
			ll_cleaner: data.ll_cleaner,

			tp_liability: data.tp_liability,
			tp_insurance: data.tp_insurance,
			tp_cng_insurance: data.tp_cng_insurance,
			ll_rate: data.ll_rate,
			ll_driver: data.ll_driver,
			cpa: data.cpa,
			pad: data.pad,
			pap: data.pap,
			add_on_charges: data.add_on_charges,
			net_premium: net_premium, //OD Final + Third Party Insurance + Third Party CNG Insurance + Legal Liability + CPA + PAD + PAP + Add-On Charges
			gst_per: data.gst_per,
			gst: gst,
			total_premium: total_premium ? parseFloat(total_premium).toFixed(2) : total_premium,
			passback_req: data.passback_req,
			disc_premium: disc_premium,
		})
		forceUpdate();
	}

	const validate = () => {
		let isValid = true;
		if ((form.getFieldValue("cat_id") === null || form.getFieldValue("cat_id") === undefined) || (form.getFieldValue("company_id") === null || form.getFieldValue("company_id") === undefined) || (form.getFieldValue("prod_id") === null || form.getFieldValue("prod_id") === undefined)) {
			isValid = false;
		}
		return isValid;
	}
	const getAddOnText = () => {
		let commasepratedValue = '';
		if (addOns?.length > 0) {
			commasepratedValue += 'Selected Addons: '
			if (addOns.length === 1) {
				commasepratedValue += addOns[0]["name"];
			}
			else {
				commasepratedValue += Array.prototype.map.call(addOns, function (item) { return item.name; }).join(",");
			}
		}
		return commasepratedValue;

	}

	const generateQuotation = () => {
		if (validate()) {
			setextraAddons(null)
			let formData;
			formData = {
				id: InsuranceQuotationStore.editValues.id,
				prod_id: form.getFieldValue("prod_id")
			}
			setvisibleDetails(true)
			InsuranceQuotationStore.generateQuotation(formData).then((data) => {
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
				data = data.data;
				setnilDepAddOn(data.quote.nil_dep_add_on);
				let add_on_charges = 0;
				let verified = true;

				if (addOns && addOns?.length > 0) {
					let diffrenceArr = differenceBy(addOns, data.add_on_rates, 'add_on_id')
					if (diffrenceArr.length > 0) {
						let nildepIndex = diffrenceArr.findIndex(x => x.id === 11);
						if (nildepIndex !== -1) {
							diffrenceArr.splice(nildepIndex, 1);
						}
					}

					if (diffrenceArr && diffrenceArr.length > 0) {
						verified = false;
						if (diffrenceArr.length === 1) {
							setextraAddons(diffrenceArr[0]["name"]);
						}
						else {
							let commasepratedValue = Array.prototype.map.call(diffrenceArr, function (item) { return item.name; }).join(",");
							setextraAddons(commasepratedValue);
						}
					}
					if (data.add_on_rates.length > 0) {
						data.add_on_rates.map(obj => {
							obj.rate = parseFloat(obj.rate).toFixed(3);
							let index = addOns.findIndex(x => x.id === obj.add_on_id);
							obj.selectedbySC = false;
							if (index !== -1) {
								if(obj.is_included === 1){
									obj.selectedbySC = true;
								}
								obj.verified = <p className="greenText">Okay</p>;
								obj.verification = true;
							}
							if (obj.is_included === 1 && index === -1) {
								obj.verified = <p className="redText">Not Selected</p>;
								verified = false;
								obj.verification = false;
							}
							if (obj.is_included === 0 && index === -1) {
								obj.verified = <p className="greenText">Okay</p>;
								obj.verification = true;
							}
							return null;
						})
					}
				}
				setaddon_verified(verified);
				if (data.add_on_rates.length > 0) {
					data.add_on_rates.map(obj => {
						obj.rate = parseFloat(obj.rate).toFixed(3);
						if (obj.charge_type === 10) {
							obj.amount = Math.round(obj.rate).toFixed(0);
						}
						if (obj.charge_type === 20) {
							obj.amount = Math.round((data.quote?.idv_ex_showroom * obj.rate) / 100).toFixed(0);
						}
						if (obj.is_included === 1) {

							if (obj.verification === true) {
								obj.is_selected = true;
								add_on_charges += Number(obj.amount);
							}
							if (obj.verification === undefined) {
								obj.is_selected = true;
								add_on_charges += Number(obj.amount);
							}
						}
						return null;
					})
				}
				setQuoteValues(data.quote);
				let tp_insurance = data.quote.tp_insurance;
				if (data.tp_rates.length > 0) {
					settpPeriod(data.tp_rates);
					let tpInsurance = data.tp_rates.filter(x => x.years === data.quote?.tp_period);
					tp_insurance = tpInsurance.length > 0 ? tpInsurance[0]["rate"] : tp_insurance;
				}

				if (data.quote?.idv_cng === 0 && addonNormalInsurance.includes(form?.getFieldValue("cat_id"))) {
					data.quote.net_premium = data.quote.net_premium - data.quote.tp_cng_insurance;
					data.quote.tp_cng_insurance = 0;
				}
				let net_premium = data.quote.net_premium;
				if (addonNormalInsurance.includes(cat_id)) {
					net_premium = net_premium + add_on_charges;
				}

				let gst = 0;
				gst = (net_premium * data.quote.gst_per) / 100;

				let total_premium = 0;
				total_premium = net_premium + gst;



				let disc_premium = 0;
				disc_premium = total_premium - data.quote.passback_req;

				form.setFieldsValue({
					ex_showroom: data.quote?.ex_showroom,
					optional_antitheft: true,
					optional_cng_insurance: true,
					dep_rate_exs: data.quote?.dep_rate_exs,
					idv_ex_showroom: data.quote?.idv_ex_showroom, // ex_Showroom - (ex_showroom * dep_rate_exs) / 100,
					non_ele_cost: data.quote?.non_ele_cost,
					dep_rate_nea: data.quote?.dep_rate_nea,
					idv_non_ele_acc: data.quote?.idv_non_ele_acc, //  non_ele_cost - (non_ele_cost * dep_rate_nea) / 100
					ele_acc_cost: data.quote?.ele_acc_cost,
					dep_rate_ea: data.quote?.dep_rate_ea,
					idv_ele_acc: data.quote?.idv_ele_acc, //  ele_acc_cost - (ele_acc_cost * dep_rate_ea) / 100
					idv_cng: data.quote?.idv_cng,
					idv_total: data.quote?.idv_total, // Ex-Showroom IDV + Ele. Accessory IDV + Non-Ele. Accessory IDV + CNG IDV
					ins_rate: data.quote?.ins_rate,
					add_on_quotes: data.add_on_rates,
					ins_premium: data.quote?.ins_premium,
					ins_acc_rate: data.quote?.ins_acc_rate,
					ins_acc_cng_premium: data.quote?.ins_acc_cng_premium,  // (Acc. & CNG IDV * Insurance Rate for Accessory & CNG ) / 100
					od: data.quote?.od,  // Insurance Premium + Acc. & CNG Premium
					ncb_per: data.quote?.ncb_per,
					ncb_disc: data.quote?.ncb_disc,  //If OD Type = Discount NCB Disc = ((Insurance Premium - OD Disc) * NCB Percentage) / 100 | If OD Type = Loading NCB Disc = ((Insurance Premium + OD Disc) * NCB Percentage) / 100
					anti_theft_per: data.quote?.anti_theft_per,
					anti_theft_disc: data.quote?.anti_theft_disc,  /// If OD Type = Discount ((Insurance Premium - OD Disc - NCB Disc) * anti_thept_per) / 100 | If OD Type = Loading ((Insurance Premium + OD Disc - NCB Disc) * anti_thept_per) / 100 | If Anti-Theft Discount > 500 Then 500 Max fixed
					od_disc_load_flag: data.quote?.od_disc_load_flag,
					od_per: data.quote?.od_per,
					od_disc_load: data.quote?.od_disc_load,     // (Insurance Premium * OD Percentage) / 100
					normal_insurance: data.quote?.normal_insurance,
					fixed_insurance: data.quote?.fixed_insurance,
					nil_dep: data.quote?.nil_dep,
					nil_dep_insurance: data.quote?.nil_dep_insurance, //Fixed Insurance Cost of Peduct + Nil_Dep
					od_final: data.quote?.od_final,
					tp_period: data.quote?.tp_period,
					tp_insurance: tp_insurance,
					tp_cng_insurance: data.quote?.tp_cng_insurance,
					ll_rate: data.quote?.ll_rate,
					tp_liability: data.quote?.tp_liability,
					ins_gc_gvw: data.quote?.ins_gc_gvw,
					ins_gc_crossed_kgs: data.quote?.ins_gc_crossed_kgs,
					ins_gc_additional_charges: data.quote?.ins_gc_additional_charges,
					gc_additional_weight: data.quote?.gc_additional_weight,
					gc_crossed_kgs_factor: data.quote?.gc_crossed_kgs_factor,
					gc_crossed_weight_charge: data.quote?.gc_crossed_weight_charge,
					nfpp: data.quote?.nfpp,
					ll_cleaner: data.quote?.ll_cleaner,
					ll_driver: data.quote?.ll,
					cpa: data.quote?.cpa,
					pad: data.quote?.pad,
					pap: data.quote?.pap,
					add_on_charges: add_on_charges,
					net_premium: net_premium, //OD Final + Third Party Insurance + Third Party CNG Insurance + Legal Liability + CPA + PAD + PAP + Add-On Charges
					gst_per: data.quote?.gst_per,
					gst: gst,
					total_premium: total_premium,
					passback_req: data.quote?.passback_req,
					disc_premium: disc_premium,
				})
				setaccessories(data.accessories);

				forceUpdate();
			}).catch((e) => {
				// console.log("error......", e);
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
				.finally(() => null);
		}
	}
	const viewAccessories = () => {
		let finalTotal = 0;
		return (
			<>
				{accessories.map((obj, index) => {
					finalTotal += Number(obj.accessory.mrp)
					return (
						<tr key={index}>
							<td>{obj.accessory?.name}</td>
							<td>{obj.accessory?.mrp}</td>
						</tr>
					)
				})}
				{accessories.length ?
					<tr>
						<td><b>{'Total'}</b></td>
						<td><b>{finalTotal}</b></td>
					</tr>
					: null}
			</>
		)
	}

	const handleCompanyChange = () => {
		let payload = {
			brand_id: InsuranceQuotationStore.editValues.ins_vehicle.brand_id,
			model_id: InsuranceQuotationStore.editValues.ins_vehicle.model_id,
			segment_id: InsuranceQuotationStore.editValues.ins_vehicle.segment_id,
			zone_id: InsuranceQuotationStore.editValues.ins_vehicle.zone_id,
			passing_type_id: InsuranceQuotationStore.editValues.ins_vehicle.passing_type_id,
			cat_id: cat_id,
			company_id: props?.form?.getFieldValue("company_id"),
		}
		if (Number(payload.cat_id) === 2 && addOns) {
			let convertedArr = addOns.map(x => { return (x.id) })
			payload.addons = convertedArr.join('#');
		}
		getInsProductList(payload)
	};

	const fetchProductList = () => {
		if (fetchProduct) {
			let payload = {
				brand_id: InsuranceQuotationStore.editValues.ins_vehicle.brand_id,
				model_id: InsuranceQuotationStore.editValues.ins_vehicle.model_id,
				segment_id: InsuranceQuotationStore.editValues.ins_vehicle.segment_id,
				zone_id: InsuranceQuotationStore.editValues.ins_vehicle.zone_id,
				passing_type_id: InsuranceQuotationStore.editValues.ins_vehicle.passing_type_id,
				cat_id: cat_id,
				company_id: props?.form?.getFieldValue("company_id"),
			}
			if (Number(payload.cat_id) === 2 && addOns) {
				let convertedArr = addOns.map(x => { return (x.id) })
				payload.addons = convertedArr.join('#');
			}
			getInsProductList(payload).then(() => setFetchProduct(false))
		}
	}
	useEffect(() => {
		if (type !== "add" && InsuranceQuotationStore.editValues && form) {

			if (cat_id && props?.form?.getFieldValue("company_id")) {
				getInsCategoryList();
				getInsCompanyList();
				let payload = {
					brand_id: InsuranceQuotationStore.editValues.ins_vehicle.brand_id,
					model_id: InsuranceQuotationStore.editValues.ins_vehicle.model_id,
					segment_id: InsuranceQuotationStore.editValues.ins_vehicle.segment_id,
					zone_id: InsuranceQuotationStore.editValues.ins_vehicle.zone_id,
					passing_type_id: InsuranceQuotationStore.editValues.ins_vehicle.passing_type_id,
					cat_id: cat_id,
					company_id: props?.form?.getFieldValue("company_id"),
				}
				if (Number(payload.cat_id) === 2 && addOns) {
					let convertedArr = addOns.map(x => { return (x.id) })
					payload.addons = convertedArr.join('#');
				}
				getInsProductList(payload)
			}
			if (props.nilDepAddOn) {
				setnilDepAddOn(props.nilDepAddOn);
			}
			if (props.accessories) {
				setaccessories(props.accessories);
			}
			if (props.tp_period) {
				settpPeriod(props.tp_period);
			}
			if (props.quoteValues) {
				setQuoteValues(props.quoteValues)
			}
		}
	}, [form, props.quoteValues, props?.form, addOns, cat_id, props.tp_period, props.nilDepAddOn, settpPeriod, props.accessories, setaccessories, type, getInsProductList, getInsCategoryList, getInsCompanyList, InsuranceQuotationStore.editValues])

	const net_premiumText = () => {
		let text = 'OD Final + Third Party Insurance + Third Party CNG Insurance + Third Party Liability  + Legal Liability Driver + CPA + PAD + PAP + Add-On Charges';
		if (passingTypeGoodsCaring === InsuranceQuotationStore?.editValues?.ins_vehicle?.pass_cat_id) {
			text = `OD Final + Third Party Insurance + Third Party CNG Insurance + Third Party Liability + Legal Liability Driver + CPA + PAD + PAP + Crossed Weight Charge + Legal Liability Cleaner + NFPP +  Add-On Charges`;
		}
		if (tpInsurance.includes(cat_id)) {
			text = 'Third Party Insurance + Third Party CNG Insurance + Legal Liability Driver+ CPA + PAD + PAP';
		}
		return text;
	}


	return form ? (
		<Form
			form={form}
			id={props.id}
			onFinish={props.handleSubmit}
			labelCol={{ span: 24 }}
			onChange={handleChange}
		>

			<Row gutter={30} className="zform_block_wrapper">
				<Col xs={{ span: 24 }} sm={{ span: 8 }} >
					<div className="zform_block blue_block">
						<p>INS. OFFER</p>
						<span title={InsuranceQuotationStore.editValues?.code}>
							{InsuranceQuotationStore.editValues?.code}
						</span>
						<span className="small">{insurance_quotation_type[InsuranceQuotationStore.editValues?.type_id]}</span>
					</div>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 8 }} >
					<div className="zform_block green_block">
						<p>Customer</p>
						<span title={InsuranceQuotationStore.editValues?.ins_customer?.full_name}>
							{InsuranceQuotationStore.editValues?.ins_customer?.full_name}
						</span>
						<span className="small">{InsuranceQuotationStore.editValues?.location?.name}</span>
					</div>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 8 }} >
					<div className="zform_block orange_block">
						<p>VEHICLE</p>
						<span title={"N/A"}>
							{InsuranceQuotationStore.editValues?.ins_vehicle?.variant}
						</span>
						<span className="small">{InsuranceQuotationStore.editValues?.ins_vehicle?.color}</span>
					</div>
				</Col>
			</Row>
			<Row gutter={30}>
				<Col xs={{ span: 24 }}>
					<Divider />
				</Col>
			</Row>
			<Row gutter={30}>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
					<InputComponent
						type="text"
						disabled={true}
						label="Passing Category"
						placeholder="Passing Category"
						name="pass_cat_id"
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
					<InputComponent
						type="text"
						disabled={true}
						label="Passing Sub Category"
						placeholder="Passing Sub Category"
						name="pass_sub_cat_id"
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
					<InputComponent
						type="text"
						disabled={true}
						label="RTO"
						placeholder="RTO"
						name="rto_place_id"
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
					<InputComponent
						type="text"
						disabled={true}
						label="Thirdparty"
						placeholder="Thirdparty"
						name="tp_period_requested"
					/>
				</Col>
			</Row>
			<Row gutter={30}>
				<Col xs={{ span: 24 }}>
					<Divider />
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
					<InputComponent
						type="text"
						disabled={true}
						label="Category"
						placeholder="Category"
						name="cust_cat_id"
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
					<InputComponent
						type="text"
						disabled={true}
						label="Company"
						placeholder="Company"
						name="cust_company_id"
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
					<Form.Item label={`Budget(INR) ${InsuranceQuotationStore.editValues?.appx_insurance}`}>
						<div className="currencyFormat_box text-right">
							{CurrencyFormat({ value: InsuranceQuotationStore.editValues?.budget })}
						</div>
					</Form.Item>
				</Col>
			</Row>

			{InsuranceQuotationStore.editValues?.cat_id === add_on_categoryID && InsuranceQuotationStore.editValues?.ncb_per_requested > 0 ?
				<>
					<Row gutter={30}>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
							<InputComponent
								type="number"
								disabled={true}
								label="NCB %"
								placeholder="NCB %"
								name="ncb_per_requested"
							/>
						</Col>
					</Row>
					<UploadNCBProof
						form={form}
						isView={true}
						setDisabled={() => { }}
						fileRequired={false}
						fileList={fileList}
						updateFileList={() => { }}
						setisImageUploaded={() => { }}
						isImageUploaded={false}
					/>
					<Col xs={{ span: 24 }}>
						<Divider />
					</Col>
				</>
				:
				null
			}
			<Row gutter={30}>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
					<InputComponent
						type="select"
						allowClear
						required
						autoComplete="chrome-off"
						name="cat_id"
						disabled={true}
						label="Category"
						onChange={() => {
							handleChange();
							resetProduct();
						}}
						placeholder="Select Category"
						rules={vsmInsuranceQuotation.validation.cat_id}
						onFocus={() =>
							fetchCategory &&
							getInsCategoryList().then(() => setFetchCategory(false))
						}
						notFoundContent={
							fetchCategory ? <Spin size="small" /> : "No Record Found."
						}
						options={{
							values: InsuranceQuotationStore.dropdown_ins_category_list,
							value_key: "id",
							text_key: "name",
							rejected_keys:
								InsuranceQuotationStore.dropdown_ins_category_list &&
								InsuranceQuotationStore.dropdown_ins_category_list
									.filter((item) => item.status === 0)
									.map((item) => item.id),
						}}
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
					<InputComponent
						type="select"
						allowClear
						required
						autoComplete="chrome-off"
						label="Company"
						name="company_id"
						disabled={isView || type === "edit"}
						onChange={() => {
							handleChange();
							handleCompanyChange();
							resetProduct();
						}}
						placeholder="Select Company"
						rules={vsmInsuranceQuotation.validation.company_id}
						onFocus={() =>
							fetchCompany &&
							getInsCompanyList().then(() => setFetchCompany(false))
						}
						notFoundContent={
							fetchCompany ? <Spin size="small" /> : "No Record Found."
						}
						options={{
							values: InsuranceQuotationStore.dropdown_ins_company_list,
							value_key: "id",
							text_key: "name",
							rejected_keys:
								InsuranceQuotationStore.dropdown_ins_company_list &&
								InsuranceQuotationStore.dropdown_ins_company_list
									.filter((item) => item.status === 0)
									.map((item) => item.id),
						}}
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
					<InputComponent
						type="select"
						allowClear
						required
						autoComplete="chrome-off"
						label="Product"
						name="prod_id"
						disabled={isView || type === "edit"}
						placeholder="Select Product"
						rules={vsmInsuranceQuotation.validation.prod_id}
						onChange={handleChange}
						onFocus={() =>
							fetchProductList()
						}
						notFoundContent={
							fetchProduct ? <Spin size="small" /> : "No Record Found."
						}
						options={{
							values: InsuranceQuotationStore.dropdown_ins_product_list,
							value_key: "id",
							text_key: "name",
							rejected_keys:
								InsuranceQuotationStore.dropdown_ins_product_list && !fetchProduct &&
								InsuranceQuotationStore.dropdown_ins_product_list
									.filter((item) => item?.status === 0)
									.map((item) => item.id),
						}}
					/>
				</Col>
			</Row>
			<Row gutter={30} justify="space-between">
				<Col xs={{ span: 24 }} sm={{ span: 12 }} className="text-left">
					{InsuranceQuotationStore?.editValues?.sales_consultant?.name ?
						<p>{`Sales Consultant Name: ${InsuranceQuotationStore?.editValues?.sales_consultant?.name}`}<br />{getAddOnText()}</p>
						:
						null
					}
				</Col>
				{type === "add" ?
					<Col xs={{ span: 24 }} sm={{ span: 12 }} className="text-right">
						<Button
							disabled={isView}
							onClick={() => generateQuotation()}
							type="primary"
						>
							Generate
						</Button>
					</Col>
					:
					null
				}
			</Row>
			{(type === "add" ? visibleDetails : true) ?
				<>

					{addonNormalInsurance.includes(cat_id) ?
						<>
							<Row gutter={30}>
								<Col xs={{ span: 24 }}>
									<Divider />
								</Col>
							</Row>
							<Row gutter={30}>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										required
										label="Ex-Showroom"
										placeholder="Ex-Showroom"
										name="ex_showroom"
										onChange={handleChange}
										disabled={isView}
										rules={vsmInsuranceQuotation.validation.ex_showroom}
										tooltip={"Ex-Showroom"}
									/>
									{/* <Form.Item name="ex_showroom" label="Ex-Showroom">
								<div className="currencyFormat_box text-right readOnlyField">
									{CurrencyFormat({ value: props?.form?.getFieldValue("ex_showroom") })}
								</div>
							</Form.Item> */}
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
									<InputComponent
										type="number"
										required
										label="Depreciation(%)"
										placeholder="Depreciation(%)"
										name="dep_rate_exs"
										disabled={isView}
										onChange={handleChange}
										rules={vsmInsuranceQuotation.validation.dep_rate_exs}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}  >
									<Form.Item label="IDV" name="idv_ex_showroom"
									>
										<div className="currencyFormat_box text-right readOnlyField">
											{CurrencyFormat({ value: props?.form?.getFieldValue("idv_ex_showroom") })}
										</div>
									</Form.Item>
								</Col>

								{accessories.length ?
									<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
										<InputComponent
											type="number"
											required
											tooltip={viewAccessories()}
											label="Non-Ele.Acc."
											disabled={isView}
											onChange={handleChange}
											placeholder="Non-Ele.Acc."
											name="non_ele_cost"
											rules={vsmInsuranceQuotation.validation.non_ele_cost}
										/>
									</Col> :
									<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
										<InputComponent
											type="number"
											required
											label="Non-Ele.Acc."
											onChange={handleChange}
											disabled={isView}
											placeholder="Non-Ele.Acc."
											name="non_ele_cost"
											rules={vsmInsuranceQuotation.validation.non_ele_cost}
										/>
									</Col>
								}

								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
									<InputComponent
										type="number"
										required
										disabled={isView}
										onChange={handleChange}
										label="Depreciation(%)"
										placeholder="Depreciation(%)"
										name="dep_rate_nea"
										rules={vsmInsuranceQuotation.validation.dep_rate_nea}
									/>
								</Col>

								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText" >
									<Form.Item name="idv_non_ele_acc" label="Non-Ele.Accessory IDV"
										tooltip="Non-Ele. Accessory Cost - (Non-Ele. Accessory Cost * Depreciation Rate) / 100"
									>
										<div className="currencyFormat_box text-right readOnlyField">
											{CurrencyFormat({ value: props?.form?.getFieldValue("idv_non_ele_acc") })}
										</div>
									</Form.Item>
								</Col>

								{accessories.length ?
									<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
										<InputComponent
											type="number"
											tooltip={viewAccessories()}
											required
											onChange={handleChange}
											disabled={isView}
											label="Ele.Acc."
											placeholder="Ele.Acc."
											name="ele_acc_cost"
											rules={vsmInsuranceQuotation.validation.ele_acc_cost}
										/>
									</Col> :
									<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
										<InputComponent
											type="number"
											required
											onChange={handleChange}
											disabled={isView}
											label="Ele.Acc."
											placeholder="Ele.Acc."
											name="ele_acc_cost"
											rules={vsmInsuranceQuotation.validation.ele_acc_cost}
										/>
									</Col>
								}

								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
									<InputComponent
										type="number"
										required
										onChange={handleChange}
										disabled={isView}
										label="Depreciation(%)."
										placeholder="Depreciation(%)."
										name="dep_rate_ea"
										rules={vsmInsuranceQuotation.validation.dep_rate_ea}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
									<Form.Item name="idv_ele_acc" label="Ele.Accessory IDV"
										tooltip="Ele. Accessory Cost - (Ele. Accessory Cost * Depreciation Rate) / 100"
									>
										<div className="currencyFormat_box text-right readOnlyField">
											{CurrencyFormat({ value: props?.form?.getFieldValue("idv_ele_acc") })}
										</div>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={30} justify="end">
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										required
										onChange={handleChange}
										label="CNG IDV"
										disabled={isView}
										placeholder="CNG IDV"
										name="idv_cng"
										rules={vsmInsuranceQuotation.validation.idv_cng}
										tooltip={"It must be a valid integer of range 0 to 99,999"}
									/>
								</Col>
							</Row>

							<Row gutter={30} justify="end">
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText">
									<Form.Item name="idv_total" label="Total IDV"
										tooltip="Ex-Showroom IDV + Ele. Accessory IDV +  Non-Ele. Accessory IDV + CNG IDV"
									>
										<div className="currencyFormat_box text-right readOnlyField">
											{CurrencyFormat({ value: props?.form?.getFieldValue("idv_total") })}
										</div>
									</Form.Item>
								</Col>
							</Row>
						</>
						:
						null
					}
					{addonNormalInsurance.includes(form?.getFieldValue("cat_id")) ?
						<>
							<Row gutter={30}>
								<Col xs={{ span: 24 }}>
									<Divider />
								</Col>
							</Row>
							<Row gutter={30} justify="end">
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										disabled={true}
										label="Insurance Rate"
										placeholder="Insurance Rate"
										name="ins_rate"
										tooltip={<>
											Identify insurance rate by considering vehicle’s
											<ul>
												<li>Passing Category</li>
												<li>Passing Sub Category</li>
												<li>Age</li>
												<li>CC</li>
												<li>Zone</li>
												<li>System Date should falls within date range</li>
											</ul>
										</>}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
										label="Insurance Premium"
										placeholder="Insurance Premium"
										name="ins_premium"
										tooltip={"(Ex-Showroom IDV * Insurance Rate) / 100.0"}
									/>
								</Col>
							</Row>
							<Row gutter={30} justify="end">
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										disabled={true}
										label="Rate for Acc. & CNG"
										placeholder="Rate for Acc. & CNG"
										name="ins_acc_rate"
										tooltip={"Configurable premium rate for Accessory & CNG at company level"}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
										label="Acc. & CNG Premium"
										placeholder="Acc. & CNG Premium"
										name="ins_acc_cng_premium"
										tooltip={<>
											<ul>
												<li>Acc. & CNG IDV = Ele. Accessory IDV + Non-Ele. Accessory IDV + CNG IDV</li>
												<li>Acc. & CNG Premium = (Acc. & CNG IDV * Insurance Rate for Accessory & CNG ) / 100</li>
											</ul>
										</>}
									/>
								</Col>
							</Row>
							<Row gutter={30} justify="end">
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
										label="OD Premium"
										placeholder="OD Premium"
										name="od"
										tooltip={"Insurance Premium + Acc. & CNG Premium"}
									/>
								</Col>
							</Row>
							<Row gutter={30}>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
									<InputComponent
										label={`OD Type`}
										placeholder="Select OD Type"
										type="select"
										allowClear
										required
										autoComplete="chrome-off"
										name="od_disc_load_flag"
										disabled={isView}
										rules={vsmInsuranceQuotation.validation.od_disc_load_flag}
										onChange={handleChange}
										options={{
											values: [{
												id: 10,
												name: "Disc"
											}, {
												id: 20,
												name: "Loading"
											}],
											value_key: "id",
											text_key: "name",
										}}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
									<InputComponent
										type="number"
										required
										label="OD(%)"
										placeholder="OD(%)"
										name="od_per"
										disabled={isView}
										onChange={handleChange}
										rules={vsmInsuranceQuotation.validation.od_per}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										label={`OD ${props?.form?.getFieldValue("od_disc_load_flag") === 10 ? "Disc" : "Loading"}`}
										placeholder={`OD ${props?.form?.getFieldValue("od_disc_load_flag") === 10 ? "Disc" : "Loading"}`}
										name="od_disc_load"
										formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
										disabled={true}
										tooltip={"(Insurance Premium * OD Percentage) / 100"}
									/>
								</Col>
							</Row>
							<Row gutter={30} justify="end">
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
									<InputComponent
										type="select"
										allowClear
										required
										autoComplete="chrome-off"
										label="NCB(%)"
										name="ncb_per"
										disabled={isView}
										placeholder="Select NCB(%)"
										rules={vsmInsuranceQuotation.validation.ncb_per}
										onChange={handleChange}
										onFocus={() =>
											fetchNCB &&
											getInsNCBList().then(() => setFetchNCB(false))
										}
										notFoundContent={
											fetchNCB ? <Spin size="small" /> : "No Record Found."
										}
										options={{
											values: InsuranceQuotationStore.dropdown_ncb_list,
											value_key: "per_value",
											text_key: "per_value",
										}}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
										label="NCB Disc"
										placeholder="NCB Disc"
										name="ncb_disc"
										tooltip={
											<>
												<ul>
													<li>If OD Type = Discount <br />
														NCB Disc = ((Insurance Premium - OD Disc) * NCB Percentage) / 100
													</li>
													<li>If OD Type = Loading <br />
														NCB Disc = ((Insurance Premium + OD Disc) * NCB Percentage) / 100</li>
												</ul>
											</>}
									/>
								</Col>
							</Row>
							<Row gutter={30} >
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
									<Form.Item
										valuePropName="checked"
										name={'optional_antitheft'}
									>
										<Checkbox
											value={1}
											disabled={isView}
											onChange={(e) => {
												handleChange();
											}}
										/>
									</Form.Item>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
									<InputComponent
										type="number"
										disabled={true}
										label="Anti Theft(%)"
										placeholder="Anti Theft(%)"
										name="anti_theft_per"
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace" >
									<InputComponent
										type="number"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
										label="Anti Theft Disc"
										placeholder="Anti Theft Disc"
										name="anti_theft_disc"
										tooltip={<>
											<ul>
												<li>If OD Type = Discount <br />
													((Insurance Premium - OD Disc - NCB Disc) * anti_thept_per) / 100
												</li>
												<li>
													If OD Type = Loading <br />
													((Insurance Premium + OD Disc - NCB Disc) * anti_thept_per) / 100
												</li>
												<li>If Anti-Theft Discount {'>'} 500 Then	500 Max fixed </li>
											</ul>
										</>}
									/>
								</Col>
							</Row>

							<Row gutter={30}>
								<Col xs={{ span: 24 }}>
									<Divider />
								</Col>
							</Row>
							<Row gutter={30}>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										label="Normal Insurance"
										placeholder="Normal Insurance"
										name="normal_insurance"
										formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
										disabled={true}
										tooltip={<>
											<ul>
												<li>OD Type = Discount Then (OD Premium - NCB Disc - Anti-Theft Disc) - OD Disc</li>
												<li>OD Type = Loading Then(OD Premium - NCB Disc - Anti-Theft Disc) + OD Loading</li>
											</ul>
										</>}
									/>
								</Col>
								{addonInsurance.includes(form?.getFieldValue("cat_id")) ? <>

									<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
										<InputComponent
											type="number"
											label="Fixed Insurance"
											placeholder="Fixed Insurance"
											name="fixed_insurance"
											formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
											disabled={true}
											tooltip={"From insurance product"}
										/>
									</Col>
									<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
										<InputComponent
											type="number"
											label={`Nil Dep ${nilDepAddOn ? nilDepAddOn?.add_on_usage?.charge_type === 10 ? '(Fixed)' : '(Percentage)' : ''}`}
											placeholder="Nil Dep"
											name="nil_dep"
											formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
											disabled={true}
											tooltip={<>
												<ul>
													<li>Nil Dep Rate = Nil Dep rate from product by Vehicle’s Age</li>
													<li>Charge Type =  Percentage (Total IDV * Nil Dep Rate) / 100</li>
													<li>Charge Type = Fixed Nil Dep Rate</li>
												</ul>
											</>}
										/>
									</Col>
									<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
										<InputComponent
											type="number"
											label="Nil Dep Insurance"
											placeholder="Nil Dep Insurance"
											name="nil_dep_insurance"
											disabled={true}
											formatter={value => (Math.round(value * 100) / 100)}
											tooltip={"Fixed Insurance Cost of Product + Nil_Dep"}
										/>
									</Col>
								</>
									:
									null
								}
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace" >
									<InputComponent
										type="number"
										label="OD Final"
										placeholder="OD Final"
										name="od_final"
										formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
										disabled={true}
										tooltip={`Normal Insurance ${addonInsurance.includes(cat_id) ? '+ Nil Dep Insurance' : ''}`}
									/>
								</Col>
							</Row>
						</>
						:
						null
					}
					<Row gutter={30}>
						<Col xs={{ span: 24 }}>
							<Divider />
						</Col>
					</Row>
					<Row gutter={30}>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }} >
							<InputComponent
								label="TP Period"
								placeholder="Select TP Period"
								type="select"
								allowClear
								autoComplete="chrome-off"
								name="tp_period"
								disabled={isView}
								onChange={() => {
									handleTPPeriodChange()
									handleChange("tp_period");
								}}
								options={{
									values: tpPeriod || (form &&
										[{ years: form.getFieldValue("tp_period") }]),
									value_key: "years",
									text_key: "years",
								}}
							/>
						</Col>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
							<InputComponent
								type="number"
								label="TP Insurance"
								placeholder="TP Insurance"
								name="tp_insurance"
								formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
								disabled={true}
							/>
						</Col>
						{tpInsurance.includes(form?.getFieldValue("cat_id")) ?
							<>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 2 }} style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }} >
									<Form.Item
										valuePropName="checked"
										name={'optional_cng_insurance'}
									>
										<Checkbox
											value={1}
											disabled={isView}
											defaultChecked
											onChange={(e) => {
												handleChange();
											}}
										/>
									</Form.Item>
								</Col>
							</>
							:
							null
						}
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
							<InputComponent
								type="number"
								required
								label="TP CNG Insurance"
								placeholder="TP CNG Insurance"
								name="tp_cng_insurance"
								onChange={handleChange}
								disabled={isView || (form?.getFieldValue("idv_cng") <= 0 && addonNormalInsurance.includes(form?.getFieldValue("cat_id")))}
								rules={vsmInsuranceQuotation.validation.tp_cng_insurance}
								tooltip={"Configurable Third Party CNG Insurance at company level X TP Period"}
							/>
						</Col>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
							<InputComponent
								type="number"
								required
								label="TP Liability"
								placeholder="TP Liability"
								name="tp_liability"
								onChange={handleChange}
								disabled={isView}
								rules={vsmInsuranceQuotation.validation.tp_liability}
								tooltip={
									<>
										<ul>
											<li>{`If passengers from ins_vehicles <= 6`}</li>
											<li>Configurable Third Party Liability upto Six Sitter at company level X TP Period</li>
											<li>{`If passengers from ins_vehicles > 6`}</li>
											<li>Configurable Third Party Liability above Six Sitter at company level X TP Period</li>
										</ul>
									</>
								}
							/>
						</Col>
					</Row>
					<Row gutter={30}>
						<Col xs={{ span: 24 }}>
							<Divider />
						</Col>
					</Row>
					<Row gutter={30}>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
							<InputComponent
								type="number"
								required
								label="LL Driver"
								placeholder="LL Driver"
								name="ll_driver"
								onChange={handleChange}
								disabled={isView}
								rules={vsmInsuranceQuotation.validation.ll_driver}
								tooltip={
									<>
										<ul>
											<li>{`If TP_Period = 0 or TP_Period = 1`}</li>
											<li>Configurable Legal Liability Driver at company level</li>
											<li>{`If TP_Period = 3`}</li>
											<li>Configurable Legal Liability Driver at company level X 3</li>
										</ul>
									</>
								}
							/>
						</Col>
					</Row>
					<Row gutter={30}>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
							<InputComponent
								type="number"
								required
								label="CPA"
								placeholder="CPA"
								name="cpa"
								onChange={handleChange}
								disabled={isView}
								rules={vsmInsuranceQuotation.validation.cpa}
								tooltip={
									<>
										<ul>
											<li>{`If TP_Period = 0 or TP_Period = 1`}</li>
											<li>1 Year CPA from product</li>
											<li>{`If TP_Period = 3`}</li>
											<li>3 Year CPA from product</li>
										</ul>
									</>
								}
							/>
						</Col>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
							<InputComponent
								type="number"
								required
								label="PAD"
								placeholder="PAD"
								name="pad"
								onChange={handleChange}
								disabled={isView}
								rules={vsmInsuranceQuotation.validation.pad}
								tooltip={
									<>
										<ul>
											<li>{`If TP_Period = 0 or TP_Period = 1`}</li>
											<li>Personal Accident for Driver from product</li>
											<li>{`If TP_Period = 3`}</li>
											<li>Personal Accident for Driver from product X 3</li>
										</ul>
									</>
								}
							/>
						</Col>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
							<InputComponent
								type="number"
								required
								label="PAP"
								placeholder="PAP"
								name="pap"
								onChange={handleChange}
								disabled={isView}
								rules={vsmInsuranceQuotation.validation.pap}
								tooltip={
									<>
										<ul>
											<li>{`If TP_Period = 0 or TP_Period = 1`}</li>
											<li>Personal Accident for passenger from product X Passengers</li>
											<li>{`If TP_Period = 3`}</li>
											<li>Personal Accident for passenger from product  X Passengers X 3</li>
										</ul>
									</>
								}
							/>
						</Col>
					</Row>
					{addonNormalInsurance.includes(form?.getFieldValue("cat_id")) && passingTypeGoodsCaring === InsuranceQuotationStore?.editValues?.ins_vehicle?.pass_cat_id ?
						<>
							<Row gutter={30}>
								<Col xs={{ span: 24 }}>
									<Divider />
								</Col>
							</Row>
							<Row gutter={30}>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										label="GVW"
										placeholder="GVW"
										name="ins_gc_gvw"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100)}
										tooltip={"From preferences/company settings"}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										label="Crossed KGs"
										placeholder="Crossed KGs"
										name="ins_gc_crossed_kgs"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100)}
										tooltip={"From preferences/company settings"}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										label="Additional Rate"
										placeholder="Additional Rate"
										name="ins_gc_additional_charges"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100)}
										tooltip={"From preferences/company settings"}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										label="Additional Weight"
										placeholder="Additional Weight"
										name="gc_additional_weight"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100)}
										tooltip={"GVW - Vehicle’s Weight, zero if negative"}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										label="Crossed KGs Factor"
										placeholder="Crossed KGs Factor"
										name="gc_crossed_kgs_factor"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100)}
										tooltip={"Additional Weight / Crossed KGs"}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										label="Crossed Weight Charges"
										placeholder="Crossed Weight Charges"
										name="gc_crossed_weight_charge"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100)}
										tooltip={"Crossed KGs Factor X Additional Rate"}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										label="NFPP"
										placeholder="NFPP"
										name="nfpp"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100)}
										tooltip={"Configurable NFPP at company level"}
									/>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
									<InputComponent
										type="number"
										label="LL Cleaner"
										placeholder="LL Cleaner"
										name="ll_cleaner"
										disabled={true}
										formatter={value => (Math.round(value * 100) / 100)}
										tooltip={"Configurable Legal Liability at company level"}
									/>
								</Col>
							</Row>
						</>
						:
						null
					}
					<Row gutter={30}>
						<Col xs={{ span: 24 }}>
							<Divider />
						</Col>
					</Row>
					{addonNormalInsurance.includes(form?.getFieldValue("cat_id")) ?
						<>
							<ViewAddOnComponent
								isView={isView}
								type={type}
								handleChange={handleChange}
								form={form}
								addOns={addOns}
							/>
							{(extraAddons !== null && extraAddons) ?
								<p>Add-ons are not available: {extraAddons}</p>
								:
								null
							}
						</>
						:
						null
					}
					<Row gutter={30}>
						<Col xs={{ span: 24 }}>
							<Divider />
						</Col>
					</Row>
					<Row gutter={30}>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
							<InputComponent
								type="number"
								label="Net Premium"
								placeholder="Net Premium"
								name="net_premium"
								disabled={true}
								formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
								tooltip={`${net_premiumText()}`}
							/>
						</Col>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} >
							<InputComponent
								type="number"
								label="GST(%)"
								placeholder="GST(%)"
								name="gst_per"
								disabled={true}
							/>
							{/* <InputComponent
								type="select"
								allowClear
								required
								autoComplete="chrome-off"
								label="GST"
								name="gst_per"
								disabled={isView}
								placeholder="Select GST"
								rules={vsmInsuranceQuotation.validation.gst_per}
								onChange={handleChange}
								onFocus={() =>
									fetchGST &&
									getGSTList().then(() => setFetchGST(false))
								}
								notFoundContent={
									fetchGST ? <Spin size="small" /> : "No Record Found."
								}
								options={{
									values: InsuranceQuotationStore.dropdown_gst_list,
									value_key: "GST",
									text_key: "GST",
								}}
							/> */}
						</Col>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
							<InputComponent
								type="number"
								label="GST"
								placeholder="GST"
								name="gst"
								disabled={true}
								formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
								tooltip={"(Net Premium * GST Percentage) / 100"}
							/>
						</Col>
					</Row>
					<Row gutter={30} justify="end">
						<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
							<InputComponent
								type="number"
								label="Total Premium"
								placeholder="Total Premium"
								name="total_premium"
								formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
								disabled={true}
								tooltip={"Net Premium + GST"}
							/>
						</Col>
					</Row>
					{typeID !== 10 &&
						<Row gutter={30} justify="end">
							<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
								<InputComponent
									type="number"
									required
									onChange={handleChange}
									label={isView ? "Passback Requested" : "Passback"}
									placeholder={isView ? "Passback Requested" : "Passback"}
									name="passback_req"
									rules={vsmInsuranceQuotation.validation.passback_req}
									disabled={isView}
									tooltip={<>
										<ul>
											<li>Discount to be offered to customer</li>
											<li>It is subject to approval</li>
										</ul>
									</>}
								/>
							</Col>
						</Row>
					}
					{isView && typeID !== 10 ?
						<Row gutter={30} justify="end">
							<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
								<InputComponent
									type="number"
									label="Passback Approved"
									placeholder="Passback Approved"
									name="passback_approved"
									disabled={true}
									tooltip={<>
										<ul>
											<li>Approved discount</li>
										</ul>
									</>}
								/>
							</Col>
						</Row>
						:
						null
					}

					{typeID !== 10 &&
						<Row gutter={30} justify="end">
							<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} className="tooltipText tooltipRightSpace">
								<InputComponent
									type="number"
									label="Discounted Premium"
									placeholder="Discounted Premium"
									name="disc_premium"
									formatter={value => (Math.round(value * 100) / 100).toFixed(0)}
									disabled={true}
									tooltip={<>
										<ul>
											<li>Total Premium - Payback</li>
											<li>Effect of payback is subject to approval</li>
										</ul>
									</>}
								/>
							</Col>
						</Row>
					}
				</>
				: null}
			{props.extraFields}
		</Form>
	) : null;
});

export default InsuranceQuotationFormComponent;
