import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { municipal_request_status } from "../../../utils/GlobalFunction";
import moment from "moment";

export default class MunicipalReqPendingStore {
	agGrid = null;
	per_page = LocalGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	approveRejectValues = null;


	// set form values to edit
	setApproveRejectValues = (data) => {
		this.approveRejectValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = () => {
		this.list_data = null;
		return Axios.post(`/sales/muni_request/pending_list`).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.status = municipal_request_status[item.status]
					item.created = item.created ? moment(item.created).format("DD/MM/YYYY HH:mm:ss") : "N/A";
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
		});
	};

	// call api for approve muni request
	approveMunicipalRequest = (booking_id) => {
		return Axios.post(`/sales/muni_request/approve/` + booking_id)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api for reject muni request
	rejectMunicipalRequest = (formData, booking_id) => {
		return Axios.post(`/sales/muni_request/reject/` + booking_id, formData)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};


	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	};
}

decorate(MunicipalReqPendingStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	approveRejectValues: observable,
	setApproveRejectValues: action,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
});
