import LocalGridConfig from "../../../../config/LocalGridConfig";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { Button } from "antd";
import { vsmCommon } from "../../../../config/messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import { CurrencyFormat } from "../../../../utils/GlobalFunction";

const ActionRenderer = observer((props) => {
	const { AUTH } = useStore();
	const { openEditModal } = props.agGridReact.props.frameworkComponents;
	const { openAddModal } = props.agGridReact.props.frameworkComponents;
	const { openDeleteModal } = props.agGridReact.props.frameworkComponents;
	return (
		<div className="action-column">
			{AUTH.checkPrivileges("#1532#") && (
				<Button
					type="text"
					title={"Copy"}
					className="widgetIcon mr-15"
					size="large"
					style={{ padding: 7 }}
					onClick={() => {
						openAddModal(props.data);
					}}
				>
					<FontAwesomeIcon icon={faCopy} />
				</Button>
			)}
			{AUTH.checkPrivileges("#1533#") && (
				<Button
					type="text"
					title={"Edit"}
					className="editIcon mr-15"
					size="large"
					style={{ padding: 7 }}
					// disabled={props.data.is_used === 1 ? true : false}
					onClick={() => {
						openEditModal(props.data);
					}}
				>
					<FontAwesomeIcon icon={faPencilAlt} />
				</Button>
			)}
			{AUTH.checkPrivileges("#1534#") && (
				<Button
					type="text"
					title={"Delete"}
					className="deleteIcon"
					size="large"
					style={{ padding: 7 }}
					disabled={props.data.is_used === 1 ? true : false}
					onClick={() => {
						openDeleteModal(props.data);
					}}
				>
					<FontAwesomeIcon icon={faTrashAlt} />
				</Button>
			)}
		</div>
	);
});

const ListComponent = observer((props) => {
	const { openEditModal, openDeleteModal, onSwitchChange, openAddModal } = props;
	const {
		ManageMunicipalTaxStore: { list_data, setupGrid, onFilterChanged }
	} = useStore();
	const gridOptions = {
		columnDefs: [
			{
				headerName: "# ID",
				field: "srno",
				filter: "agNumberColumnFilter",
				pinned: "left",
				minWidth: 120,
				width: 120,
			},
			// { headerName: "Name", field: "name" },
			{
				headerName: "Municipality Name",
				field: "municipality.name",
				pinned: "left",
			},
			{
				headerName: "Min Amount",
				field: "min_amt",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.min_amt} />
				},
			},
			{
				headerName: "Max Amount",
				field: "max_amt",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.max_amt} />
				},
			},
			{
				headerName: "Fixed Charge",
				field: "fixed_charge"
			},
			{
				headerName: "Tax %",
				field: "tax_per"
			},
			{
				headerName: "Actions",
				field: "actions",
				type: "actionColumn",
				filter: false,
				sortable: false,
				pinned: "right",
				minWidth: 180,
				width: 180,
			},
		],
	};
	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowHeight={LocalGridConfig.rowHeight}
				headerHeight={LocalGridConfig.headerHeight}
				rowData={list_data}
				modules={AllModules}
				columnDefs={gridOptions.columnDefs}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{ ActionRenderer, openEditModal, openDeleteModal, openAddModal }}
				onGridReady={setupGrid}
				onFilterChanged={onFilterChanged}
				onSortChanged={onFilterChanged}
				gridOptions={LocalGridConfig.options}
				onSwitchChangeGrid={onSwitchChange}
			/>
		</div>
	);
});

export default ListComponent;
