import React, { useState } from "react";
import { Form, Button, Modal, Col, Row } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { vsmMunicipalRequest, vsmNotify } from "../../../../config/messages";
import useStore from "../../../../store";
import InputComponent from "../../../../component/InputComponent";
import { debounce } from "@syncfusion/ej2-base";

const RejectComponent = observer((props) => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);

	const {
		MunicipalReqPendingStore
	} = useStore();

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true);
		MunicipalReqPendingStore.rejectMunicipalRequest(data, MunicipalReqPendingStore.approveRejectValues?.booking_id)
			.then((data) => {
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
		setDisabled(true);
	};

	return (
		<Modal
			className="addModal"
			centered
			title="Reject Municipal Request"
			width={640}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					No
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					form="muniReqRejectForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Yes
				</Button>,
			]}
		>
			<Form
				form={form}
				id="muniReqRejectForm"
				onFinish={handleSubmit}
				labelCol={{ span: 24 }}
				onChange={handleChange}
			>
				<Row gutter={30}>
					<Col xs={{ span: 24 }}>
						<InputComponent
							type="textarea"
							required
							label="Note"
							placeholder="Note"
							name="note"
							onChange={handleChange}
							rules={vsmMunicipalRequest.validation.note}
						/>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
});

export default RejectComponent;
