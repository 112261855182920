import LocalGridConfig from "../../../config/LocalGridConfig";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import useStore from "../../../store";
import { observer } from "mobx-react";
import { vsmCommon } from "../../../config/messages";
import { CurrencyFormat } from "../../../utils/GlobalFunction";
import { useEffect } from "react";
import Unauthorized from "../../../page/commonpage/Unauthorized";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import { PageHeader } from "antd";
import { useHistory } from "react-router";

const ListComponent = observer((props) => {
	const {
		FinancePipelineSummaryStore: {
			getList,
		},
	} = useStore();
	const {
		FinancePipelineSummaryStore: { list_data, setupGrid, setPageSize, per_page },
		AUTH: { checkPrivileges },
	} = useStore();
	let history = useHistory()
	const handleRedirect = (params) => {
		localStorage.setItem("name", "");
		if (params.data.name) {
			localStorage.setItem("name", params.data.name);
		}
		history.push({
			pathname: "/sales/finance-pipeline-detail",
		});
	}
	const gridOptions = {
		suppressAggFuncInHeader: false,
		groupIncludeTotalFooter: false,
		columnDefs: [
			{
				headerName: "# ID",
				field: "srno",
				filter: false,
				pinned: "left",
				minWidth: 120,
				width: 120,
			},
			{
				headerName: "Location Name",
				field: "name",
				filter: false,				
				cellClass: ['loc_short_name_color_cg'],
				valueGetter: (params) => (params.data && params.data.name) ? params.data.name : "N/A",
				onCellClicked:handleRedirect.bind(this),			
				
			},
			{
				headerName: "Active Orders",
				field: "total_active",
				filter: false,
			},
			{
				headerName: "Total Received",
				field: "total_received",
				filter: false,	
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.total_received} />
				},
			},
			{
				headerName: "Total Receivable",
				field: "total_receivable",
				filter: false,
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.total_receivable} />
				},
			},
			{
				headerName: "Total Due",
				field: "total_due",
				filter: false,				
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.total_due} />
				},
			},
			// {
			// 	headerName: "Total",
			// 	field: "acc_offer.fixed_disc",				
			// },						
		],		
		
	};
	useEffect(() => {
		getList();
	}, [getList]);
	let pinnedBottomRowData = [];
	let totalValue = {		
		short_name :'Total',
		total_active:0,
        total_received: 0,
		total_receivable:0,
        total_due: 0,       
    }
	list_data && list_data.map((currentObj, index) => {
		totalValue['total_active'] += parseInt(currentObj['total_active']);
		totalValue['total_receivable'] += parseInt(currentObj['total_receivable']);
		totalValue['total_received'] += parseInt(currentObj['total_received']);
		totalValue['total_due'] += parseInt(currentObj['total_due']);
		return null
	});	
	pinnedBottomRowData.push(totalValue)
	return (
		<>
			{!checkPrivileges("#53007#") ? (
				<Unauthorized />
			) : (
				<PageHeader
					title={BreadcrumbConfig.FinancePipelineReportRequest.title}
					className="tableAreaSec"
					extra={
						<BreadcrumbComponent items={BreadcrumbConfig.FinancePipelineReportRequest.path} />
					}
				>
					<div className="listCountNew">
						{/* {AUTH.checkPrivileges("#6305#") && ( */}
						<RecordPerPage
							key="2"
							style={{ width: "150px" }}
							defaultValue={per_page + " per page"}
							onChange={setPageSize}
						/>
					</div>
					<div className="ag-theme-alpine grid_wrapper">
						<AgGridReact
							rowHeight={LocalGridConfig.rowHeight}
							headerHeight={LocalGridConfig.headerHeight}
							rowData={list_data}
							modules={AllModules}
							columnDefs={gridOptions.columnDefs}
							defaultColDef={LocalGridConfig.defaultColDef}
							columnTypes={LocalGridConfig.columnTypes}
							overlayNoRowsTemplate={vsmCommon.noRecord}
							onGridReady={setupGrid}
							gridOptions={LocalGridConfig.options}
							//groupIncludeTotalFooter={gridOptions.groupIncludeTotalFooter}
							suppressAggFuncInHeader={gridOptions.suppressAggFuncInHeader}
							pinnedBottomRowData={pinnedBottomRowData}	
						/>
					</div>
				</PageHeader>
			)}
		</>
	);
});

export default ListComponent;
