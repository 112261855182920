import React, { useState } from "react";
import { Form, Button, Modal, Col, Row } from "antd";
import useStore from "../../../../../../store";
import { observer } from "mobx-react";
import { vsmNotify, vsmQuotationApproval } from "../../../../../../config/messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../../../../../component/InputComponent";
import debounce from "lodash/debounce";

const ResetBookingZformComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageZFormsStore,
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true);
		data.id = ManageZFormsStore.viewValues.id;
		ManageZFormsStore.ResetBookingZform(data)
			.then((data) => {
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => {
				setSaving(false);
			});
	};
	// check for valid form values then accordingly make save button disable/enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
	};

	return ManageZFormsStore.viewValues ? (
		<Modal
			centered
			className="deleteModal"
			title={"Cancel Z-Form (" + ManageZFormsStore.viewValues.id + ")"}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					No
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					form="resetBookingZform"
					loading={saving}
					htmlType="submit"
					type="primary"
					danger
				>
					Yes
				</Button>,
			]}
		>
			<Form form={form} id="resetBookingZform" onFinish={handleSubmit} layout="vertical">
				{
					<Row align="middle">
						<Col span={24} className="text-center">
							<h3>
								Would you like to reset the z-form?
							</h3>
						</Col>
						<Col span={24}>
							<div className="revert_package_sec">
								<ul>
									<li>Reset package and associated discounts, if applied.</li>
									<li>Reset scheme discount and approval requests, if any.</li>
									<li>Reset corporate offer and approval request, if any.</li>
									<li>Reset kitty offer and approval request, if any.</li>
									<li>Reset RTO offer, if any.</li>
									<li>Reset Accessory offer, if any.</li>
									<li>Reset Insurance offer, quotations and approvals, if any.</li>
									<li>Reset Finance offer, quotations and approvals, if any.</li>
									<li>Payments and Refund entries will remain as it is.</li>
									<li>This is irreversible process.</li>
								</ul>
							</div>
						</Col>
						<Col xs={{ span: 24 }}>
							<InputComponent
								type="textarea"
								label="Remarks"
								placeholder="Remarks"
								name="remarks"
								required
								onChange={handleChange}
								rules={vsmQuotationApproval.validation.remark}
							/>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null;
});

export default ResetBookingZformComponent;
