import { useEffect } from "react";
import { observer } from "mobx-react";
import useStore from "./store";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./utils/AppRouter";
import Login from "./page/Login";
// import Page404 from "./page/ErrorPages/Page404";
import LayoutComponent from "./component/LayoutComponent";
import { getTokenInit } from "./firebase";
// import { ErrorBoundary } from 'react-error-boundary'

// const myErrorHandler = (error, info) => {
// 	// Do something with the error
// 	// E.g. log to an error logging client here
// 	console.log("error", error, info)
// }
const getFaviconEl = () => {
	return document.getElementById("favicon");
}

const App = observer(() => {
	const { AUTH, AUTH: { user, company, setAxiosInterceptors } } = useStore();
	useEffect(() => {
		setAxiosInterceptors();
	}, [setAxiosInterceptors]);

	useEffect(() => {
		getTokenInit()
	}, [user])

	useEffect(() => {
		const favicon = getFaviconEl();
		favicon.href = company && company.branding.favicon
	}, [company]);


	// const ErrorFallback = ({ error }) => {
	// 	return (
	// 		<Page404 />
	// 	)
	// }


	return (
		<BrowserRouter basename="/">
			{/* <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}> */}
			{/* </ErrorBoundary> */}
			{AUTH.open_login ?
				<Login />
				:
				<LayoutComponent>
					<AppRouter />
				</LayoutComponent>
			}
		</BrowserRouter>
	);
});

export default App;
