import Axios from "axios"
import { action, decorate, observable } from "mobx"
export default class WidgetStore {
	list_public_holidays = null;
	list_marriage_anniversaries = null;
	list_birthdays = null;
	list_zform = null;
	list_zform_post = null;
	list_inquiries_by_ratings = null;
	list_inquiries_by_status = null;
	list_active_status = null;
	list_detail_status = null;
	list_by_status = null;
	list_zform_pending_fund = null;
	list_insurance_active = null;
	list_insurance_active_fund = null;
	list_insurance_quotation = null;
	list_active_finance = null;

	// ----------------------- Get List Data for General Widgets ----------------------------------- //

	getListData = (api_url, list_name, formdata = {}) => {
		this[list_name] = null
		return Axios.post(api_url, formdata)
			.then(({ data }) => {
				this[list_name] = data.list ? data.list.records : null;
				return data;
			}).catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	}

	getZFormDetail = (api_url, list_name, formdata = {}) => {
		this[list_name] = null
		return Axios.post(api_url, formdata)
			.then(({ data }) => {
				return data;
			}).catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	}

	getInquiriesList = (api_url, list_name, formData = {}) => {
		this[list_name] = null
		return Axios.post(api_url, formData)
			.then(({ data }) => {
				this[list_name] = data.list ? data.list : null
				return data;
			}).catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	}

	getActiveFinaceList = (api_url, list_name, formData = {}) => {
		this[list_name] = null
		return Axios.post(api_url, formData)
			.then(({ data }) => {
				this[list_name] = data.list ? data.list : null
				return data;
			}).catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	}

	resetOnLogin = () => {
		this.list_public_holidays = null;
		this.list_marriage_anniversaries = null;
		this.list_birthdays = null;
		this.list_zform = null;
		this.list_zform_post = null;
		this.list_inquiries_by_ratings = null;
		this.list_inquiries_by_status = null;
		this.list_active_status = null;
		this.list_detail_status = null;
		this.list_by_status = null;
		this.list_zform_pending_fund = null;
		this.list_insurance_active = null;
		this.list_insurance_active_fund = null;
		this.list_insurance_quotation = null;
		this.list_active_finance = null;
	}

}

decorate(WidgetStore, {
	list_public_holidays: observable,
	list_marriage_anniversaries: observable,
	list_birthdays: observable,
	list_zform: observable,
	list_zform_post: observable,
	list_inquiries_by_ratings: observable,
	list_inquiries_by_status: observable,
	list_active_status: observable,
	list_detail_status: observable,
	list_by_status: observable,
	list_zform_pending_fund: observable,
	list_insurance_active: observable,
	list_insurance_active_fund: observable,
	list_insurance_quotation: observable,
	list_active_finance: observable,
	getInquiriesList: action,
	getListData: action,
	resetOnLogin: action,
});
