import React from "react";
import { Form, Button, Row, Col, Drawer } from "antd";
import useStore from "../../../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { vsmCommon } from "../../../../../../config/messages";
import moment from "moment";
import { AgGridReact } from "@ag-grid-community/react";
import LocalGridConfig from "../../../../../../config/LocalGridConfig";
import { AllModules } from "@ag-grid-enterprise/all-modules";

const ActionRenderer = observer((props) => {

	const {
		applyMunicipal,
	} = props.agGridReact.props.frameworkComponents;

	return (
		<div className="action-column">
			{/* {AUTH.checkPrivileges("#6260#") && ( */}
			<Button
				type="text"
				title={"Apply"}
				className="recordIcon mr-15"
				size="large"
				style={{ padding: 7 }}
				onClick={() => {
					applyMunicipal(props.data);
				}}
			>
				<FontAwesomeIcon icon={faCheck} />
			</Button>
			{/* )} */}
		</div>
	);
});

const MunicipalComponent = observer((props) => {
	const [form] = Form.useForm();
	const { ManageZFormsStore } = useStore();

	const {
		applyMunicipal,
		openViewPackageDefModal,
	} = props;

	const gridOptions = {
		columnDefs: [
			{
				headerName: "Municipality Name",
				field: "municipality.name",
			},
			{
				headerName: "Minimum Amount",
				field: "min_amt",
				initialHide: true,
			},
			{
				headerName: "Maximum Amount",
				field: "max_amt",
				initialHide: true,
			},
			{
				headerName: "Fixed Charge",
				field: "fixed_charge",
				initialHide: true,
			},
			{
				headerName: "Tax %",
				field: "tax_per",
				initialHide: true,
			},
			{
				headerName: "Actions",
				field: "actions",
				type: "actionColumn",
				filter: false,
				sortable: false,
				pinned: "right",
				minWidth: 180,
				width: 180,
			},
		],
	};

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
	};

	return ManageZFormsStore.viewValues ? (
		<Drawer
			className="addModal"
			title="Apply Municipal tax"
			width="75%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			destroyOnClose
			onClose={close}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
			]}
		>
			<Row gutter={30} className="zform_block_wrapper" justify="center">
				<Col xs={{ span: 24 }} sm={{ span: 8 }} >
					<div className="zform_block blue_block">
						<p>CO NO - {ManageZFormsStore.viewValues?.co_no ? ManageZFormsStore.viewValues.co_no : 'N/A'}</p>
						<span className="small">
							{ManageZFormsStore.viewValues?.sales_consultant?.name}
						</span>
						<span className="small">{moment(ManageZFormsStore.viewValues?.created).format("DD/MM/YYYY")}</span>
					</div>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 8 }} >
					<div className="zform_block green_block">
						<p>Customer</p>
						<span title={ManageZFormsStore.viewValues.booking_customer.title.name + " " + ManageZFormsStore.viewValues.booking_customer.full_name}>
							{ManageZFormsStore.viewValues.booking_customer.title.name + " " + ManageZFormsStore.viewValues.booking_customer.full_name}
						</span>
						<span className="small">{ManageZFormsStore.viewValues.location.name}</span>
					</div>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 8 }} >
					<div className="zform_block orange_block">
						<p>Variant</p>
						<span title={ManageZFormsStore.viewValues.booking_model.variant ? ManageZFormsStore.viewValues.booking_model.variant.name : "N/A"}>
							{ManageZFormsStore.viewValues.booking_model.variant ? ManageZFormsStore.viewValues.booking_model.variant.name : "N/A"}
						</span>
						<span className="small">{ManageZFormsStore.viewValues.booking_model.color ? ManageZFormsStore.viewValues.booking_model.color.name : "N/A"}</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={{ span: 24 }}>
					<div className="ag-theme-alpine grid_wrapper">
						<AgGridReact
							rowHeight={LocalGridConfig.rowHeight}
							headerHeight={LocalGridConfig.headerHeight}
							rowData={ManageZFormsStore.municipal_list}
							modules={AllModules}
							columnDefs={gridOptions.columnDefs}
							defaultColDef={LocalGridConfig.defaultColDef}
							columnTypes={LocalGridConfig.columnTypes}
							overlayNoRowsTemplate={vsmCommon.noRecord}
							frameworkComponents={{
								ActionRenderer,
								applyMunicipal,
								openViewPackageDefModal,
							}}
							onGridReady={ManageZFormsStore.packageOfferSetupGrid}
							gridOptions={LocalGridConfig.options}
							onFilterChanged={ManageZFormsStore.packageOfferOnFilterChanged}
							onSortChanged={ManageZFormsStore.packageOfferOnFilterChanged}
						/>
					</div>
				</Col>
			</Row>
		</Drawer>
	) : null
});

export default MunicipalComponent;
