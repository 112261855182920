import { Col, Divider, Spin } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import { useState } from "react";
import InputComponent from "../../../../component/InputComponent";
import { vsmInsuranceOffer } from "../../../../config/messages";
import useStore from "../../../../store";
const AddEditNomineeDetailComponent = observer((props) => {

	const {
		InsuranceOfferStore,
	} = useStore();

	const { isView, handleChange, requiredFields = true } = props;

	const disabledDate = (current) => {
		return current && current > moment().endOf("day");
	};

	const [fetchNomineeRel, setFetchNomineeRel] = useState(true);

	return (
		<>
			<Col xs={{ span: 24 }}>
				<Divider />
				<h1 className="formTitle">Nominee Information</h1>
			</Col>
			<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
				<InputComponent
					type="text"
					disabled={isView}
					label="Nominee Name"
					required={requiredFields}
					placeholder="Nominee Name"
					name="nom_name"
					rules={requiredFields ? vsmInsuranceOffer.validation.nom_name : [{ required: false, message: '' }]}
				/>
			</Col>
			<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
				<InputComponent
					type="date"
					disabled={isView}
					label="Nominee Birthdate"
					required={requiredFields}
					placeholder="Nominee Birthdate"
					name="nom_birthdate"
					format="DD/MM/YYYY"
					rules={requiredFields ? vsmInsuranceOffer.validation.nom_birthdate : [{ required: false, message: '' }]}
					disabledDate={disabledDate}
					onChange={handleChange}
				/>
			</Col>
			<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
				<InputComponent
					type="select"
					required={requiredFields}
					allowClear
					rules={requiredFields ? vsmInsuranceOffer.validation.nom_rel_id : [{ required: false, message: '' }]}
					autoComplete="chrome-off"
					label="Nominee Relation"
					name="nom_rel_id"
					disabled={isView}
					placeholder="Select Nominee Relation"
					onChange={handleChange}
					onFocus={() =>
						fetchNomineeRel && InsuranceOfferStore.getNomineeRelationList().then(() => setFetchNomineeRel(false))
					}
					notFoundContent={
						fetchNomineeRel ? <Spin size="small" /> : "No Record Found."
					}
					options={{
						values: InsuranceOfferStore.dropdown_nominee_relation_list,
						value_key: "id",
						text_key: "name",
						rejected_keys:
							InsuranceOfferStore.dropdown_nominee_relation_list && !fetchNomineeRel &&
							InsuranceOfferStore.dropdown_nominee_relation_list
								.filter((item) => item?.status === 0)
								.map((item) => item.id),
					}}
				/>
			</Col>
		</>
	)
})

export default AddEditNomineeDetailComponent;
