import React from "react";
import { Form, Row, Col, Table } from "antd";
import { observer } from "mobx-react";
import useStore from "../../../../store";
import moment from "moment";
import { inquiry_status } from "../../../../utils/GlobalFunction";

const ActionTabComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		RecordInquiriesStore
	} = useStore();

	const tableColumn = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: date => <>{date ? moment(date).format("DD/MM/YYYY") : "N/A"}</>,
		},
		{
			title: "Time",
			dataIndex: "time",
			key: "time",
			render: time => <>{time ? moment(time).format("hh:mm A") : "N/A"}</>,
		},
		{
			title: "Action",
			dataIndex: "followup_action",
			key: "followup_action",
			render: followup_action => <>{followup_action.name}</>,
		},
		{
			title: "Consultant",
			dataIndex: "sales_consultant",
			key: "sales_consultant",
			render: sales_consultant => <>{sales_consultant.name}</>,
		},
	];

	const actionTableColumn = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: date => <>{date ? moment(date).format("DD/MM/YYYY") : "N/A"}</>,
		},
		{
			title: "User",
			dataIndex: "sales_consultant",
			key: "sales_consultant",
			render: sales_consultant => <>{sales_consultant.name}</>,
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: item => (<>{inquiry_status[item]}</>)
		},
	];


	return (
		<Form
			form={form}
			id="DealTabForm"
			// onFinish={handleSubmit}
			labelCol={{ span: 24 }}
		// onChange={handleChange}
		>
			<Row>
				<Col xs={{ span: 24 }}>
					<h1 className="formTitle">Follow ups</h1>
				</Col>
				<Col xs={{ span: 24 }}>
					{RecordInquiriesStore.recordTabData &&
						<div className="import_table">
							<Table
								columns={tableColumn}
								dataSource={RecordInquiriesStore.recordTabData.followups}
								pagination="false"
								scroll={{ x: "100%", y: 300 }}
							/>
						</div>
					}
				</Col>
				<Col xs={{ span: 24 }}>
					<h1 className="formTitle">Actions</h1>
				</Col>
				<Col xs={{ span: 24 }}>
					{RecordInquiriesStore.recordTabData &&
						<div className="import_table">
							<Table
								columns={actionTableColumn}
								dataSource={RecordInquiriesStore.recordTabData.inquiry_tracking}
								pagination="false"
								scroll={{ x: "100%", y: 300 }}
							/>
						</div>
					}
				</Col>
			</Row>
		</Form>
	);
});

export default ActionTabComponent;
