import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../config/LocalGridConfig";
import moment from "moment";

export default class ManageDRFStore {
	agGrid = null;
	per_page = LocalGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	viewDRFValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_location_list = null;
	location_wise_user_data = null;
	agLocationUserGrid = null;
	typeMode = null;

	// set form values to edit
	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};	

	// set form values to view
	setDRFViewValues = (id) => {
		return Axios.post(`sales/manage_bookings/drf_detail/${id}`).then(({ data }) => {
			this.viewDRFValues = data.view;
		});
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
	};

	// Setup location use grid and set column size to autosize
	setupLocationUserGrid = (params) => {
		this.agLocationUserGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	setTypeMode = (mode) => {
		this.typeMode = mode;
	}

	// call api to get records
	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel();
			var sort = this.agGrid.api.getSortModel();
		}
		this.list_data = null;
		return Axios.post(`/sales/manage_bookings/list_drf`).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.srno = index + 1;
					item.status_name = item.status === 1 ? "Yes" : "No";
					item.date = item.date ? moment(item.date).format("DD/MM/YYYY") : "N/A";
					item.date_completed = item.date_completed ? moment(item.date_completed).format("DD/MM/YYYY") : "N/A";
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.agGrid) {
				this.agGrid.api.setFilterModel(filter);
				this.agGrid.api.setSortModel(sort);
			}
		});
	};

	getListBasedOnLocation = () => {
		if (this.agLocationUserGrid) {
			var filter = this.agLocationUserGrid.api.getFilterModel();
			var sort = this.agLocationUserGrid.api.getSortModel();
		}
		this.location_wise_user_data = null;
		return Axios.post(`/sales/manage_bookings/list_drf_history`).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.srno = index + 1;
					item.status_name = item.status === 1 ? "Yes" : "No";
					item.date = item.date ? moment(item.date).format("DD/MM/YYYY") : "N/A";
					item.date_completed = item.date_completed ? moment(item.date_completed).format("DD/MM/YYYY") : "N/A";
					return null;
				});
			}
			this.location_wise_user_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
				if (this.agLocationUserGrid && this.agLocationUserGrid.columnApi) {
					this.agLocationUserGrid.columnApi.getAllColumns() &&
						this.agLocationUserGrid.columnApi
							.getAllColumns()
							.forEach(function (column) {
								allColumnIds.push(column.colId);
							});
				}
				if (this.agLocationUserGrid) {
					this.agLocationUserGrid.api.setFilterModel(filter);
					this.agLocationUserGrid.api.setSortModel(sort);
				}
			}
		);		
	};

	DrfApply = (formdata) => {
		return Axios.post(`sales/manage_bookings/drf_apply/` + formdata.id , formdata)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	DrfCompleted = (formdata,id) => {
		return Axios.post(`sales/manage_bookings/drf_completed/${id}`, formdata)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	DrfRejected = (formdata,id) => {
		return Axios.post(`sales/manage_bookings/drf_rejected/${id}`, formdata)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	DrfBacktoworkshop = (formdata,id) => {
		return Axios.post(`sales/manage_bookings/drf_backtoworkshop/${id}`, formdata)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Filter function for no record found message
	onDRFHistoryFilterChanged = (params) => {
		this.agLocationUserGrid = params;
		if (this.agLocationUserGrid && this.agLocationUserGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agLocationUserGrid.api.showNoRowsOverlay();
		}
		if (this.agLocationUserGrid && this.agLocationUserGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agLocationUserGrid.api.hideOverlay();
		}
	};
}

decorate(ManageDRFStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	location_wise_user_data: observable,
	total: observable,
	allColumnIds: observable,
	editValues: observable,
	deleteValues: observable,
	viewDRFValues: observable,
	dropdown_location_list: observable,
	setupGrid: action,
	setupLocationUserGrid: action,
	setPageSize: action,
	setEditValues: action,
	setDRFViewValues: action,
	setDeleteValues: action,
	getList: action,
	DeleteData: action,
	onFilterChanged: action,
	agLocationUserGrid: observable,
	typeMode: observable,
	setTypeMode: action,
});
