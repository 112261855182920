import { Button, Form, Typography } from "antd";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import InputComponent from "../component/InputComponent";
import { vsmAuth, vsmNotify } from "../config/messages";
import useStore from "../store";
import Banner_img from "../images/login-bg.png";
import logo from "../images/logo.png";

const Login = observer((props) => {
	const [form] = Form.useForm();
	const history = useHistory()
	const [disabled, setDisabled] = useState(true);
	const [saving, setSaving] = useState();
	const state = props?.location?.state ? props.location.state : {}
	const {
		AUTH: { company, doLogin, remember_me },
		WidgetStore: { resetOnLogin },
		NotificationStore,
	} = useStore();

	// handle password tool tip visiblility
	const handleChangePassword = (e) => {
		form
			.validateFields()
			.then((data) => {
				setDisabled(false);
			})
			.catch((e) => {
				setDisabled(true);
			});
	};

	// make function call to login
	const handleSubmit = (data) => {
		setSaving(true);
		resetOnLogin();
		NotificationStore.list_data = null;
		doLogin(data)
			.then((data) => {
				if (state?.redirectPath) {
					history.push({ pathname: state.redirectPath })
				}
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			}).finally(() => setSaving(false));
	};

	useEffect(() => {
		if (remember_me) {
			form.setFieldsValue({
				email: remember_me.email,
				password: remember_me.password,
			});
		}
	}, [remember_me, form]);

	return (
		<div className="login__page__wrapper">
			<div className="login__page__title__wrapper">
				<div
					className="login__page_img_wrapper"
					style={{
						backgroundImage: `url(${company ? company.branding.hero_image : Banner_img
							})`,
					}}
				></div>

				<div className="login__page__form">
					<div className="logo_wrapper">
						<img src={company ? company.branding.logo : logo} alt="Logo" />
					</div>
					<Typography.Title level={2} className="w-100 login_title">
						Login
					</Typography.Title>
					<Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
						<InputComponent
							label="Username or Mobile no"
							type="text"
							hasFeedback
							name="login_id"
							placeholder="Username or Mobile no"
							onChange={handleChangePassword}
							onFocus={handleChangePassword}
							rules={vsmAuth.validation.email}
						/>

						<InputComponent
							hasFeedback
							label="Password"
							type="password"
							name="password"
							placeholder="Password"
							onChange={handleChangePassword}
							onFocus={handleChangePassword}
							rules={vsmAuth.validation.password}
						/>
						<div className="remember_forgot_wrap">
							<Link
								to="/forgot-password"
								type="link"
								className="forgot_pass_link"
							>
								Forgot password?
							</Link>
						</div>
						<Button
							disabled={disabled}
							htmlType="submit"
							block
							type="primary"
							size="large"
							loading={saving}
						>
							sign in
						</Button>
					</Form>
				</div>
			</div>
			<div className="login__page__form__section"></div>
		</div>
	);
});

export default Login;
