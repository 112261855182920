import React, { useState, useEffect } from "react";
import { Form, Button, Drawer } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { vsmNotify } from "../../../config/messages";
import useStore from "../../../store";
import { convertError, tpPeriodObj } from '../../../utils/GlobalFunction'
import InsuranceQuotationFormComponent from "./InsuranceQuotationFormComponent";

const AddQuotationComponent = observer((props) => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState();
	const [typeID, setTypeID] = useState(null);
	const [visibleDetails, setvisibleDetails] = useState(false);
	const [addOns, setAddOns] = useState(null);
	const [addon_verified, setaddon_verified] = useState(true);
	const [extraAddons, setextraAddons] = useState(null);
	const [cat_id, setcat_id] = useState(null)

	const [fileList, updateFileList] = useState([]);
	const {
		InsuranceQuotationStore,
		InsuranceOfferStore,
		InsuranceQuotationStore: {
			getInsCategoryList,
		}
	} = useStore();
	const [disabled, setDisabled] = useState(true);

	useEffect(() => {
		if (props.visible) {
			getInsCategoryList()
			InsuranceQuotationStore.getDetails(InsuranceOfferStore.insurance_detail.id).then(data => {
				setTypeID(data?.view?.type_id);
				data?.view?.addons?.map((obj) => {
					obj.add_on_id = obj.id;
					obj.rate = parseInt(obj.rate).toFixed(3);
					return null
				})
				let ncb_doc = '';
				if (data?.view?.documents) {
					let documents = data?.view?.documents;
					ncb_doc = documents.filter(x => x.ds_id === 28);
					if (ncb_doc) {
						ncb_doc = ncb_doc[0];
					}
					ncb_doc && ncb_doc.url !== null && updateFileList([
						{
							uid: "-1",
							name: "image.png",
							status: "done",
							url: ncb_doc.url,
						},
					]);
				}
				setcat_id(data?.view?.ins_category?.id);
				setAddOns(data?.view?.addons);
				form.setFieldsValue({
					cat_id: data?.view?.ins_category?.id,
					pass_cat_id: data?.view?.ins_vehicle?.passing_category?.name ? data?.view?.ins_vehicle?.passing_category?.name : "N/A",
					pass_sub_cat_id: data?.view?.ins_vehicle?.passing_sub_category?.name ? data?.view?.ins_vehicle?.passing_sub_category?.name : "N/A",
					rto_place_id: data?.view?.ins_vehicle?.rto_places?.rto_place ? data?.view?.ins_vehicle?.rto_places?.rto_place : "N/A",
					tp_period_requested: tpPeriodObj[data?.view?.tp_period_requested],
					cust_cat_id: data?.view?.ins_category?.name,
					cust_company_id: data?.view?.ins_company?.name,
					ncb_per_requested: data?.view?.ncb_per_requested,
				})
			})
		}
	}, [InsuranceQuotationStore, props.visible, form, InsuranceOfferStore.insurance_detail, getInsCategoryList])
	const validate = (data) => {
		let verified = true;
		if (data?.add_on_quotes?.length > 0) {
			data.add_on_quotes.map(obj => {

				if ((!obj.is_selected) && obj.verification === false) {
					verified = false;
				}
				return null;
			})
		}
		if (verified) {
			return true
		}
		else {
			return false
		}
	}
	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		if (validate(data)) {

			if (data.add_on_quotes) {
				data.add_on_quotes.map(obj => {
					obj.is_selected = obj.is_selected ? 1 : 0;
					obj.amount = (Math.round(obj.amount * 100) / 100).toFixed(0);
					return null;
				})
			}
			if (data.tp_period === null) {
				data.tp_period = 0;
			}
			data.nil_dep = data.nil_dep ? (Math.round(data.nil_dep * 100) / 100).toFixed(0) : 0;
			data.tp_insurance = data.tp_insurance ? (Math.round(data.tp_insurance * 100) / 100).toFixed(0) : 0;
			data.nil_dep_insurance = data.nil_dep_insurance ? (Math.round(data.nil_dep_insurance * 100) / 100).toFixed(0) : 0;
			let newData = { ...InsuranceQuotationStore.generatedValues?.quote, ...data }
			data = newData;
			data.ins_offer_id = InsuranceOfferStore.insurance_detail.id;
			InsuranceQuotationStore.addInsuranceQuotation(data).then((data) => {
				if (InsuranceOfferStore.viewValues) {
					InsuranceOfferStore.insuranceDetail(InsuranceOfferStore.viewValues);
				}
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
				close();
			}).catch((e) => {
				// console.log("error......", e);
				if (e.errors) {
					form.setFields(convertError(e.errors));
				}
			})
				.finally(() => setSaving(false));
		}
		else {
			vsmNotify.error({
				message: "Add-on verification is failed.Please check add-on list"
			})
		}
	};

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
		setAddOns(null)
		setaddon_verified(true);
		setextraAddons(null)
		setTypeID(null);
		updateFileList([]);
		setvisibleDetails(false);
	};

	return (
		<Drawer
			className="addModal"
			title={`New Quotation`}
			width="80%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					form="addInsuranceQuotation"
					loading={saving}
					className="mr-35"
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
			]}
		>
			<InsuranceQuotationFormComponent
				form={form}
				addon_verified={addon_verified}
				addOns={addOns}
				setaddon_verified={setaddon_verified}
				setDisabled={setDisabled}
				handleSubmit={handleSubmit}
				extraAddons={extraAddons}
				fileList={fileList}
				setextraAddons={setextraAddons}
				id="addInsuranceQuotation"
				cat_id={cat_id}
				visibleDetails={visibleDetails}
				setvisibleDetails={setvisibleDetails}
				type="add"
				typeID={typeID}
			/>
		</Drawer>
	);
});

export default AddQuotationComponent;
