import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

const ActiveFinanceStructure = observer((props) => {
	const history = useHistory();
	const { className, title, count, redirectLink, filterValue, listType } = props;

	const handleRedirect = () => {
		localStorage.setItem("list_type_irr", "");
		if (listType) {
			localStorage.setItem("list_type_irr", listType);
		}
		else {
			localStorage.setItem("active_finance", "");
			if (filterValue) {
				localStorage.setItem("active_finance", filterValue);
			}
		}
		history.push(redirectLink);
	}

	return (
		<>
			<li className={`widget_row ${className}`} onClick={() => handleRedirect()}>
				<div className="widget_left">
					<p>{title}</p>
				</div>
				<div className="widget_right">
					<p>{count}</p>
				</div>
			</li>
		</>
	)
})

export default ActiveFinanceStructure
