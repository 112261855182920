import ServerGridConfig from "../../../config/ServerGridConfig";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import useStore from "../../../store";
import { observer } from "mobx-react";
import { vsmCommon } from "../../../config/messages";
import { booking_status, DateComparator } from "../../../utils/GlobalFunction";
import Unauthorized from "../../commonpage/Unauthorized";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import { PageHeader} from "antd";


const HistoryListComponent = observer((props) => {
	const {
		CorporateReportStore: { list_data, setupGrid, setPageSize, per_page,},
		AUTH: { checkPrivileges },
	} = useStore();

	

	const gridOptions = {
		columnDefs: [
			{
				headerName: "# ID",
				field: "srno",
				filter: "agNumberColumnFilter",
				pinned: "left",
				minWidth: 120,
				width: 120,
			},
			{
				headerName: "Zform ID",
				field: "booking_id",
				filter: "agNumberColumnFilter",
				minWidth: 120,
				width: 120,
				valueGetter: (params) => (params.data && params.data.booking_id) ? params.data.booking_id : "N/A"
			},
			{
				headerName: "CO NO",
				field: "booking.co_no",
				valueGetter: (params) => (params.data && params.data.booking?.co_no) ? params.data.booking?.co_no : "N/A"
			},
			{
				headerName: "Order Date",
				field: "booking.date",
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator,
					browserDatePicker: true
				},
			},
			{
				headerName: "Completed Date",
				field: "date_completed",
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator,
					browserDatePicker: true
				},
			},
			{
				headerName: "Invoice Date",
				field: "booking.inv_date",
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator,
					browserDatePicker: true
				},
			},
			{
				headerName: "Corp Approval Date",
				field: "approval_date",
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator,
					browserDatePicker: true
				},
			},
			{
				headerName: "Invoice No",
				field: "booking.inv_no",
				filter: "agNumberColumnFilter",
				valueGetter: (params) => (params.data && params.data.booking?.inv_no) ? params.data.booking?.inv_no : "N/A"
			},			
			{
				headerName: "Customer Name",
				field: "booking.booking_customer.full_name",
				valueGetter: (params) => (params.data && params.data?.booking?.booking_customer?.changed_name) ? params.data?.booking?.booking_customer?.changed_name : params.data?.booking?.booking_customer?.full_name
			},			
			{
				headerName: "Model",
				field: "booking.booking_model.model.name",
				valueGetter: (params) => (params.data && params.data.booking?.booking_model) ? params.data.booking?.booking_model?.model?.name : "N/A",
			},
			{
				headerName: "Variant",
				field: "booking.booking_model.variant.name",
				valueGetter: (params) => (params.data && params.data.booking?.booking_model) ? params.data.booking?.booking_model?.variant?.name : "N/A",
			},
			{
				headerName: "Color",
				field: "booking.booking_model.color.name",
				valueGetter: (params) => (params.data && params.data.booking?.booking_model) ? params.data.booking?.booking_model?.color?.name : "N/A",
			},
			{
				headerName: "Engine No",
				field: "booking.booking_model.engine_no",
				valueGetter: (params) => (params.data && params.data.booking?.booking_model) ? params.data.booking?.booking_model?.engine_no : "N/A",
			},
			{
				headerName: "Chassis No",
				field: "booking.booking_model.chassis_no",
				valueGetter: (params) => (params.data && params.data.booking?.booking_model) ? params.data.booking?.booking_model?.chassis_no : "N/A",
			},
			{
				headerName: "Dealer Share",
				field: "dealer_share",
				filter: "agNumberColumnFilter",
				valueGetter: (params) => (params.data && params.data.dealer_share) ? params.data.dealer_share : "N/A",
			},
			{
				headerName: "SAIPL Share",
				field: "mfg_share",
				filter: "agNumberColumnFilter",
				valueGetter: (params) => (params.data && params.data.mfg_share) ? params.data.mfg_share : "N/A",
			},
			{
				headerName: "Category",
				field: "deal_category.name",
				valueGetter: (params) => (params.data && params.data.deal_category) ? params.data.deal_category?.name : "N/A"
			},
			{
				headerName: "Type",
				field: "deal_type.name",
				valueGetter: (params) => (params.data && params.data.deal_type) ? params.data.deal_type?.name : "N/A"
			},
			{
				headerName: "Company Name",
				field: "approved_company.name",
				valueGetter: (params) => (params.data && params.data.approved_company?.name) ? params.data.approved_company?.name : "N/A",
			},
			{
				headerName: "Location Name",
				field: "booking.location.name",
				valueGetter: (params) => (params.data && params.data.booking?.location) ? params.data.booking?.location?.name : "N/A",
			},
			{
				headerName: "Sales Consultant",
				field: "booking.sales_consultant.name",
				valueGetter: (params) => (params.data && params.data.booking?.sales_consultant) ? params.data.booking?.sales_consultant?.name : "N/A",
			},
			{
				headerName: "Remarks",
				field: "remarks",
				valueGetter: (params) => (params.data && params.data.remarks) ? params.data.remarks : "N/A"
			},
			{
				headerName: "Status",
				field: "status",
				filter: "agSetColumnFilter",
				valueGetter: (params) => (params.data && params.data.status) ? booking_status[params.data.status] : "N/A",
				filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						params.success([booking_status[10], booking_status[20], booking_status[22], booking_status[25], booking_status[30]])
					}
				}
			},			
		],
	};
	ServerGridConfig.options.paginationPageSize = 5000
	ServerGridConfig.options.cacheBlockSize = 5000
	return (
		<>
			{!checkPrivileges("#53010#") ? (
				<Unauthorized />
			) : (
				<PageHeader
					title={BreadcrumbConfig.CorporateReport.title}
					className="tableAreaSec"
					extra={
						<BreadcrumbComponent items={BreadcrumbConfig.CorporateReport.path} />
					}
				>
					<div className="listCountNew">						
						<RecordPerPage
							key="2"
							style={{ width: "150px" }}
							defaultValue={per_page + " per page"}
							onChange={setPageSize}
						/>

					</div>
					<div className="ag-theme-alpine grid_wrapper">
						<AgGridReact
							rowHeight={ServerGridConfig.rowHeight}
							headerHeight={ServerGridConfig.headerHeight}
							rowData={list_data}
							modules={AllModules}
							columnDefs={gridOptions.columnDefs}
							defaultColDef={ServerGridConfig.defaultColDef}
							columnTypes={ServerGridConfig.columnTypes}
							overlayNoRowsTemplate={vsmCommon.noRecord}
							onGridReady={setupGrid}
							gridOptions={ServerGridConfig.options}
						/>
					</div>
				</PageHeader>
			)}
		</>
	);
});

export default HistoryListComponent;
