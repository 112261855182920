import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import ServerGridConfig from "../../../config/ServerGridConfig";
import { globalStatus } from "../../../utils/GlobalFunction";

export default class NotificationStore {
	AUTH = null
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	infoList = [];
	list_indexObj = null;

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		const { api } = params
		var datasource = this.createDatasource(ServerGridConfig.options)
		api.setServerSideDatasource(datasource)

	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel
		var final_sort = params.sortModel

		if (final_filter["type_id"]) {
			let values_changed = []
			final_filter["type_id"].values.forEach(x => (
				values_changed.push(globalStatus('notification_status', 'value', x))
			))
			final_filter["type_id"].values = values_changed
		}
		if (final_filter["status"]) {
			let values_changed = []
			final_filter["status"].values.forEach(x => {
				values_changed.push(x === "No" ? 0 : 1)
			})
			final_filter["status"].values = values_changed
		}

		return { final_filter, final_sort }
	}

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request)
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				this.getList(payload).then((data) => {
					if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.list.data, data.list.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.colId)
						})
					}
				})
			}
		}
	}

	// call api to get records
	getList = (payload) => {
		return Axios.post(`/reports/users/notifications`, payload).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.created = item.created ? moment(item.created).format("DD/MM/YYYY") : "N/A";
					item.message = item.message ? item.message : "N/A";
					item.status = item.status === 0 ? "No" : "Yes";
					return null;
				});
			}
			this.list_data =
				data.list && data.list.data.length ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data
		});
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	changeInfoFilter = (index, final_filter = {}) => {
		let obj = {
			"filter_data": final_filter,
			"sort_data": [],
			"per_page": 10,
			"page": index
		}
		return obj;
	}

	getInfoList = (payload = {}) => {
		return Axios.post(`/general/info/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.srno = index + 1;
					// item.user.name = item.user.name ? item.user.name : "N/A";
					item.created = item.created ? moment(item.created).format("DD/MM/YYYY") : item.created;
					return null;
				});
			}
			let obj = {
				from_page: 1,
				last_page: data.list.last_page,
				current_page: data.list.current_page
			}
			this.list_indexObj = obj;
			this.infoList = data.list && data.list.data.length ? data.list.data : null;
			return { count: data.info_cnt, length: data.list ? data.list.data.length : 0 }
		})
	}

	readInfo = (id) => {
		return Axios.post(`/general/info/read/${id}`).then(({ data }) => {

			return data;
		})
	}


}

decorate(NotificationStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	defaultType: observable,
	infoList: observable,
	list_indexObj: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	changeInfoFilter: action,
	onFilterChanged: action,
	getInfoList: action,
	readInfo: action,
});
