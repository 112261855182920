import React, { useState } from "react";
import { Form, Button, Modal, Row, Col } from "antd";
import { vsmNotify, vsmScheme } from "../../../../config/messages";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash/debounce";
import InputComponent from "../../../../component/InputComponent";
import moment from "moment";

const DateExtendComponent = observer((props) => {
	const dateFormat = "DD/MM/YYYY";
	const [form] = Form.useForm();

	const {
		ManagePackagesStore: { DateExtendData },
		ManagePackagesStore
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	// Handle submit and call function to save new record
	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {		
		form
			.validateFields()
			.then((data) => {
				setDisabled(false);
			})
			.catch((e) => {
				setDisabled(true);
			});
	}, 500);
	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true);
		data.id = ManagePackagesStore.editValues.id
		data.to_date = moment(data.to_date).format("YYYY-MM-DD");
		DateExtendData(data)
			.then((data) => {
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
		setDisabled(true);
	};

	const disabledDate = (current) => {
		return current && current < moment().startOf("day");
	};

	return (
		<Modal
			className="addModal"
			centered
			title="Date Extend"
			width={534}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					form="addDateExtendForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
			]}
		>
			<Form
				form={form}
				id="addDateExtendForm"
				onFinish={handleSubmit}
				labelCol={{ span: 24 }}
				onChange={handleChange}
			>
				<Row gutter={30}>					
					<Col xs={{ span: 24 }}>
						<InputComponent
							type="date"
							mode="date"
							required
							format={dateFormat}
							onChange={handleChange}
							disabledDate={disabledDate}
							label="To Date"
							placeholder="To Date"
							name="to_date"
							rules={vsmScheme.validation.to_date}
						/>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
});

export default DateExtendComponent;
