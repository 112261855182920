import React, { useState } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import useStore from "../../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash/debounce";
import InputComponent from '../../../../component/InputComponent';
import { vsmQuotationLostCase, vsmNotify } from "../../../../config/messages";
import { default_roles } from '../../../../utils/GlobalFunction';

const RevertInsuranceComponent = observer((props) => {
	const [form] = Form.useForm()
	const {
		InsuranceOfferStore,
		AUTH,
		InsuranceOfferStore: {
			requestRevertInsurance
		},
	} = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true);

	const handleSubmit = (data) => {
		requestRevertInsurance(InsuranceOfferStore.revertInsuraceData?.id, data).then((res) => {
			close(true);
			vsmNotify.success({
				message: res.STATUS.NOTIFICATION[0],
			});
		}).catch((e) => {
			if (e.errors) {
				form.setFields(e.errors);
			}
		}).finally(() => {
			setSaving(false);
		});

	};

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);

	const close = (reload = false) => {
		props.close(reload);
		form.resetFields();
		setDisabled(true);
	}

	return (
		<Modal
			centered
			title={`Revert Insurance Case`}
			visible={props.visible}
			onCancel={() => close()}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button
					key="1"
					className="mr-35"
					form="revertInsurance"
					loading={saving}
					disabled={disabled}
					htmlType="submit"
					type="primary"
				>
					Yes
				</Button>,
				<Button
					key="3"
					htmlType="button"
					className="cancelBtn"
					onClick={() => close()}
				>
					No
				</Button>,
			]}
		>
			<Form form={form} id="revertInsurance" layout="vertical" onFinish={handleSubmit}>
				<Row align="middle">
					<Col span={24}>
						<div className="revert_package_sec">
							<ul>
								{[default_roles.admin, default_roles.mis_executive].includes(AUTH?.user?.role_id) ?
									<>
										<li>This will reset insurance offer, so Z-Form owner can apply insurance offer again.</li>
										<li>
											This is irreversible process.
										</li>
									</>
									:
									<>
										<li>MIS or Admin can revert the insurance’s case</li>
										<li>This action will send a request to MIS/Admin for the same.</li>
										<li>There will not be any immediate effect on z-form, you must wait for MIS/Admin action.</li>
									</>
								}
							</ul>
						</div>
					</Col>
					<Col xs={{ span: 24 }}>
						<InputComponent
							type="textarea"
							label="Notes"
							placeholder="Note"
							name="notes"
							required
							onChange={handleChange}
							rules={vsmQuotationLostCase.validation.remark}
						/>
					</Col>
				</Row>
				<p className="text-center">Would you like to continue?</p>
			</Form>
		</Modal >
	)
})

export default RevertInsuranceComponent
