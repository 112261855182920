import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Drawer, Upload } from "antd";
import useStore from "../../../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { vsmNotify, vsmRTOOffer } from "../../../../../../config/messages";
import InputComponent from "../../../../../../component/InputComponent";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { UploadIcon } from "../../../../../../config/IconsConfig";
import Axios from "axios"
import debounce from "lodash/debounce";
import moment from "moment";
import { Bharat_Passing_doc_id, CurrencyFormat, default_roles, validFileTypes } from "../../../../../../utils/GlobalFunction";

const ApplyRTOComponent = observer((props) => {
	const [rtoForm] = Form.useForm();
	const [pertForm] = Form.useForm();
	const { 
		ManageZFormsStore,
		ManageZFormsStore: { setRtoPlaceId, rtoPlaceId },
		AUTH,
		RTODiscReqPendingStore
	 } = useStore();
	// const [disabled, setDisabled] = useState(true);
	const [pertdisabled, setpertDisabled] = useState(true);
	const [rtoSaving, setRTOSaving] = useState(false);
	const [rtoDisabled, setrtoDisabled] = useState(false)
	const [pertSaving, setPertSaving] = useState(false);
	const [pertDisabled, setPertDisabled] = useState(false)
	const [hideCRTMSec, setHideCRTMSec] = useState(true)
	const [ctDisabled, setCTDisabled] = useState(false)
	const [documentTitle, setDocumentTitle] = useState("")
	const [hypeDisabled, sethypeDisabled] = useState(true);
	const [bharatPassingDisabled, setBharatPassingDisabled] = useState(true);
	const [rtoDocDisabled, setRtoDocDisabled] = useState(true);

	const [isPendingStatus, setisPendingStatus] = useState(true);
	const [formDocID, setformDocID] = useState(0);
	const [proofoCustomer, setProofoCustomer] = useState(0);
	const [declarationCertificate, setdeclarationCertificate] = useState(0);
	const [hideBharatPassingDoc, setHideBharatPassingDoc] = useState(false)
	const [hideBharatPassingSec, setHideBharatPassingSec] = useState(false)
	const [rtoBharatPassingData, setRtoBharatPassingData] = useState()
	const [isSpecialNumber, setIsSpecialNumber] = useState(false);

	const { isZform = true } = props;

	const handleRTOSubmit = (data) => {
		if (data.rto_status === 1 && !data.rto_id) {
			vsmNotify.error({
				message: 'Please select RTO',
			});
		} else {
			setRTOSaving(true);
			const formdata = {}
			formdata.id = ManageZFormsStore.applyRTOValues.booking.rto_offer.id
			formdata.booking_id = ManageZFormsStore.applyRTOValues.booking.id
			if (data.rto_status === 1) {
				formdata.passing_type = data.passing_type
				formdata.hypo_chrg_flag = data?.hypo_chrg_flag;
				formdata.rto_place_id = data?.rto_id;
				formdata.is_special_number = data?.is_special_number;
				formdata.special_number = data?.special_number;
			}
			else {
				formdata.hypo_chrg_flag = 1;
				formdata.passing_type = 0;
				formdata.rto_place_id = null;
				formdata.is_special_number = 0;
				formdata.special_number = null;
			}
			formdata.crtm = data.rto_status === 1 ? 0 : data.crtm;
			formdata.bharat_passing = data?.bharat_passing
			
			ManageZFormsStore.ApplyRTOOffer(formdata)
				.then((data) => {
					close();
					vsmNotify.success({
						message: data.STATUS.NOTIFICATION[0],
					});
				})
				.catch((e) => {
					if (e.errors) {
						rtoForm.setFields(e.errors);
					}
				})
				.finally(() => setRTOSaving(false));
		}
	}

	const handleParticularsSubmit = (data) => {
		// setShowRequired(false)
		setPertSaving(true)
		data.booking_id = ManageZFormsStore.applyRTOValues.booking.id
		if (data.vehicle_reg_date && data.vehicle_reg_date !== null) {
			data.vehicle_reg_date = moment(data.vehicle_reg_date).format("YYYY-MM-DD");
		}
		else {
			data.vehicle_reg_date = undefined
		}
		ManageZFormsStore.ParticularsRTOOffer(data, isZform)
			.then((data) => {
				if (!isZform) {
					RTODiscReqPendingStore.getList();
				}
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					pertForm.setFields(e.errors);
				}
			})
			.finally(() => setPertSaving(false));
	}

	const calculateRTOCharges = () => {
		let formData = {
			booking_id: ManageZFormsStore.applyRTOValues.booking.id,
			passing_type: rtoForm.getFieldValue("passing_type"),
			hypo_chrg_flag: rtoForm.getFieldValue("hypo_chrg_flag"),
			bharat_passing: rtoForm.getFieldValue("bharat_passing"),
			rto_place_id: rtoForm.getFieldValue("rto_id"),
		}
		ManageZFormsStore.RecalculateRTOAmount(formData).then((data) => {
			ManageZFormsStore.applyRTOValues.booking.rto_offer = data.rtoOfferData;
			setRtoBharatPassingData(data.rtoOfferData.rto_bharat_passing)
			rtoForm.setFieldsValue({
				rto_amount: data.rtoOfferData ? data.rtoOfferData.rto_tax : 0
			})
		})
	}

	useEffect(() => {
		if (rtoForm.getFieldValue("rto_id")) {
			setRtoPlaceId(rtoForm.getFieldValue("rto_id"))
		}
	},[setRtoPlaceId, rtoForm])

	useEffect(() => {
		if (ManageZFormsStore.applyRTOValues && props.visible) {

			setPertDisabled(true)

			const crtm = ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.crtm;
			if (AUTH.checkPrivileges("#8360#") && crtm === 0 && ManageZFormsStore.applyRTOValues.booking.rto_status >= 0 && (ManageZFormsStore.applyRTOValues.booking.status === 20 || ManageZFormsStore.applyRTOValues.booking.status === 22 || ManageZFormsStore.applyRTOValues.booking.status === 25 || ManageZFormsStore.applyRTOValues.booking.status === 30 || ManageZFormsStore.applyRTOValues.booking.status === 40)) {
				setPertDisabled(false)
			}

			setrtoDisabled(true)
			if ((AUTH?.user?.id === ManageZFormsStore.applyRTOValues.booking.sales_consultant.id || [
				default_roles.mis_executive,
				default_roles.admin,
			].includes(AUTH?.user?.role_id)) && (ManageZFormsStore.applyRTOValues.booking.rto_status === null || ManageZFormsStore.applyRTOValues.booking.rto_status === 0) && (ManageZFormsStore.applyRTOValues.booking.status === 20 || ManageZFormsStore.applyRTOValues.booking.status === 22 || ManageZFormsStore.applyRTOValues.booking.status === 25)) {
				setrtoDisabled(false)
			}

			if (ManageZFormsStore.applyRTOValues.booking.rto_offer.crtm === 0) {
				setHideCRTMSec(false)
			}

			if ([
				default_roles.mis_executive,
				default_roles.admin,
			].includes(AUTH?.user?.role_id)) {
				sethypeDisabled(false);
			}

			if ([
				default_roles.mis_executive,
				default_roles.admin,
			].includes(AUTH?.user?.role_id) || AUTH?.user?.id === ManageZFormsStore.applyRTOValues.booking.sales_consultant.id) {
				setBharatPassingDisabled(false);
			}

			if (AUTH.checkPrivileges("#8355#") && (ManageZFormsStore.applyRTOValues?.booking?.status === 20 || ManageZFormsStore.applyRTOValues?.booking?.status === 22 || ManageZFormsStore.applyRTOValues?.booking?.status === 25 || ManageZFormsStore.applyRTOValues?.booking?.status === 30 || ManageZFormsStore.applyRTOValues?.booking?.status === 40) && [default_roles.rto_executive].includes(AUTH?.user?.role_id)) {
				setRtoDocDisabled(false)
			}

			const rto_status = ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.crtm === 0 ? 1 : ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.crtm === 1 ? 0 : null;
			const bharat_passing = ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.bharat_passing;
			const rto_id = rto_status === 1 ? ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.rto_place_id : null;
			rtoForm.setFieldsValue({
				rto_status: rto_status,
				rto_id: rto_id,
				crtm: crtm,
				amount: ManageZFormsStore.applyRTOValues?.booking?.rto_status === null ? ManageZFormsStore.applyRTOValues?.config?.crtm : 0,
				rto_amount: ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.crtm === 0 ? ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.rto_tax : 0,
				passing_type: ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.passing_type,
				hypo_chrg_flag: ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.hypo_chrg_flag,
				bharat_passing: bharat_passing,
				is_special_number: ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.is_special_number,
				special_number: ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.special_number
			})

			if (crtm === 0) {
				setHideCRTMSec(false)
				setHideBharatPassingSec(true)
				ManageZFormsStore.getRTOList()
				setRtoPlaceId(rto_id)
			}

			if (rto_status === 1) {

				if (ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.is_special_number === 1) {
					setIsSpecialNumber(true);
				}
				calculateRTOCharges();
			}

			if (ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.passing_type === 0 && ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.bharat_passing === 1) {
				setHideBharatPassingDoc(true)
				setHideBharatPassingSec(true)
			} else if (ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.passing_type === 0 && ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.bharat_passing === 0) {
				setHideBharatPassingDoc(false)
				setHideBharatPassingSec(true)
			}

			pertForm.setFieldsValue({
				vehicle_reg_no: ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.vehicle_reg_no,
				vehicle_reg_date: ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.vehicle_reg_date ? moment(ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.vehicle_reg_date) : null,
				vehicle_temp_no: ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.vehicle_temp_no,
				rto_amount: ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.crtm === 0 ? ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.rto_tax : 0,
				rto_status: ManageZFormsStore.applyRTOValues?.booking?.rto_status === null ? null : ManageZFormsStore.applyRTOValues?.booking?.rto_status
			})
			setisPendingStatus(ManageZFormsStore.applyRTOValues?.booking?.rto_status ? false : true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ManageZFormsStore.applyRTOValues?.booking])

	useEffect(() => {
		if (ManageZFormsStore.applyRTOValues?.booking?.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Form_60)) {
			let formBkDocId = ManageZFormsStore.applyRTOValues?.booking?.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Form_60)
			setformDocID(formBkDocId)
		} else {
			setformDocID(0)
		}
		if (ManageZFormsStore.applyRTOValues?.booking?.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.ID_Proof_of_Customer)) {
			let formBkDocId1 = ManageZFormsStore.applyRTOValues?.booking?.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.ID_Proof_of_Customer)
			setProofoCustomer(formBkDocId1)
		}
		else {
			setProofoCustomer(0)
		}
		if (ManageZFormsStore.applyRTOValues?.booking?.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Declaration_Certificate)) {
			let formBkDocId2 = ManageZFormsStore.applyRTOValues?.booking?.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Declaration_Certificate)
			setdeclarationCertificate(formBkDocId2)
		}
		else {
			setdeclarationCertificate(0)
		}
	}, [ManageZFormsStore.applyRTOValues?.booking?.documents])

	// useEffect(() => {
	// 	if (ManageZFormsStore.applyRTOValues && props.visible) {
	// 		setPertDisabled(true)
	// 		// setShowRequired(true)

	// 		if (AUTH.checkPrivileges("#8360#") && ManageZFormsStore.applyRTOValues.booking.rto_offer.crtm === 0 && ManageZFormsStore.applyRTOValues.booking.rto_status >= 0 && (ManageZFormsStore.applyRTOValues.booking.status === 20 || ManageZFormsStore.applyRTOValues.booking.status === 22 || ManageZFormsStore.applyRTOValues.booking.status === 25 || ManageZFormsStore.applyRTOValues.booking.status === 30 || ManageZFormsStore.applyRTOValues.booking.status === 40)) {
	// 			setPertDisabled(false)
	// 		}

	// 		setrtoDisabled(true)
	// 		if ((AUTH?.user?.id === ManageZFormsStore.applyRTOValues.booking.sales_consultant.id || [
	// 			default_roles.mis_executive,
	// 			default_roles.admin,
	// 		].includes(AUTH?.user?.role_id)) && (ManageZFormsStore.applyRTOValues.booking.rto_status === null || ManageZFormsStore.applyRTOValues.booking.rto_status === 0) && (ManageZFormsStore.applyRTOValues.booking.status === 20 || ManageZFormsStore.applyRTOValues.booking.status === 22 || ManageZFormsStore.applyRTOValues.booking.status === 25)) {
	// 			setrtoDisabled(false)
	// 		}

	// 		setHideCTSec(false)
	// 		if (ManageZFormsStore.applyRTOValues.booking.rto_offer.crtm === 0) {
	// 			setHideCTSec(true)
	// 			setHideCRTMSec(false)
	// 		}

	// 		if ([
	// 			default_roles.mis_executive,
	// 			default_roles.admin,
	// 		].includes(AUTH?.user?.role_id)) {
	// 			sethypeDisabled(false);
	// 		}

	// 		// if (fetchRto && ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.rto_place_id !== null) {
	// 			// ManageZFormsStore.setRtoPlaceId(ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.rto_place_id);
	// 		ManageZFormsStore.getRTOList().then(() =>
	// 			setFetchRto(false),
	// 		)
	// 		// }

	// 		// if (fetchRto && ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.rto_place_id !== null) {
	// 		// 	ManageZFormsStore.getRTOList().then(() =>
	// 		// 		setFetchRto(false),
	// 		// 		ManageZFormsStore.setRtoPlaceId(ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.rto_place_id)
	// 		// 	)
	// 		// }
	// 		// if (fetchRto && ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.rto_place_id !== null) {
	// 		// 	ManageZFormsStore.setRtoPlaceId(ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.rto_place_id);
	// 		// }

	// 		const rto_status = ManageZFormsStore.applyRTOValues.booking.rto_offer.crtm === 0 ? 1 : ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.crtm === 1 ? 0 : null;
			
	// 		rtoForm.setFieldsValue({
	// 			rto_status: rto_status,
	// 			rto_id: rto_status === 1 ? ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.rto_place_id : null,
	// 			crtm: ManageZFormsStore.applyRTOValues.booking.rto_offer.crtm,
	// 			amount: ManageZFormsStore.applyRTOValues.booking.rto_status === null ? ManageZFormsStore.applyRTOValues.config.crtm : 0,
	// 			rto_amount: ManageZFormsStore.applyRTOValues.booking.rto_offer.crtm === 0 ? ManageZFormsStore.applyRTOValues.booking.rto_offer.rto_tax : 0,
	// 			passing_type: ManageZFormsStore.applyRTOValues.booking.rto_offer.passing_type,
	// 			hypo_chrg_flag: ManageZFormsStore.applyRTOValues.booking.rto_offer.hypo_chrg_flag,
	// 			bharat_passing: ManageZFormsStore.applyRTOValues.booking.rto_offer.bharat_passing,
	// 			is_special_number: ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.is_special_number,
	// 			special_number: ManageZFormsStore.applyRTOValues.booking.rto_offer.special_number,

	// 		})
	// 		let isRto = true;
	// 		if (rtoForm.getFieldValue("rto_id") && isRto === true && rto_status === 1) {
	// 			isRto = false;
	// 			calculateRTOCharges();
	// 		}

	// 		if (ManageZFormsStore.applyRTOValues?.booking?.rto_offer?.is_special_number === 1) {
	// 			setIsSpecialNumber(true);
	// 		}
	// 		if (ManageZFormsStore.applyRTOValues.booking.rto_offer.passing_type === 0 && ManageZFormsStore.applyRTOValues.booking.rto_offer.bharat_passing === 1) {
	// 			setHideBharatPassingDoc(true)
	// 			setHideBharatPassingSec(true)
	// 		} else if (ManageZFormsStore.applyRTOValues.booking.rto_offer.passing_type === 0 && ManageZFormsStore.applyRTOValues.booking.rto_offer.bharat_passing === 0) {
	// 			setHideBharatPassingDoc(false)
	// 			setHideBharatPassingSec(true)
	// 		}
	// 		if (ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Form_60)) {
	// 			let formBkDocId = ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Form_60)
	// 			setformDocID(formBkDocId)
	// 		} else {
	// 			setformDocID(0)
	// 		}
	// 		if (ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.ID_Proof_of_Customer)) {
	// 			let formBkDocId1 = ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.ID_Proof_of_Customer)
	// 			setProofoCustomer(formBkDocId1)
	// 		}
	// 		else {
	// 			setProofoCustomer(0)
	// 		}
	// 		if (ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Declaration_Certificate)) {
	// 			let formBkDocId2 = ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Declaration_Certificate)
	// 			setdeclarationCertificate(formBkDocId2)
	// 		}
	// 		else {
	// 			setdeclarationCertificate(0)
	// 		}
	// 		pertForm.setFieldsValue({
	// 			vehicle_reg_no: ManageZFormsStore.applyRTOValues.booking.rto_offer.vehicle_reg_no,
	// 			vehicle_reg_date: ManageZFormsStore.applyRTOValues.booking.rto_offer.vehicle_reg_date ? moment(ManageZFormsStore.applyRTOValues.booking.rto_offer.vehicle_reg_date) : null,
	// 			vehicle_temp_no: ManageZFormsStore.applyRTOValues.booking.rto_offer.vehicle_temp_no,
	// 			rto_amount: ManageZFormsStore.applyRTOValues.booking.rto_offer.crtm === 0 ? ManageZFormsStore.applyRTOValues.booking.rto_offer.rto_tax : 0,
	// 			rto_status: ManageZFormsStore.applyRTOValues.booking.rto_status === null ? null : ManageZFormsStore.applyRTOValues.booking.rto_status
	// 		})
	// 		setisPendingStatus(ManageZFormsStore.applyRTOValues.booking.rto_status ? false : true)

	// 	}
	// }, [props, ManageZFormsStore, AUTH, rtoForm, pertForm, ManageZFormsStore.applyRTOValues?.booking?.documents, fetchRto])
	let GSTclac = 1 + 1 * ManageZFormsStore.applyRTOValues?.booking?.booking_model?.gst_per / 100
	let DiscountedExshowroomPrice = (ManageZFormsStore.applyRTOValues?.booking?.booking_ledger?.ex_showroom_discounted / GSTclac).toFixed()
	const RTOAmountInfo = ManageZFormsStore.applyRTOValues && (
		<div className="schemeInfo inputTooltip">
			<div className="schemeWrap">
				<p>Vehicle Purchase Cost (W/O GST)</p>
				{ManageZFormsStore.applyRTOValues.booking.booking_ledger && (
					<span>
						{ManageZFormsStore.applyRTOValues.booking.booking_model?.stock?.basic_amount > DiscountedExshowroomPrice ?
							<CurrencyFormat className="greenText"
								value={
									ManageZFormsStore.applyRTOValues.booking.booking_model?.stock?.basic_amount
								}
							/> : <CurrencyFormat
								value={
									ManageZFormsStore.applyRTOValues.booking.booking_model?.stock?.basic_amount
								}
							/>
						}
					</span>
				)}
			</div>

			<div className="schemeWrap">

				<p>Discounted Ex-Exshowroom Price (W/O GST)</p>

				{ManageZFormsStore.applyRTOValues.booking.booking_ledger && (
					<span>

						{ManageZFormsStore.applyRTOValues.booking.booking_model?.stock?.basic_amount < DiscountedExshowroomPrice ?
							<CurrencyFormat className="greenText"
								value={
									DiscountedExshowroomPrice
								}
							/> : <CurrencyFormat
								value={
									DiscountedExshowroomPrice
								}
							/>
						}
					</span>
				)}
			</div>
			<div className="schemeWrap">
				<p>RTO Rate</p>
				{rtoBharatPassingData && ManageZFormsStore.applyRTOValues.booking.rto_offer && (
					<span>
						{(rtoBharatPassingData.rto_per) + "%"}
					</span>
				)}
				{!rtoBharatPassingData && ManageZFormsStore.applyRTOValues.booking.rto_offer && (
					<span>
						{(ManageZFormsStore.applyRTOValues.booking.rto_offer.passing_type === 0 ? ManageZFormsStore.rto_place?.rto_individual : ManageZFormsStore.rto_place?.rto_company) + "%"}
					</span>
				)}
			</div>

			<div className="schemeWrap">
				<p>Other Amount</p>
					<span>
						<CurrencyFormat
							value={
								ManageZFormsStore.applyRTOValues.booking.rto_offer?.other_charges
							}
						/>
					</span>
			</div>
			{ManageZFormsStore.applyRTOValues.booking.rto_offer?.hypo_chrg_flag === 1 &&
				<div className="schemeWrap">
					<p>Fix Hypo Charge </p>
						<span>
							<CurrencyFormat
								value={
									ManageZFormsStore.applyRTOValues.booking.rto_offer?.hypo_charge
								}
							/>
						</span>
				</div>}
			<div className="schemeWrap">
				<p>Total RTO</p>
				{ManageZFormsStore.applyRTOValues.booking.rto_offer && (
					<span>
						<CurrencyFormat
							value={
								ManageZFormsStore.applyRTOValues.booking.rto_offer.rto_tax
							}
						/>
					</span>
				)}
			</div>
			{/* <div className="schemeWrap">
				<p>Fixed Charge</p>
				{ManageZFormsStore.applyRTOValues.booking.rto_offer && (
					<span>
						<CurrencyFormat
							value={
								ManageZFormsStore.applyRTOValues.booking.rto_offer?.crtm === 0 ?
									(
										ManageZFormsStore.applyRTOValues.booking.rto_offer?.hypo_charge + ManageZFormsStore.applyRTOValues.booking.rto_offer?.other_charges
									)
									:
									(
										ManageZFormsStore.applyRTOValues.booking.rto_offer && rtoForm.getFieldValue("hypo_chrg_flag") === 1 ?
											ManageZFormsStore.applyRTOValues.booking.rto_charges.hypothecation_charge + ManageZFormsStore.applyRTOValues.booking.rto_charges.other_charges
											:
											ManageZFormsStore.applyRTOValues.booking.rto_charges.other_charges
									)

							}
						/>
					</span>
				)}
			</div>
			
			<div className="schemeWrap">
				<p>Multi. Factor </p>
				{ManageZFormsStore.applyRTOValues.booking.rto_offer && (
					<span>
						<CurrencyFormat
							value={
								ManageZFormsStore.applyRTOValues.booking.booking_model.variant.bc.factor
							}
						/>
					</span>
				)}
			</div> */}
			<div className="schemeWrap">
				<p>Is Hypo. Incl.</p>
				{ManageZFormsStore.applyRTOValues.booking.rto_offer && (
					<span>
						{
							rtoForm.getFieldValue("hypo_chrg_flag") === 1 ? "Yes" : "No"
						}
					</span>
				)}
			</div>
		</div>
	)
	const RTOBharatPassingInfo = ManageZFormsStore.applyRTOValues && (
		<div className="schemeInfo inputTooltip">
			<div className="schemeWrap">
				<p>Vehicle Purchase Cost (W/O GST)</p>
				{ManageZFormsStore.applyRTOValues.booking.booking_ledger && (
					<span>
						{ManageZFormsStore.applyRTOValues.booking.booking_model?.stock?.basic_amount > DiscountedExshowroomPrice ?
							<CurrencyFormat className="greenText"
								value={
									ManageZFormsStore.applyRTOValues.booking.booking_model?.stock?.basic_amount
								}
							/> : <CurrencyFormat
								value={
									ManageZFormsStore.applyRTOValues.booking.booking_model?.stock?.basic_amount
								}
							/>
						}
					</span>
				)}
			</div>

			<div className="schemeWrap">

				<p>Discounted Ex-Exshowroom Price (W/O GST)</p>

				{ManageZFormsStore.applyRTOValues.booking.booking_ledger && (
					<span>

						{ManageZFormsStore.applyRTOValues.booking.booking_model?.stock?.basic_amount < DiscountedExshowroomPrice ?
							<CurrencyFormat className="greenText"
								value={
									DiscountedExshowroomPrice
								}
							/> : <CurrencyFormat
								value={
									DiscountedExshowroomPrice
								}
							/>
						}
					</span>
				)}
			</div>
			<div className="schemeWrap">
				<p>RTO Rate</p>
				{rtoBharatPassingData && ManageZFormsStore.applyRTOValues.booking.rto_offer && (
					<span>
						{(rtoBharatPassingData.rto_per) + "%"}
					</span>
				)}
				{!rtoBharatPassingData && ManageZFormsStore.applyRTOValues.booking.rto_offer && (
					<span>
						{(ManageZFormsStore.applyRTOValues.booking.rto_offer.passing_type === 0 ? ManageZFormsStore.rto_place?.rto_individual : ManageZFormsStore.rto_place?.rto_company) + "%"}
					</span>
				)}
			</div>

			<div className="schemeWrap">
				<p>Other Amount</p>
				{ManageZFormsStore.applyRTOValues.booking.rto_offer?.crtm === 0 ? (
					<span>
						<CurrencyFormat
							value={
								rtoBharatPassingData?.fix_amt
							}
						/>
					</span>
				) :
					(
						<span>
							<CurrencyFormat
								value={
									rtoBharatPassingData?.fix_amt
								}
							/>
						</span>
					)
				}
			</div>
			{ManageZFormsStore.applyRTOValues.booking.rto_offer?.hypo_chrg_flag === 1 &&
				<div className="schemeWrap">
					<p>Fix Hypo Charge </p>
					{ManageZFormsStore.applyRTOValues.booking.rto_offer?.crtm === 0 ? (
						<span>
							<CurrencyFormat
								value={
									rtoBharatPassingData?.hypothecation_charge
								}
							/>
						</span>
					) :
						(
							<span>
								<CurrencyFormat
									value={
										rtoBharatPassingData?.hypothecation_charge
									}
								/>
							</span>
						)
					}
				</div>}
			<div className="schemeWrap">
				<p>Total RTO</p>
				{ManageZFormsStore.applyRTOValues.booking.rto_offer && (
					<span>
						<CurrencyFormat
							value={
								ManageZFormsStore.applyRTOValues.booking.rto_offer.rto_tax
							}
						/>
					</span>
				)}
			</div>			
			<div className="schemeWrap">
				<p>Is Hypo. Incl.</p>
				{ManageZFormsStore.applyRTOValues.booking.rto_offer && (
					<span>
						{
							rtoForm.getFieldValue("hypo_chrg_flag") === 1 ? "Yes" : "No"
						}
					</span>
				)}
			</div>
		</div>
	)
	const handleRTOStatusChange = () => {
		const rto_status = rtoForm.getFieldValue("rto_status")
		const bharat_passing = rtoForm.getFieldValue("bharat_passing")
		if (rto_status === 0) {
			rtoForm.setFieldsValue({
				crtm: 1,
				amount: ManageZFormsStore.applyRTOValues.config.crtm,
				passing_type: 0,
				rto_amount: ManageZFormsStore.applyRTOValues.booking.rto_offer.rto_tax,
				bharat_passing: 0,
				rto_id: null
			})
			setHideCRTMSec(true)
			setCTDisabled(true)
			setHideBharatPassingDoc(false)
			setRtoPlaceId(null)
		}
			
		else {
			rtoForm.setFieldsValue({
				crtm: 0,
				amount: 0,
				passing_type: 0,
			})
			setHideCRTMSec(false)
			setCTDisabled(false)
			setHideBharatPassingDoc(true)
			ManageZFormsStore.getRTOList()
		}
		const pt_status = rtoForm.getFieldValue("passing_type")
		if(pt_status === 0){
			setHideBharatPassingSec(true);
		}
		else{
			setHideBharatPassingSec(true);
		}	
		if(rto_status === 1 && bharat_passing === 0){
			setHideBharatPassingDoc(false)
		}
		else{
			setHideBharatPassingDoc(true)
		}	
	}

	const handleRTOSelectChange = () => {
		const rto_id = rtoForm.getFieldValue("rto_id");
		setRtoPlaceId(rto_id)
		let rto_calc = false;
		if (rto_id && rto_calc === false) {
			calculateRTOCharges();
			rto_calc = true;
		}
	}

	const handleBharatPassingChange = () => {
		const bharat_passing = rtoForm.getFieldValue("bharat_passing")
		if (bharat_passing === 1) {
			setHideBharatPassingDoc(true)
		}
		else {
			setHideBharatPassingDoc(false)
		}
	}

	const handlePassingtypeChange = () => {		
		const passing_type = rtoForm.getFieldValue("passing_type");
		if (passing_type === 0) {
			setHideBharatPassingSec(true);
		}
		else {
			setHideBharatPassingDoc(false)
			setHideBharatPassingSec(false);
			rtoForm.setFieldsValue({ bharat_passing: 0 })
		}
	}

	const handleIsSpecialNumberChange = () => {		
		const is_special_number = rtoForm.getFieldValue("is_special_number");
		if (is_special_number === 0) {
			setIsSpecialNumber(false);
			rtoForm.setFieldsValue({ special_number: null })
		}
		else {
			setIsSpecialNumber(true);
		}
	}

	const eventProps = {
		onChange(info) {
			const { response } = info.file;
			if (info.file.status !== 'uploading') {
			}
			if (info.file.status === 'done') {
				ManageZFormsStore.applyRTOValues.booking.documents = response.documents
				setDocumentTitle("")
				vsmNotify.success({
					message: response.STATUS.NOTIFICATION[0],
				});
			} else if (info.file.status === 'error') {
				vsmNotify.error({
					message: response.STATUS.NOTIFICATION[0],
				});
			}
			if (ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Form_60)) {
				let formBkDocId = ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Form_60)
				setformDocID(formBkDocId)
			} else {
				setformDocID(0)
			}
			if (ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.ID_Proof_of_Customer)) {
				let formBkDocId1 = ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.ID_Proof_of_Customer)
				setProofoCustomer(formBkDocId1)
			}
			else {
				setProofoCustomer(0)
			}
			if (ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Declaration_Certificate)) {
				let formBkDocId2 = ManageZFormsStore.applyRTOValues.booking.documents.find((item) => item.ds_id === Bharat_Passing_doc_id.Declaration_Certificate)
				setdeclarationCertificate(formBkDocId2)
			}
			else {
				setdeclarationCertificate(0)
			}
		},
	};

	const handleBeforeUpload = (file) => {
		const isValid = validFileTypes.includes(file.type);
		if (!isValid) {
			// message.error('Please upload valid file');
			vsmNotify.error({
				message: "Please upload valid file",
			});
			return false;
		}
		let checkValue = documentTitle.replace(/(<([^>]+)>)/ig, '')
		checkValue = checkValue.trim()
		if (checkValue !== "") {
			return true
		}
		vsmNotify.error({
			message: "Document title is required",
		});
		return false
	}

	const handleEditBeforeUpload = (file) => {
		const isValid = validFileTypes.includes(file.type);
		if (!isValid) {
			// message.error('Please upload valid file');
			vsmNotify.error({
				message: "Please upload valid file",
			});
			return false;
		}
	}

	const handleViewDocument = (doc_id) => {
		ManageZFormsStore.getImageUrl(doc_id).then((data) => {
			onPreview(data)
		})
	}

	const onPreview = async (src) => {
		setTimeout(() => {
			const response = {
				file: src,
			};
			window.open(response.file);
		}, 100);
	};

	const handleDeleteDocument = (doc_id) => {
		const data = {}
		data.booking_id = ManageZFormsStore.applyRTOValues.booking.id
		data.id = doc_id
		ManageZFormsStore.DeleteRTODocument(data)
			.then((response) => {
				ManageZFormsStore.applyRTOValues.booking.documents = response.documents
				vsmNotify.success({
					message: response.STATUS.NOTIFICATION[0],
				});
			})
	}

	const handleRTOFormChange = debounce(() => {
		rtoForm
			.validateFields()
			.then((data) => {
				// setDisabled(false);
			})
			.catch((e) => {
				// setDisabled(true);
			});
	}, 500);

	const handlePerticularsChange = debounce(() => {
		pertForm
			.validateFields()
			.then((data) => {
				setpertDisabled(false);
			})
			.catch((e) => {
				setpertDisabled(true);
			});
	}, 500);

	const handlePertRTOStatusChange = () => {
		const rto_status = pertForm.getFieldValue("rto_status");
		const is_special_number = pertForm.getFieldValue("is_special_number"); 
		if (is_special_number === 1) {
			setIsSpecialNumber(true);
		} else {
			setIsSpecialNumber(false);
		}
		if (rto_status) {
			setisPendingStatus(false);
		}
		else {
			setisPendingStatus(true);
		}
	}

	const disabledDate = (current) => {
		return current && (
			current > moment().endOf("day") || current.isBefore(ManageZFormsStore?.applyRTOValues?.booking?.date)
		);
	};

	const financeText = () => {
		return ManageZFormsStore.viewValues && (
			ManageZFormsStore.viewValues.booking_ledger?.finance_offer?.need_finance === null ?
				<p className="redText">Finance is not decided yet</p>
				:
				ManageZFormsStore.viewValues.booking_ledger?.finance_offer?.need_finance === 0 ?
					<p className="redText">Customer doesn't need finance</p>
					:
					<p className="redText">Customer needs finance - {ManageZFormsStore.viewValues.booking_ledger?.finance_offer?.loan_source?.name}</p>
		)
	}

	// reset form and close add form
	const close = () => {
		props.close();
		rtoForm.resetFields();
		pertForm.resetFields();
		setRTOSaving(false)
		setrtoDisabled(false)
		setPertSaving(false)
		setPertDisabled(false)
		setHideCRTMSec(true)
		setCTDisabled(false)
		setDocumentTitle("")
		ManageZFormsStore.applyRTOValues = null
		setIsSpecialNumber(false)
		setRtoPlaceId(null)
	};
	return ManageZFormsStore.applyRTOValues ? (
		<Drawer
			className="addModal"
			title="RTO"
			width="70%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
			]}
		>
			<Form form={rtoForm} id="applyrtoForm" labelCol={{ span: 24 }} onFinish={handleRTOSubmit}>
				<div className="rtoBlock">
					<Row gutter={30}>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} xl={{ span: 8 }} >
							
							<InputComponent
								type="radio_button"
								name="rto_status"
								disabled={rtoDisabled}
								label="Need RTO?"
								onChange={handleRTOStatusChange}
								options={{
									values: [{ id: 1, name: "Yes" }, { id: 0, name: "No" }],
									value_key: "id",
									text_key: "name",
								}}
							/>
						</Col>
						{rtoForm.getFieldValue("rto_status") === 1 &&
							<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} xl={{ span: 8 }} >
								<InputComponent
									type="select"
									autoComplete="off"
									filterOption="false"
									allowClear
									disabled={rtoDisabled}
									label="Select RTO"
									onChange={handleRTOSelectChange}
									name="rto_id"
									placeholder="Select"
									options={{
										values: ManageZFormsStore.dropdown_rto_list,
										value_key: "id",
										text_key: "rto_label"
									}}
								/>
							</Col>
						}
					</Row>
				</div>
				{
					hideCRTMSec &&
					<div className="rtoBlock leftBorder">
						<h3 className="formTitle">No, Customer will manage</h3>
						<Row gutter={30} align="middle">
							<Col xs={{ span: 12 }}>
								<Form.Item
									label=""
									valuePropName="checked"
									name="crtm"
								>
									<Checkbox disabled={true}>
										CRTM
									</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={{ span: 12 }} className="sideLabel">
								<Form.Item label="Amount">
									<div className="currencyFormat_box text-right">
										{CurrencyFormat({ value: rtoForm.getFieldValue("amount"), })}
									</div>
								</Form.Item>
							</Col>
						</Row>
					</div>
				}
				{/* {rtoStatus === 1 && rtoId} */}
				{
					rtoForm.getFieldValue("rto_id") &&
					<>

						<div className="rtoBlock leftBorder">
							<Row gutter={30} align="middle" >
								<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} xl={{ span: 8 }} >
							<h3 className="formTitle">Is Choice Number Needed?</h3>
									<InputComponent
										type="radio_button"
										name="is_special_number"
										disabled={rtoDisabled || ctDisabled}
										onChange={handleIsSpecialNumberChange}
										options={{
											values: [{ id: 0, name: "No" }, { id: 1, name: "Yes" }],
											value_key: "id",
											text_key: "name",
										}}
									/>
								</Col>
								{isSpecialNumber && 
									<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} xl={{ span: 8 }} >
										<h3 className="formTitle">Special number</h3>
										<InputComponent
											type="text"
											disabled={false}
											placeholder="Special number"
											name="special_number"
											rules={vsmRTOOffer.validation.special_number}
										/>
									</Col>
								}
							</Row>
						</div>
						<div className="rtoBlock leftBorder">
							<h3 className="formTitle">Yes, Going for RTO Process</h3>
							<Row gutter={30} align="middle" >
								<Col xs={{ span: 12 }}>
									<InputComponent
										type="radio_button"
										name="passing_type"
										disabled={rtoDisabled || ctDisabled}
										onChange={() => { handlePassingtypeChange(); calculateRTOCharges();}}
										options={{
											values: [{ id: 0, name: "Individual" }, { id: 1, name: "Company" }],
											value_key: "id",
											text_key: "name",
										}}
									/>
								</Col>
								{!hideBharatPassingDoc && <Col xs={{ span: 12 }} className="topLabel tooltipText">
									<InputComponent
										name="rto_amount"
										className="currencyFormat_box text-right"
										disabled
										tooltip={RTOAmountInfo}
									/>
								</Col>}
							</Row>
						</div>
						{hideBharatPassingSec && <div className="rtoBlock leftBorder">
							<h3 className="formTitle">Need Bharat Passing?</h3>
							<Row gutter={30} align="middle" >
								<Col xs={{ span: 12 }} >
									<InputComponent
										type="radio_button"
										name="bharat_passing"
										disabled={bharatPassingDisabled}
										required
										defaultValue={0}
										onChange={() => {
											handleRTOFormChange();
											calculateRTOCharges();
											handleBharatPassingChange()
										}
										}
										options={{
											values: [{ id: 1, name: "Yes" }, { id: 0, name: "No" }],
											value_key: "id",
											text_key: "name",
										}}
									/>
								</Col>
								{hideBharatPassingDoc && <Col xs={{ span: 12 }} className="topLabel tooltipText">
									<InputComponent
										name="rto_amount"
										className="currencyFormat_box text-right"
										disabled
										tooltip={RTOBharatPassingInfo}
									/>
								</Col>}
							</Row>
						</div>}
						<div className="rtoBlock leftBorder">
							<h3 className="formTitle">Consider Hypo.Charge</h3>
							<Row gutter={30} align="middle" >
								<Col xs={{ span: 12 }} >
									<InputComponent
										type="radio_button"
										name="hypo_chrg_flag"
										required
										disabled={hypeDisabled}
										onChange={() => {
											handleRTOFormChange();
											calculateRTOCharges();
										}
										}
										options={{
											values: [{ id: 1, name: "Yes" }, { id: 0, name: "No" }],
											value_key: "id",
											text_key: "name",
										}}
									/>
								</Col>
							</Row>
							<Row gutter={30}>
								<Col xs={{ span: 12 }}>
									{financeText()}
								</Col>
							</Row>
						</div>
					</>
				}
				{/* {hideBharatPassingDoc && hideCTSec && <div className="rtoBlock leftBorder"> */}
				{ rtoForm.getFieldValue("bharat_passing") === 1 && <div className="rtoBlock leftBorder">
					<Row gutter={30}>
						<Col xs={{ span: 24 }}>
							<h3 className="formTitle">Upload Documents</h3>
						</Col>
						<Col xs={{ span: 24 }}>
							<table className="rtoDocuments">

								<tr key="1">
									<td className="name">
										Form 60
									</td>
									<td className="upload">

										<Upload
											data={{
												booking_id: ManageZFormsStore.applyRTOValues.booking.id,
												bk_doc_id: formDocID.id ? formDocID.id : 0,
												ds_id: Bharat_Passing_doc_id.Form_60,
												name: "Form 60"
											}}
											name="rto_document"
											action={Axios.defaults.baseURL + "sales/rto_offer/upload/" + ManageZFormsStore.applyRTOValues.booking.id}
											headers={Axios.defaults.headers}
											multiple={false}
											showUploadList={false}
											beforeUpload={handleEditBeforeUpload}
											{...eventProps}
											disabled={[default_roles.sales_consultant,default_roles.team_leader_sales,default_roles.admin].includes(AUTH?.user?.role_id) ? false : true }
										>
											<Button icon={<UploadIcon />}>Upload</Button>
										</Upload>
									</td>
									<td className="action">
										{

											formDocID.doc_id ?
												<>
													<Button
														type="text"
														title={"View"}
														className="viewIcon mr-10"
														size="large"
														style={{ padding: 7 }}
														onClick={() => { handleViewDocument(formDocID.doc_id) }}
													>
														<FontAwesomeIcon icon={faEye} />
													</Button>
													{(AUTH.checkPrivileges("#8355#") &&

														<Button
															type="text"
															title={"Deletes"}
															className="deleteIcon"
															size="large"
															style={{ padding: 7 }}
															onClick={() => { handleDeleteDocument(formDocID.id) }}
														>
															<FontAwesomeIcon icon={faTrashAlt} />
														</Button>
													)}
												</>
												:
												"N/A"
										}
									</td>
								</tr>
								<tr key="1">
									<td className="name">
										ID Proof of Customer
									</td>
									<td className="upload">
										<Upload
											data={{
												booking_id: ManageZFormsStore.applyRTOValues.booking.id,
												bk_doc_id: proofoCustomer ? proofoCustomer.id : 0,
												ds_id: Bharat_Passing_doc_id.ID_Proof_of_Customer,
												name: "ID Proof of Customer"
											}}
											name="rto_document"
											action={Axios.defaults.baseURL + "sales/rto_offer/upload/" + ManageZFormsStore.applyRTOValues.booking.id}
											headers={Axios.defaults.headers}
											multiple={false}
											showUploadList={false}
											beforeUpload={handleEditBeforeUpload}
											{...eventProps}
											disabled={[default_roles.sales_consultant,default_roles.team_leader_sales,default_roles.admin].includes(AUTH?.user?.role_id) ? false : true }
										>
											<Button icon={<UploadIcon />}>Upload</Button>
										</Upload>
									</td>
									<td className="action">
										{
											proofoCustomer.doc_id ?
												<>
													<Button
														type="text"
														title={"View"}
														className="viewIcon mr-10"
														size="large"
														style={{ padding: 7 }}
														onClick={() => { handleViewDocument(proofoCustomer.doc_id) }}
													>
														<FontAwesomeIcon icon={faEye} />
													</Button>
													{(AUTH.checkPrivileges("#8355#") &&

														<Button
															type="text"
															title={"Deletes"}
															className="deleteIcon"
															size="large"
															style={{ padding: 7 }}
															onClick={() => { handleDeleteDocument(proofoCustomer.id) }}
														>
															<FontAwesomeIcon icon={faTrashAlt} />
														</Button>
													)}
												</>
												:
												"N/A"
										}
									</td>
								</tr>
								<tr key="1">
									<td className="name">
										Declaration Certificate
									</td>
									<td className="upload">
										<Upload
											data={{
												booking_id: ManageZFormsStore.applyRTOValues.booking.id,
												bk_doc_id: declarationCertificate ? declarationCertificate.id : 0,
												ds_id: Bharat_Passing_doc_id.Declaration_Certificate,
												name: "Declaration Certificate"
											}}
											name="rto_document"
											action={Axios.defaults.baseURL + "sales/rto_offer/upload/" + ManageZFormsStore.applyRTOValues.booking.id}
											headers={Axios.defaults.headers}
											multiple={false}
											showUploadList={false}
											beforeUpload={handleEditBeforeUpload}
											{...eventProps}
											disabled={[default_roles.sales_consultant,default_roles.team_leader_sales,default_roles.admin].includes(AUTH?.user?.role_id) ? false : true }
										>
											<Button icon={<UploadIcon />}>Upload</Button>
										</Upload>
									</td>
									<td className="action">
										{
											declarationCertificate.doc_id ?
												<>
													<Button
														type="text"
														title={"View"}
														className="viewIcon mr-10"
														size="large"
														style={{ padding: 7 }}
														onClick={() => { handleViewDocument(declarationCertificate.doc_id) }}
													>
														<FontAwesomeIcon icon={faEye} />
													</Button>
													{(AUTH.checkPrivileges("#8355#") &&

														<Button
															type="text"
															title={"Deletes"}
															className="deleteIcon"
															size="large"
															style={{ padding: 7 }}
															onClick={() => { handleDeleteDocument(declarationCertificate.id) }}
														>
															<FontAwesomeIcon icon={faTrashAlt} />
														</Button>
													)}
												</>
												:
												"N/A"
										}
									</td>
								</tr>
							</table>
						</Col>
					</Row>
				</div>}
				<Row>
					<Col xs={{ span: 24 }} className="text-center">
						<Button
							form="applyrtoForm"
							loading={rtoSaving}
							disabled={rtoDisabled}
							htmlType="submit"
							type="primary"
						>
							Submit
						</Button>
					</Col>
				</Row>
			</Form>
			{
				rtoPlaceId &&
				<div className="rtoBlock leftBorder">
					<Row gutter={30}>
						<Col xs={{ span: 24 }}>
							<h3 className="formTitle">Upload Documents</h3>
						</Col>
						<Col xs={{ span: 24 }}>
							<table className="rtoDocuments">
								{
									ManageZFormsStore.applyRTOValues.booking.documents && ManageZFormsStore.applyRTOValues.booking.documents.filter(
										(item) => item?.ds_id !== Bharat_Passing_doc_id.Form_60 && item?.ds_id !== Bharat_Passing_doc_id.ID_Proof_of_Customer && item?.ds_id !== Bharat_Passing_doc_id.Declaration_Certificate
									).map(item => (
										<tr key={item.id}>
											<td className="name">
												{item.name}
											</td>
											<td className="upload">
												<Upload
													data={{ booking_id: ManageZFormsStore.applyRTOValues.booking.id, bk_doc_id: item.id, name: item.name }}
													name="rto_document"
													action={Axios.defaults.baseURL + "sales/rto_offer/upload/" + ManageZFormsStore.applyRTOValues.booking.id}
													headers={Axios.defaults.headers}
													multiple={false}
													showUploadList={false}
													beforeUpload={handleEditBeforeUpload}
													{...eventProps}
													disabled={rtoDocDisabled}
												>
													<Button icon={<UploadIcon />}>Upload</Button>
												</Upload>
											</td>
											<td className="action">
												{
													item.doc_id ?
														<>
															<Button
																type="text"
																title={"View"}
																className="viewIcon mr-10"
																size="large"
																style={{ padding: 7 }}
																onClick={() => { handleViewDocument(item.doc_id) }}
															>
																<FontAwesomeIcon icon={faEye} />
															</Button>
															{(AUTH.checkPrivileges("#8355#") &&

																<Button
																	type="text"
																	title={"Deletes"}
																	className="deleteIcon"
																	size="large"
																	style={{ padding: 7 }}
																	onClick={() => { handleDeleteDocument(item.id) }}
																>
																	<FontAwesomeIcon icon={faTrashAlt} />
																</Button>
															)}
														</>
														:
														"N/A"
												}
											</td>
										</tr>
									))
								}
								<tr>
									<td className="name">
										<input
											name="name"
											value={documentTitle}
											placeholder="Document Title"
											onChange={(e) => setDocumentTitle(e.target.value)}
										/>
									</td>
									<td className="upload">
										<Upload
											data={{ booking_id: ManageZFormsStore.applyRTOValues.booking.id, bk_doc_id: 0, name: documentTitle }}
											name="rto_document"
											action={Axios.defaults.baseURL + "sales/rto_offer/upload/" + ManageZFormsStore.applyRTOValues.booking.id}
											headers={Axios.defaults.headers}
											multiple={false}
											showUploadList={false}
											{...eventProps}
											beforeUpload={handleBeforeUpload}
											disabled={AUTH.checkPrivileges("#8355#") && (ManageZFormsStore.applyRTOValues.booking.status === 20 || ManageZFormsStore.applyRTOValues.booking.status === 22 || ManageZFormsStore.applyRTOValues.booking.status === 25 || ManageZFormsStore.applyRTOValues.booking.status === 30 || ManageZFormsStore.applyRTOValues.booking.status === 40) && [!default_roles.sales_consultant || !default_roles.team_leader_sales].includes(AUTH?.user?.role_id) ? false : true}
										>
											<Button icon={<UploadIcon />}>Upload</Button>
										</Upload>
									</td>
									<td className="action">

									</td>
								</tr>
							</table>
						</Col>
					</Row>
				</div>
			}
			{
				rtoPlaceId &&
				<div className="rtoBlock leftBorder">
					<h3 className="formTitle">Particulars</h3>
					<Form form={pertForm} id="applyParticularsForm" onChange={handlePerticularsChange} labelCol={{ span: 24 }} onFinish={handleParticularsSubmit}>
						<Row gutter={30}>
							<Col xs={{ span: 12 }}>
								<InputComponent
									type="text"
									required={!isPendingStatus}
									disabled={pertDisabled}
									label="Vehicle Reg. No."
									placeholder="Vehicle Reg. No."
									name="vehicle_reg_no"
									onChange={handlePerticularsChange}
									rules={!isPendingStatus ? vsmRTOOffer.validation.vehicle_reg_no : [{ required: false, message: "" }]}
								/>
							</Col>
							<Col xs={{ span: 12 }}>
								<InputComponent
									type="date"
									required={!isPendingStatus}
									disabled={pertDisabled}
									disabledDate={disabledDate}
									label="Vehicle Reg. Date"
									placeholder="Vehicle Reg. Date"
									name="vehicle_reg_date"
									format="DD/MM/YYYY"
									onChange={handlePerticularsChange}
									rules={!isPendingStatus ? vsmRTOOffer.validation.vehicle_reg_date : [{ required: false, message: "" }]}
								/>
							</Col>
							<Col xs={{ span: 12 }}>
								<InputComponent
									type="text"
									disabled={pertDisabled}
									label="Vehicle Temp No."
									placeholder="Vehicle Temp No."
									name="vehicle_temp_no"
									onChange={handlePerticularsChange}
									rules={vsmRTOOffer.validation.vehicle_temp_no}
								/>
							</Col>
							<Col xs={{ span: 12 }}>
								<InputComponent
									type="select"
									required
									disabled={pertDisabled}
									label="RTO Status"
									name="rto_status"
									placeholder="Select RTO Status"
									rules={vsmRTOOffer.validation.rto_status}
									onChange={() => {
										handlePerticularsChange();
										handlePertRTOStatusChange();
									}}
									options={{
										values: [{ id: 0, name: "Pending" }, { id: 1, name: "Completed" }],
										value_key: "id",
										text_key: "name",
									}}
								/>
							</Col>
						</Row>

						<Row>
							<Col xs={{ span: 24 }} className="text-center">
								<Button
									form="applyParticularsForm"
									loading={pertSaving}
									htmlType="submit"
									type="primary"
									disabled={pertDisabled || pertdisabled}
								>
									Submit
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			}
		</Drawer>
	) : null
});

export default ApplyRTOComponent;
