import React, { useState } from "react";
import { Form, Button, Row, Col, Drawer, Upload, Table, message } from "antd";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { UploadIcon } from "../../../../../config/IconsConfig";
import { vsmPackageEntry, vsmNotify } from "../../../../../config/messages";
import debounce from "lodash/debounce";


const ImportComponent = observer((props) => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState();
	const [importSaving, setimportSaving] = useState()
	const [disabled, setDisabled] = useState(true);
	const [fileList, updateFileList] = useState([]);
	// Handle submit and call function to save new record
	const {
		ManagePackageDefStore,
		ManagePackagesStore
	} = useStore();

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		const formData = new FormData();
		if (fileList.length > 0) {
			formData.append("package_sheet", fileList[0]?.originFileObj ? fileList[0]?.originFileObj : fileList[0]);
		}

		setSaving(true);
		ManagePackageDefStore.VerifyImport(formData)
			.then((data) => {
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	const handleImportProceed = () => {
		if (ManagePackageDefStore.dropdown_verify_list) {
			setimportSaving(true)
			const data = {
				package_sheet: ManagePackageDefStore.dropdown_verify_list.package_sheet,
				packages: ManagePackageDefStore.dropdown_verify_list.packages,
				package_id: ManagePackagesStore.viewValues?.id
			}
			ManagePackageDefStore.ProceedImport(data)
				.then((data) => {
					close();
					vsmNotify.success({
						message: data.STATUS.NOTIFICATION[0],
					});
				})
				.catch((e) => {
					if (e.errors) {
						form.setFields(e.errors);
					}
				})
				.finally(() => setimportSaving(false));
		}
	}

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = () => {
		form
			.validateFields()
			.then((data) => {
				setDisabled(false);
			})
			.catch((e) => {
				setDisabled(true);
			});
	};

	const uploadButton = (
		<div className="upload_btn">
			<UploadIcon />
			<p>Drag files to upload, or browse</p>
		</div>
	);

	const tableColumn = [
		{
			title: "Comment",
			key: "comment",
			width: 300,
			className: "text-left",
			render: (text, record) => (
				<>
					{
						record.comment.map((item, index) => {
							return (
								<p key={index} style={{ color: record.verified && record.verified === "0" ? "red" : "green" }}>
									<FontAwesomeIcon icon={record.verified && record.verified === "0" ? faExclamationTriangle : faCheckCircle} /><span>{record.verified && record.verified === "0" ? ("Error: " + item) : item}</span>
								</p>
							);
						})
					}
				</>
			),
		},
		{
			title: "Package",
			dataIndex: "package_name",
			key: "package_name",
		},
		{
			title: "Brand",
			dataIndex: "brand",
			key: "brand",
		},
		{
			title: "Model",
			dataIndex: "model",
			key: "model",
		},
		{
			title: "Variant",
			dataIndex: "variant",
			key: "variant",
		},
		{
			title: "Color Option?",
			key: "color_flag",
			render: (_, data) => data?.color_flag === 1 ? "Yes" : "Any"
		},
		{
			title: "Colors",
			key: "colors",
			render: (_, data) => data?.color_flag === 1 ? data.colors : "All"
		},
		{
			title: "Ex showroom",
			dataIndex: "ex_showroom",
			key: "ex_showroom",
		},
		{
			title: "CSD ex showroom",
			dataIndex: "csd_ex_showroom",
			key: "csd_ex_showroom",
		},
		{
			title: "Handling amt",
			dataIndex: "handling_amount",
			key: "handling_amount",
		},
		{
			title: "RTO amt (Individual)",
			dataIndex: "rto_amount",
			key: "rto_amount",
		},
		{
			title: "RTO amt (Company)",
			dataIndex: "rto_amount_comp",
			key: "rto_amount_comp",
		},
		{
			title: "PMS Amount",
			dataIndex: "pms_amount",
			key: "pms_amount",
		},
		{
			title: " Specific chassis's?",
			key: "variant",
			render: (_, data) => data?.chassis_flag === 1 ? "Yes" : "No"
		},
		{
			title: "Chassis No.",
			key: "chassis_nos",
			render: (_, data) => data?.chassis_flag === 1 ? data.chassis_nos : ""
		},
		{
			title: "Is Corpo. benefit?",
			key: "corporate_benefit_flag",
			render: (_, data) => data?.corporate_benefit_flag === 1 ? "Yes" : data?.corporate_benefit_flag === 0 ? "No" : "Any"
		},
		{
			title: "Corpo. Benefit",
			key: "corporate_benefit",
			render: (_, data) => data?.corporate_benefit_flag === 0 ? "" : data?.corporate_benefit
		},
		{
			title: "Effect on Z-Form?",
			key: "corporate_benefit_allowed",
			render: (_, data) => data?.corporate_benefit_flag === 0 ? "" : data?.corporate_benefit_allowed === 0 ? "No" : "Yes"
		},
		{
			title: "Is finance?",
			key: "fin_flag",
			render: (_, data) => data?.fin_flag === 1 ? "Yes" : data?.fin_flag === 0 ? "No" : "Any"
		},
		{
			title: "Finance Type",
			key: "fin_type",
			render: (_, data) => data?.fin_flag === 0 ? "" : data?.fin_type
		},
		{
			title: "Finance specific bank?",
			key: "fin_bank",
			render: (_, data) => data?.corporate_benefit_flag === 0 ? "" : data?.fin_bank
		},
		{
			title: "Applicable for Ins.?",
			key: "ins_flag",
			render: (_, data) => data?.ins_flag === 1 ? "Yes" : data?.ins_flag === 0 ? "No" : "Any"
		},
		{
			title: "Ins. Category?",
			key: "ins_catg",
			render: (_, data) => data?.ins_flag === 0 ? "" : data?.ins_catg
		},
		{
			title: "extend warranty?",
			key: "ew_flag",
			render: (_, data) => data?.ew_flag === 1 ? "Yes" : data?.ew_flag === 0 ? "No" : "Any"
		},
		{
			title: "Ex. warranty amt?",
			key: "ew_fix_amount",
			render: (_, data) => data?.ew_flag === 0 ? "" : data?.ew_fix_amount
		},
		{
			title: "Fixed accessories?",
			key: "accessory_flag",
			render: (_, data) => data?.accessory_flag === 1 ? "Yes" : data?.accessory_flag === 0 ? "No" : "Any"
		},
		{
			title: "Accessory",
			key: "accessory",
			render: (_, data) => data?.accessory_flag === 0 ? "" : data?.accessory
		},
		{
			title: "Accessory amount",
			key: "accessory_amount",
			render: (_, data) => data?.accessory_flag === 0 ? "" : data?.accessory_amount
		},
		{
			title: "Accessory max. disc. (%)",
			key: "accessory_disc",
			render: (_, data) => data?.accessory_flag === 0 ? "" : data?.accessory_disc
		},
		{
			title: "Offer scheme disc.?",
			key: "scheme_disc_flag",
			render: (_, data) => data?.scheme_disc_flag === 1 ? "Yes" : "No"
		},
		{
			title: "Pre. year disc.",
			key: "prev_year_disc",
			render: (_, data) => data?.scheme_disc_flag === 0 ? 0 : data?.prev_year_disc
		},
		{
			title: "Cur. year disc.",
			key: "cur_year_disc",
			render: (_, data) => data?.scheme_disc_flag === 0 ? 0 : data?.cur_year_disc
		},
		{
			title: "Level 1 disc. (TL)",
			key: "level1_disc",
			render: (_, data) => data?.scheme_disc_flag === 0 ? 0 : data?.level1_disc
		},
		{
			title: "Level 2 disc. (SM)",
			key: "level2_disc",
			render: (_, data) => data?.scheme_disc_flag === 0 ? 0 : data?.level2_disc
		},
		{
			title: "Level 3 disc. (VP)",
			key: "level3_disc",
			render: (_, data) => data?.scheme_disc_flag === 0 ? 0 : data?.level3_disc
		},
		{
			title: "Level 4 disc. (CEO)",
			key: "level4_disc",
			render: (_, data) => data?.scheme_disc_flag === 0 ? 0 : data?.level4_disc
		},
		{
			title: "Level 5 disc. (MD)",
			key: "level5_disc",
			render: (_, data) => data?.scheme_disc_flag === 0 ? 0 : data?.level5_disc
		},
		{
			title: "Is Active",
			dataIndex: "active",
			key: "active",
		},
		{
			title: "Is Used",
			dataIndex: "used",
			key: "used",
		},
	];

	const eventProps = {
		onChange: debounce(({ fileList: newFileList }) => {
			updateFileList(newFileList);
			handleChange()
		}, 500),
		fileList,
		beforeUpload: (file) => {
			let isType = file.type === "text/csv" || file.type === "application/vnd.ms-excel"
			if (!isType) {
				message.error(`Please select valid CSV file to import.`);
				return true;
			} else {
				updateFileList([file]);
				return false;
			}
		},
	};

	// Handle on remove image
	const onRemoveImage = () => {
		form.setFields([{ name: "package_sheet", errors: [] }]);
		updateFileList([]);
	};

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
		setDisabled(true);
		updateFileList([]);
		ManagePackageDefStore.dropdown_verify_list = null;
	};

	return (
		<Drawer
			className="addModal"
			title="Import"
			width="80%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
			]}
		>
			<div className="import_trans_main">
				<Form
					form={form}
					id="importTransactionForm"
					onFinish={handleSubmit}
					labelCol={{ span: 24 }}
					onChange={handleChange}
				>
					<div className="upload_verify_sec">
						<Row justify="space-between" align="middle" gutter={30}>
							<Col xs={{ span: 24 }} sm={{ span: 14 }}>
								<div className="upload_left_sec">
									<p>
										Hope you are ready with an Package List, Please upload file draft.
									</p>
									<Form.Item>
										<Button
											htmlType="submit"
											type="primary"
											loading={saving}
											disabled={disabled}
										>
											Upload & Verify
										</Button>
									</Form.Item>
								</div>
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 8 }}>
								<Form.Item name="package_sheet" rules={vsmPackageEntry.validation.package_sheet}>
									<Upload.Dragger
										accept=".csv"
										showUploadList={true}
										multiple={false}
										fileList={fileList}
										onRemove={onRemoveImage}
										name="package_sheet"
										rules={vsmPackageEntry.validation.package_sheet}
										{...eventProps}
									>
										{uploadButton}
									</Upload.Dragger>
								</Form.Item>
							</Col>
						</Row>
					</div>
					{
						ManagePackageDefStore.dropdown_verify_list &&
						<div className="import_table">
							<Table
								columns={tableColumn}
								dataSource={ManagePackageDefStore.dropdown_verify_list.packages}
								pagination="false"
								scroll={{ x: 3500, y: 300 }}
							/>
						</div>
					}
				</Form>
				{
					ManagePackageDefStore.dropdown_verify_list && ManagePackageDefStore.dropdown_verify_list.errors !== 0 &&
					<div className="import_total_sec">
						<div className="import_total_wrap">
							<Row gutter={30} justify="space-between" align="middle">
								<Col xs={{ span: 24 }} sm={{ span: 10 }}>
									<div className="import_total_text">
										<p>
											There are several entries with error, please resolve them and try again.
										</p>
									</div>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 14 }}>
									<div className="import_total_block">
										<Row gutter={20}>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_total">
													<div className="import_block_title">Total</div>
													<p>{ManagePackageDefStore.dropdown_verify_list.total_entries}</p>
												</div>
											</Col>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_verified">
													<div className="import_block_title">Verified OK</div>
													<p>{ManagePackageDefStore.dropdown_verify_list.verified_ok}</p>
												</div>
											</Col>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_error">
													<div className="import_block_title">Errors</div>
													<p>{ManagePackageDefStore.dropdown_verify_list.errors}</p>
												</div>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</div>
					</div>}
				{
					ManagePackageDefStore.dropdown_verify_list && ManagePackageDefStore.dropdown_verify_list.errors === 0 &&
					<div className="import_total_sec">
						<div className="import_total_wrap">
							<Row gutter={30} justify="space-between" align="middle">
								<Col xs={{ span: 24 }} sm={{ span: 14 }}>
									<div className="import_total_block">
										<Row gutter={20}>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_total">
													<div className="import_block_title">Total</div>
													<p>{ManagePackageDefStore.dropdown_verify_list.total_entries}</p>
												</div>
											</Col>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_verified">
													<div className="import_block_title">Verified OK</div>
													<p>{ManagePackageDefStore.dropdown_verify_list.verified_ok}</p>
												</div>
											</Col>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_error">
													<div className="import_block_title">Errors</div>
													<p>{ManagePackageDefStore.dropdown_verify_list.errors}</p>
												</div>
											</Col>
										</Row>
									</div>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 10 }}>
									<div className="import_total_text">
										<p>Verification is successfully completed, please import package list.</p>
										<Button htmlType="button" onClick={handleImportProceed} className="" loading={importSaving} >
											Proceed with Import
										</Button>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				}
			</div>
		</Drawer>
	);
});

export default ImportComponent;
