import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import useStore from "../store";
import { Empty, Skeleton } from "antd";
import { ZFormRefresh, ZFormCollapseDown, ZFormCollapseUp } from "../config/IconsConfig";
import NotificationWidgetStructure from "./NotificationWidgetStructure";
import { notification_redirect, notification_status } from "../utils/GlobalFunction";
// import moment from "moment";


const NotificationWidget = observer((props) => {
	const { NotificationStore } = useStore()
	const [isOpen, setIsOpen] = useState(true)
	const [loading, setLoading] = useState(false)


	useEffect(() => {
		if (!NotificationStore.list_data) {
			setLoading(true)

			NotificationStore.getList({}).then((data) => {
				setLoading(false);
			}).catch((e) => {
				setLoading(false)
			})
		}
	}, [NotificationStore])

	const handleReSync = () => {
		setLoading(true)
		NotificationStore.getList({}).then((data) => {
			setLoading(false);
		}).catch((e) => {
			setLoading(false)
		})
			.finally(() => setLoading(false));
	}


	return (
		<div className="dashboard_widget_block">
			<div className="widget_title">
				<h3>{props.title}</h3>
				<div className="refresh_toggle_icon">

					<ZFormRefresh className="mr-15" onClick={handleReSync} />
					{isOpen ? <ZFormCollapseDown onClick={() => setIsOpen(!isOpen)} /> : <ZFormCollapseUp onClick={() => setIsOpen(!isOpen)} />}
				</div>
			</div>
			<div style={{ maxHeight: isOpen ? "650px" : "0", overflowY: 'auto' }} className="widget_wrapper">
				{
					loading ?
						<Skeleton active />
						:
						NotificationStore.list_data ?
							<>
								{NotificationStore.list_data.map((obj) => {
									let createdDate = obj.created
									return (
										<ul className="widget_data notification_widget">
											<NotificationWidgetStructure
												title={notification_status[obj.type_id]}
												message={obj.message}
												className={"grayRow"}
												redirectLink={notification_redirect[obj.type_id]}
												filterValue={"Quote In progress"}
												createdDate={createdDate}
											/>
										</ul>
									)
								})
								}

							</>
							: <Empty />
				}
			</div>
		</div>
	)
})

export default NotificationWidget
