import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { vsmCommon } from "../../../../config/messages";
import { Button, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { booking_status, DateComparator, finance_irr_status, finance_status_Arr, insurance_status, insurance_status_Arr } from "../../../../utils/GlobalFunction";
import LocalGridConfig from "../../../../config/LocalGridConfig";

const ActionRenderer = observer((props) => {
	// const { ManageZFormsStore, AUTH } = useStore();
	const { AUTH } = useStore();
	const {
		openDRFModal } = props.agGridReact.props.frameworkComponents

	return (
		<div className="action-column">
			{AUTH.checkPrivileges("#8501#") && (
				<Button
					type="text"
					title={"DRF"}
					className="viewIcon mr-10"
					size="large"
					style={{ padding: 7 }}
					onClick={() => {
						openDRFModal(props.data, "openHistory");
					}}
				>
					<FontAwesomeIcon icon={faEye} />
				</Button>
			)}
		</div>
	);

	
});
const HistoryListComponent = observer((props) => {
	const {
		openDRFModal,
	} = props;
	const {
		ManageDRFStore: { location_wise_user_data, setupLocationUserGrid,getListBasedOnLocation, onDRFHistoryFilterChanged } } = useStore();
		
	useEffect(() => {
		getListBasedOnLocation();
	}, [getListBasedOnLocation]);

	const gridOptions = {
		columnDefs: [
			{
				headerName: "Z-Form",
				field: "id",
				filter: "agNumberColumnFilter",
				pinned: "left",
				minWidth: 120,
				width: 120,
			},
			{
				headerName: "CO NO",
				field: "co_no",
			},
			{
				headerName: "Date",
				field: "date",
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator,
					browserDatePicker: true
				},
			},
			{
				headerName: "Date Completed",
				field: "date_completed",
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
				},
			},
			{
				headerName: "Customer Name",
				field: "booking_customer.full_name",
			},
			{
				headerName: "Phone",
				field: "booking_customer.phone",
				initialHide: true
			},			
			{
				headerName: "Model",
				field: "booking_model.model.name",
			},
			{
				headerName: "Variant",
				field: "booking_model.variant.name",
			},
			{
				headerName: "Color",
				field: "booking_model.color.name",
			},
			{
				headerName: "Chassis Number",
				field: "booking_model.chassis_no",
				valueGetter: (params) => (params.data && params.data.booking_model?.chassis_no) ? params.data.booking_model?.chassis_no : "N/A",
			},
			{
				headerName: "Customer Type",
				field: "booking_customer.customer_type.name",
			},
			{
				headerName: "Promising Delivery",
				field: "booking_model.promised_delivery_date",
				cellRendererFramework: function (params) {
					return (
						<Tooltip title={params.data?.booking_model?.promised_delivery_date === "N/A" ? "Promised Delivery Date is not specified so cannot do chassis allocation" : ""}>
							<p>{params.data?.booking_model ? params.data?.booking_model?.promised_delivery_date : ""}</p>
						</Tooltip>
					)
				},
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator,
					browserDatePicker: true
				},
			},
			{
				headerName: "RTO",
				field: "rto_status",
				filter: "agSetColumnFilter",
				filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						params.success([null, "Pending", "Completed"])
					}
				}
			},
			{
				headerName: "Exchange",
				field: "exchange_status",
				filter: "agSetColumnFilter",
				filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						params.success([null, "Pending", "Completed"])
					}
				}
			},
			{
				headerName: "Resale",
				field: "resale_status",
				filter: "agSetColumnFilter",
				filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						params.success([null, "Pending", "Completed"])
					}
				}
			},
			{
				headerName: "Finance",
				field: "finance_status",
				filter: "agSetColumnFilter",
				filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						params.success(["N/A", ...finance_status_Arr])
					}
				},
				cellRendererFramework: function (params) {
					return (params.data.finance_status ? finance_irr_status[params.data.finance_status] : "N/A")
				},
			},
			{
				headerName: "Insurance",
				field: "insurance_offer.status",
				filter: "agSetColumnFilter",
				filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						params.success(["N/A", ...insurance_status_Arr])
					}
				},
				cellRendererFramework: function (params) {
					return ((params.data.insurance_offer && params.data.insurance_offer?.status) ? insurance_status[params.data.insurance_offer.status] : "N/A")
				},
			},
			{
				headerName: "Payment Received",
				field: "booking_ledger.total_credits",
				filter: "agNumberColumnFilter",
			},
			{
				headerName: "Consultant",
				field: "sales_consultant.name",
			},
			{
				headerName: "Reporting To",
				field: "sales_consultant.reporting_to.name",
			},
			{
				headerName: "Location",
				field: "location.name",
			},			
			{
				headerName: "Status",
				field: "status",
				filter: "agSetColumnFilter",
				pinned: "right",
				minWidth: 120,
				width: 120,
				valueGetter: (params) => (params.data && params.data.status) ? booking_status[params.data.status] : "N/A",
				filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						params.success([booking_status[10], booking_status[20], booking_status[40]])
					}
				}
			},			
			{
				headerName: "Actions",
				field: "actions",
				type: "actionColumn",
				filter: false,
				sortable: false,
				pinned: "right",
				minWidth: 180,
				width: 180,
			},
		],
	};
	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact			

				rowHeight={LocalGridConfig.rowHeight}
				headerHeight={LocalGridConfig.headerHeight}
				rowData={location_wise_user_data}
				modules={AllModules}
				columnDefs={gridOptions.columnDefs}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer,
					openDRFModal,
				
				}}
				onGridReady={setupLocationUserGrid}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={onDRFHistoryFilterChanged}
				onSortChanged={onDRFHistoryFilterChanged}
			/>
		</div>
	);
});

export default HistoryListComponent;
