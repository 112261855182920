import { Button, PageHeader } from "antd";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import { useState } from "react";
import PendingListComponent from "./component/PendingListComponent";
import HistoryListComponent from "./component/HistoryListComponent";
import ApproveComponent from "./component/ApproveComponent";
import RejectComponent from "./component/RejectComponent";

const MunicipalRequest = observer((props) => {
	const [approveModal, setApproveModal] = useState(false);
	const [rejectModal, setRejectModal] = useState(false);
	const [viewType, setViewType] = useState(true);
	const {
		MunicipalReqPendingStore: {
			setPageSize,
			per_page,
			setApproveRejectValues
		},
		AUTH
	} = useStore();

	const handleChange = () => {
		setViewType(!viewType)
	};

	// approve & reject  form for muni request
	const openApproveModal = (data) => {
		setApproveRejectValues(data)
		setApproveModal(true)
	};
	const closeApproveModal = () => setApproveModal(false);

	const openRejectModal = (data) => {
		setApproveRejectValues(data)
		setRejectModal(true)
	};
	const closeRejectModal = () => setRejectModal(false);

	return (
		<PageHeader
			title={BreadcrumbConfig.MunicipalRequest.title + (viewType ? " - Pending" : " - History")}
			className="tableAreaSec"
			extra={
				<BreadcrumbComponent items={BreadcrumbConfig.MunicipalRequest.path} />
			}
		>
			<div className="listCountNew">
				{AUTH.checkPrivileges("#8601#") && (
					<Button key="1"
						onClick={handleChange}
					>
						{viewType ? "History" : "Pending"}
					</Button>
				)}
				<RecordPerPage
					key="2"
					style={{ width: "150px" }}
					defaultValue={per_page + " per page"}
					onChange={setPageSize}
				/>
			</div>

			<ApproveComponent visible={approveModal} close={closeApproveModal} />
			<RejectComponent visible={rejectModal} close={closeRejectModal} />

			{viewType && (viewType === true) ?
				<PendingListComponent
					openApproveModal={openApproveModal}
					openRejectModal={openRejectModal}
				/>
				:
				<HistoryListComponent />
			}
		</PageHeader>
	);
});

export default MunicipalRequest;
