import React, { useState } from "react";
import { Form, Button, Row, Col, Drawer, Upload, Table, message } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { UploadIcon } from "../../../../config/IconsConfig";
import { vsmScheme, vsmNotify } from "../../../../config/messages";
import debounce from "lodash/debounce";


const ImportComponent = observer((props) => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState();
	const [importSaving, setimportSaving] = useState()
	const [disabled, setDisabled] = useState(true);
	const [fileList, updateFileList] = useState([]);
	// Handle submit and call function to save new record
	const {
		ManageSchemeStore
	} = useStore();

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		const formData = new FormData();
		if (fileList.length > 0) {
			formData.append("scheme_sheet", fileList[0]?.originFileObj ? fileList[0]?.originFileObj : fileList[0]);
		}

		setSaving(true);
		ManageSchemeStore.VerifyImport(formData)
			.then((data) => {
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	const handleImportProceed = () => {
		if (ManageSchemeStore.dropdown_varify_list) {
			setimportSaving(true)
			const data = {
				scheme_sheet: ManageSchemeStore.dropdown_varify_list.scheme_sheet,
				schemes: ManageSchemeStore.dropdown_varify_list.schemes
			}
			ManageSchemeStore.ProceedImport(data)
				.then((data) => {
					close();
					vsmNotify.success({
						message: data.STATUS.NOTIFICATION[0],
					});
				})
				.catch((e) => {
					if (e.errors) {
						form.setFields(e.errors);
					}
				})
				.finally(() => setimportSaving(false));
		}
	}

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = () => {
		form
			.validateFields()
			.then((data) => {
				setDisabled(false);
			})
			.catch((e) => {
				setDisabled(true);
			});
	};

	const uploadButton = (
		<div className="upload_btn">
			<UploadIcon />
			<p>Drag files to upload, or browse</p>
		</div>
	);

	const tableColumn = [
		{
			title: "Comment",
			key: "comment",
			width: 300,
			className: "text-left",
			render: (text, record) => (
				<>
					{
						record.comment.map((item, index) => {
							return (
								<p key={index} style={{ color: record.verified && record.verified === "0" ? "red" : "green" }}>
									<FontAwesomeIcon icon={record.verified && record.verified === "0" ? faExclamationTriangle : faCheckCircle} /><span>{record.verified && record.verified === "0" ? ("Error: " + item) : item}</span>
								</p>
							);
						})
					}
				</>
			),
		},
		{
			title: "Start Date",
			dataIndex: "from_date",
			key: "from_date",
		},
		{
			title: "End Date",
			dataIndex: "to_date",
			key: "to_date",
		},
		{
			title: "Booking Apply In Date Range",
			dataIndex: "range",
			key: "range",
		},
		{
			title: "Brand",
			dataIndex: "brand",
			key: "brand",
		},
		{
			title: "Model",
			dataIndex: "model",
			key: "model",
		},
		{
			title: "Variant",
			dataIndex: "variant",
			key: "variant",
		},
		{
			title: "Prev. Year Discount",
			dataIndex: "prev_year_disc",
			key: "prev_year_disc",
		},
		{
			title: "Cur. Year Discount",
			dataIndex: "cur_year_disc",
			key: "cur_year_disc",
		},
		{
			title: "Level 1 Discount (TL)",
			dataIndex: "level1_discount",
			key: "level1_discount",
		},
		{
			title: "Level 2 Discount (SM)",
			dataIndex: "level2_discount",
			key: "level2_discount",
		},
		{
			title: "Level 3 Discount (VP)",
			dataIndex: "level3_discount",
			key: "level3_discount",
		},
		{
			title: "Level 4 Discount (CEO)",
			dataIndex: "level4_discount",
			key: "level4_discount",
		},
		{
			title: "Level 5 Discount (MD)",
			dataIndex: "level5_discount",
			key: "level5_discount",
		},
		{
			title: "Is Active",
			dataIndex: "active",
			key: "active",
		},
		{
			title: "Is Used",
			dataIndex: "used",
			key: "used",
		},
	];

	const eventProps = {
		onChange: debounce(({ fileList: newFileList }) => {
			updateFileList(newFileList);
			handleChange()
		}, 500),
		fileList,
		beforeUpload: (file) => {
			let isType = file.type === "text/csv" || file.type === "application/vnd.ms-excel"
			if (!isType) {
				message.error(`Please select valid CSV file to import.`);
				return true;
			} else {
				updateFileList([file]);
				return false;
			}
		},
	};

	// Handle on remove image
	const onRemoveImage = () => {
		form.setFields([{ name: "scheme_sheet", errors: [] }]);
		updateFileList([]);
	};

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
		setDisabled(true);
		updateFileList([]);
		ManageSchemeStore.dropdown_varify_list = null;
	};

	return (
		<Drawer
			className="addModal"
			title="Import"
			width="80%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
			]}
		>
			<div className="import_trans_main">
				<Form
					form={form}
					id="importTransactionForm"
					onFinish={handleSubmit}
					labelCol={{ span: 24 }}
					onChange={handleChange}
				>
					<div className="upload_verify_sec">
						<Row justify="space-between" align="middle" gutter={30}>
							<Col xs={{ span: 24 }} sm={{ span: 14 }}>
								<div className="upload_left_sec">
									<p>
										Hope you are ready with an Scheme List, Please upload file draft.
									</p>
									<Form.Item>
										<Button
											htmlType="submit"
											type="primary"
											loading={saving}
											disabled={disabled}
										>
											Upload & Verify
										</Button>
									</Form.Item>
								</div>
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 8 }}>
								<Form.Item name="scheme_sheet" rules={vsmScheme.validation.scheme_sheet}>
									<Upload.Dragger
										accept=".csv"
										showUploadList={true}
										multiple={false}
										fileList={fileList}
										onRemove={onRemoveImage}
										name="scheme_sheet"
										rules={vsmScheme.validation.scheme_sheet}
										{...eventProps}
									>
										{uploadButton}
									</Upload.Dragger>
								</Form.Item>
							</Col>
						</Row>
					</div>
					{
						ManageSchemeStore.dropdown_varify_list &&
						<div className="import_table">
							<Table
								columns={tableColumn}
								dataSource={ManageSchemeStore.dropdown_varify_list.schemes}
								pagination="false"
								scroll={{ x: 3500, y: 300 }}
							/>
						</div>
					}
				</Form>
				{
					ManageSchemeStore.dropdown_varify_list && ManageSchemeStore.dropdown_varify_list.errors !== 0 &&
					<div className="import_total_sec">
						<div className="import_total_wrap">
							<Row gutter={30} justify="space-between" align="middle">
								<Col xs={{ span: 24 }} sm={{ span: 10 }}>
									<div className="import_total_text">
										<p>
											There are several entries with error, please resolve them and try again.
										</p>
									</div>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 14 }}>
									<div className="import_total_block">
										<Row gutter={20}>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_total">
													<div className="import_block_title">Total</div>
													<p>{ManageSchemeStore.dropdown_varify_list.total_entries}</p>
												</div>
											</Col>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_verified">
													<div className="import_block_title">Verified OK</div>
													<p>{ManageSchemeStore.dropdown_varify_list.verified_ok}</p>
												</div>
											</Col>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_error">
													<div className="import_block_title">Errors</div>
													<p>{ManageSchemeStore.dropdown_varify_list.errors}</p>
												</div>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</div>
					</div>}
				{
					ManageSchemeStore.dropdown_varify_list && ManageSchemeStore.dropdown_varify_list.errors === 0 &&
					<div className="import_total_sec">
						<div className="import_total_wrap">
							<Row gutter={30} justify="space-between" align="middle">
								<Col xs={{ span: 24 }} sm={{ span: 14 }}>
									<div className="import_total_block">
										<Row gutter={20}>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_total">
													<div className="import_block_title">Total</div>
													<p>{ManageSchemeStore.dropdown_varify_list.total_entries}</p>
												</div>
											</Col>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_verified">
													<div className="import_block_title">Verified OK</div>
													<p>{ManageSchemeStore.dropdown_varify_list.verified_ok}</p>
												</div>
											</Col>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_error">
													<div className="import_block_title">Errors</div>
													<p>{ManageSchemeStore.dropdown_varify_list.errors}</p>
												</div>
											</Col>
										</Row>
									</div>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 10 }}>
									<div className="import_total_text">
										<p>Verification is successfully completed, please import scheme list.</p>
										<Button htmlType="button" onClick={handleImportProceed} className="" loading={importSaving} >
											Proceed with Import
										</Button>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				}
			</div>
		</Drawer>
	);
});

export default ImportComponent;
