import React, { useState } from "react";
import { Form, Button, Row, Col, Drawer, Upload, Table,  message,} from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { DownloadOutlined } from "@ant-design/icons";
import InputComponent from "../../../../component/InputComponent";
import { UploadIcon } from "../../../../config/IconsConfig";
import { vsmImportTransaction, vsmInTransit, vsmNotify } from "../../../../config/messages";
import debounce from "lodash/debounce";
import { VehicleType} from "../../../../utils/GlobalFunction";


const ImportComponent = observer((props) => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState();
	const [importSaving, setimportSaving] = useState()
	const [disabled, setDisabled] = useState(true);
	const [disabledIn, setDisabledIn] = useState(true);
	const [fileList, updateFileList] = useState([]);
	// Handle submit and call function to save new record
	const {
		InStockStore,
		AUTH
	} = useStore();

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		const vtid = form.getFieldValue("vt_id")
		if (InStockStore.dropdown_verify_chassis_list) {
			setimportSaving(true)
			const data = {
				vt_id: vtid,
				chassis_sheet: InStockStore.dropdown_verify_chassis_list?.chassis_sheet,
				stock: InStockStore.dropdown_verify_chassis_list?.stock
			}
			InStockStore.ProceedImport(data)
				.then((data) => {
					close();
					vsmNotify.success({
						message: data.STATUS.NOTIFICATION[0],
					});
				})
				.catch((e) => {
					if (e.errors) {
						form.setFields(e.errors);
					}
				})
				.finally(() => setimportSaving(false));
		}
	};

	const handleImportProceed = (data) => {
		const formData = new FormData();
		if (fileList.length > 0) {
			formData.append("chassis_sheet", fileList[0]?.originFileObj ? fileList[0]?.originFileObj : fileList[0]);
		}

		setSaving(true);
		InStockStore.VerifyChassisImport(formData)
			.then((data) => {
				// close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));

	}

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = () => {
		form
			.validateFields()
			.then((data) => {
				setDisabled(false);
			})
			.catch((e) => {
				setDisabled(true);
			});
	};
	const handleChangeIn  = () => {
		form
			.validateFields()
			.then((data) => {
				if(form.getFieldValue("vt_id")){
					setDisabledIn(false)
				}
			})
			.catch((e) => {
				setDisabledIn(true)
			});
	};
	const uploadButton = (
		<div className="upload_btn">
			<UploadIcon />
			<p>Drag files to upload, or browse</p>
		</div>
	);

	const tableColumn = [
		{
			title: "Comment",
			key: "comment",
			width: 300,
			className: "text-left",
			render: (text, record) => (
				<>
					{
						record.comment.map((item, index) => {
							return (
								<p key={index} style={{ color: record.verified && record.verified === "0" ? "red" : "green" }}>
									<FontAwesomeIcon icon={record.verified && record.verified === "0" ? faExclamationTriangle : faCheckCircle} /><span>{"Error: " + item}</span>
								</p>
							);
						})
					}
				</>
			),
		},
		{
			title: "Chassis Number",
			dataIndex: "chassis_no",
			key: "chassis_no",
		},
		{
			title: "Vehicle Type",
			dataIndex: "vt_name",
			key: "vt_name",
		},
	];

	const eventProps = {
		onChange: debounce(({ fileList: newFileList }) => {
			updateFileList(newFileList);
			handleChange()
		}, 500),
		fileList,
		beforeUpload: (file) => {
			let isType = file.type === "text/csv" || file.type === "application/vnd.ms-excel"
			if (!isType) {
				message.error(`Please select valid CSV file to import.`);
				return true;
			} else {
				updateFileList([file]);
				return false;
			}
		},
	};

	// Handle on remove image
	const onRemoveImage = () => {
		form.setFields([{ name: "chassis_sheet", errors: [] }]);
		updateFileList([]);
	};

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
		setDisabled(true);
		setDisabledIn(true)
		updateFileList([]);
		InStockStore.dropdown_verify_chassis_list = null;
		InStockStore.dropdown_supplier_list = null;
	};

	return (
		<Drawer
			className="addModal"
			title="Change Vehicle Type"
			width="80%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					disabled={disabledIn}
					form="importVehicleTypeForm"
					loading={importSaving}
					htmlType="submit"
					type="primary"
				>
					Continue
				</Button>
			]}
		>
			<div className="import_trans_main">
				<Form
					form={form}
					id="importVehicleTypeForm"
					onFinish={handleSubmit}
					//labelCol={{ span: 24 }}
				>
					<div className="download_sample_sec">
						<p>
							Please download a sample file format, this will help you to create a new draft considering what information is required, what should be the sequence and formatting a value if necessary. This way we can minimize error while importing a Chassis listing.
						</p>
						<a className="ant-btn" shape="round" type="link" href={AUTH.company && AUTH.company.branding.stock_chassis_import_sheet_id}>
							<span class="anticon anticon-download"><DownloadOutlined /></span>
							<span>Download Sample</span>
						</a>
					</div>
					<div className="upload_verify_sec">
						<Row justify="space-between" align="middle" gutter={30}>
							<Col xs={{ span: 24 }} sm={{ span: 14 }}>
								<div className="upload_left_sec">
									<p>
										Hope you are ready with an Chassis List, Please upload file draft
									</p>
									<Form.Item>
										<Button
											htmlType="button"
											//type="primary"
											loading={saving}
											disabled={disabled}
											onClick={handleImportProceed}
										>
											Upload & Verify
										</Button>
									</Form.Item>
								</div>
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 8 }}>
								<Form.Item name="chassis_sheet" rules={vsmImportTransaction.validation.chassis_sheet}>
									<Upload.Dragger
										accept=".csv"
										showUploadList={true}
										multiple={false}
										fileList={fileList}
										onRemove={onRemoveImage}
										name="chassis_sheet"
										rules={vsmImportTransaction.validation.chassis_sheet}
										{...eventProps}
									>
										{uploadButton}
									</Upload.Dragger>
								</Form.Item>
							</Col>
						</Row>
					</div>
					{
						InStockStore.dropdown_verify_chassis_list &&
						<div className="import_table">
							<Table
								columns={tableColumn}
								dataSource={InStockStore.dropdown_verify_chassis_list?.stock}
								pagination="false"
							//scroll={{ x: 2500, y: 300 }}
							/>
						</div>
					}

					{InStockStore.dropdown_verify_chassis_list &&
						<div className="import_total_sec">
							<div className="import_total_wrap">
								<Row gutter={30} justify="space-between" align="middle">
									<Col xs={{ span: 24 }} sm={{ span: 10 }}>
										<div className="import_total_text">
											<p>
												There are several entries with not matched, please make sure again.
											</p>
										</div>
									</Col>
									<Col xs={{ span: 24 }} sm={{ span: 14 }}>
										<div className="import_total_block">
											<Row gutter={20}>
												<Col xs={{ span: 24 }} sm={{ span: 8 }}>
													<div className="import_block import_total">
														<div className="import_block_title">Total</div>
														<p>{InStockStore.dropdown_verify_chassis_list?.total_entries}</p>
													</div>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 8 }}>
													<div className="import_block import_verified">
														<div className="import_block_title">Verified OK</div>
														<p>{InStockStore.dropdown_verify_chassis_list?.verified_ok}</p>
													</div>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 8 }}>
													<div className="import_block import_error">
														<div className="import_block_title">Errors</div>
														<p>{InStockStore.dropdown_verify_chassis_list?.errors}</p>
													</div>
												</Col>
											</Row>

										</div>
									</Col>

								</Row>
								<Row gutter={30} justify="space-between" align="middle" className="mt-35">
									<Col className="custom-radio-center">
										<InputComponent
											type="radio_button"
											required
											label="Convert all chassis"
											name="vt_id"
											rules={vsmInTransit.validation.vt_id}
											onChange={handleChangeIn}
											options={{
												values: VehicleType,
												value_key: "id",
												text_key: "name",
											}}
										/>
									</Col>
								</Row>
							</div>
						</div>}
				</Form>
				{/* {
					InStockStore.dropdown_verify_chassis_list? && InStockStore.dropdown_verify_chassis_list?.errors !== 0 &&
					<div className="import_total_sec">
						<div className="import_total_wrap">
							<Row gutter={30} justify="center" align="middle">
								<Col xs={{ span: 24 }} sm={{ span: 10 }}>
									<div className="import_total_text">
										<p>
											There are several entries with error, please resolve them and
											try again.
										</p>
									</div>
								</Col>
								<Col xs={{ span: 24 }} sm={{ span: 14 }}>
									<div className="import_total_block">
										<Row gutter={20}>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_total">
													<div className="import_block_title">Total</div>
													<p>{InStockStore.dropdown_verify_chassis_list?.total_entries}</p>
												</div>
											</Col>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_verified">
													<div className="import_block_title">Verified OK</div>
													<p>{InStockStore.dropdown_verify_chassis_list?.verified_ok}</p>
												</div>
											</Col>
											<Col xs={{ span: 24 }} sm={{ span: 8 }}>
												<div className="import_block import_error">
													<div className="import_block_title">Errors</div>
													<p>{InStockStore.dropdown_verify_chassis_list?.errors}</p>
												</div>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
							
						</div>
					</div>
				} */}

			</div>
		</Drawer>
	);
});

export default ImportComponent;
