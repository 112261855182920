import React, { useState } from "react";
import { Form, Button, Modal, Col, Row } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react";
// import { vsmNotify } from "../../../../config/messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ActivateComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageInsuranceSegmentStore: { deleteValues },
	} = useStore();
	const [saving, setSaving] = useState();
	const { handlePublish } = props;

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true);
		handlePublish(deleteValues);
		close();
		setSaving(false);
	};

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
	};

	return deleteValues ? (
		<Modal
			centered
			className="addModal"
			title={`${deleteValues?.status === 1 ? "Deactivate" : "Activate"} Segment?`}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					form="activateSegmentInsuranceModal"
					loading={saving}
					htmlType="submit"
					type="primary"
					danger
				>
					{deleteValues?.status === 1 ? "Deactivate" : "Activate"}
				</Button>,
			]}
		>
			<Form form={form} id="activateSegmentInsuranceModal" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={24} className="text-center">
							<h3>
								Would you like to {deleteValues?.status === 1 ? "deactivate" : "activate"} &nbsp;
								<span>
									{deleteValues?.segment ? deleteValues?.segment?.name : ""}&nbsp;
								</span>
								for
								<span>
									&nbsp;{deleteValues?.insurance_company ? deleteValues?.insurance_company?.name : ""}
								</span> ?
							</h3>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null;
});

export default ActivateComponent;
