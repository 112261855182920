import React, { useState, useEffect } from "react";
import { Form, Row, Col, Divider, Spin } from "antd";
import { vsmInsuranceProducts } from "../../../../config/messages";
import { observer } from "mobx-react";
import InputComponent from "../../../../component/InputComponent";
import useStore from "../../../../store";
import { addonInsurance, modelWiseProduct, productBasedOn, tpInsurance, validateModelBased } from "../../../../utils/GlobalFunction";

const InsuranceProductFormComponent = observer((props) => {
	const { isView = false, editFields, handleChange, initialValues = {}, catID = null } = props;
	const [fetchBrand, setFetchBrand] = useState(true);
	const [fetchPassingType, setFetchPassingType] = useState(true);
	const [fetchSegment, setFetchSegment] = useState(true);
	// const [fetchZone, setFetchZone] = useState(true);
	const [fetchCompany, setFetchCompany] = useState(true);
	const [fetchCategory, setFetchCategory] = useState(true);

	const {
		InsuranceProductStore,
		InsuranceProductStore: {
			getBrandList,
			getPassingTypeList,
			getInsCompanyList,
			getInsCategoryList,
		}
	} = useStore();

	useEffect(() => {
		InsuranceProductStore.getBrandList();
		const brand_id = props.form.getFieldValue("brand_id");
		if (brand_id && brand_id !== undefined) {
			const data = { brand_id };
			InsuranceProductStore.getModelListByBrand(data);
		}
		InsuranceProductStore.getPassingTypeList();
		InsuranceProductStore.getSegmentsList();
		// InsuranceProductStore.getInsuranceSegmentList();
		InsuranceProductStore.getZonesList();
		InsuranceProductStore.getInsCompanyList();
		InsuranceProductStore.getInsCategoryList();
	}, [InsuranceProductStore, props.form])


	const handleBrandChange = () => {
		const brand_id = props.form.getFieldValue("brand_id");
		props.form.setFieldsValue({ model_id: null })
		if (brand_id && brand_id !== undefined) {
			const data = { brand_id };
			InsuranceProductStore.getModelListByBrand(data);
		}
	};

	const handleModalChange = (value) => {
		let segmentObj = InsuranceProductStore.dropdown_model_list.filter(x => x.id === value)[0];
		props.form.setFieldsValue({
			segment_name: segmentObj.segment.name,
			segment_id: segmentObj.segment_id,
		})
	}

	const handleCompanyChange = () => {
		if ([20].includes(props?.form?.getFieldValue("product_base"))) {

			let company_id = props.form.getFieldValue("company_id");
			props.form.setFieldsValue({ model_id: null, segment_id: null })
			if (company_id && company_id !== undefined) {
				const data = { company_id };
				InsuranceProductStore.getInsuranceSegmentList(data).then(() => {
					setFetchSegment(false)
				});
			}
		}
	}

	const handleCategoryChange = () => {
		if (!addonInsurance.includes(props?.form?.getFieldValue("cat_id"))) {
			props.form.setFieldsValue({
				product_base: 10
			})
		}
	}

	const handleProductBaseChange = () => {
		props.form.setFieldsValue({
			segment_id: null
		})
		if ([20].includes(props?.form?.getFieldValue("product_base"))) {
			let company_id = props.form.getFieldValue("company_id");
			if (company_id && company_id !== undefined) {
				const data = { company_id };
				InsuranceProductStore.getInsuranceSegmentList(data).then(() => {
					setFetchSegment(false)
				});
			}
		}
	}

	const segmentTooltip = () => {
		let modelList = InsuranceProductStore.dropdown_ins_segment_list || [];
		let filterData = []
		if (modelList.length > 0) {
			filterData = modelList.filter(x => x.id === Number(props.form.getFieldValue("segment_id")));
			if (filterData.length > 0) {
				filterData = filterData[0]["model"];
			}
		}
		return filterData && filterData.map(x => {
			return (
				<p>
					{x}
				</p>)
		})
	}


	return props.form ? (
		<Form
			form={props.form}
			id={props.id}
			onFinish={props.handleSubmit}
			labelCol={{ span: 24 }}
			onChange={handleChange}
			initialValues={initialValues}
		>

			<Row gutter={30}>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: addonInsurance.includes(props?.form?.getFieldValue("cat_id")) ? 5 : 6 }}>
					<InputComponent
						type="text"
						required
						label="Name"
						placeholder="Name"
						name="name"
						disabled={isView}
						onChange={handleChange}
						rules={vsmInsuranceProducts.validation.name}
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: addonInsurance.includes(props?.form?.getFieldValue("cat_id")) ? 5 : 7 }} >
					<InputComponent
						type="select"
						allowClear
						required
						autoComplete="chrome-off"
						label="Insurance Company"
						name="company_id"
						disabled={isView}
						placeholder="Select Insurance Company"
						rules={vsmInsuranceProducts.validation.company_id}
						onChange={() => {
							handleChange();
							handleCompanyChange();
						}}
						onFocus={() =>
							fetchCompany &&
							getInsCompanyList().then(() => setFetchCompany(false))
						}
						notFoundContent={
							fetchCompany ? <Spin size="small" /> : "No Record Found."
						}
						options={{
							values: InsuranceProductStore.dropdown_ins_company_list,
							value_key: "id",
							text_key: "name",
							rejected_keys:
								InsuranceProductStore.dropdown_ins_company_list &&
								InsuranceProductStore.dropdown_ins_company_list
									.filter((item) => item.status === 0)
									.map((item) => item.id),
						}}
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: addonInsurance.includes(props?.form?.getFieldValue("cat_id")) ? 5 : 7 }} >
					<InputComponent
						type="select"
						allowClear
						onChange={() => {
							handleCategoryChange();
							handleChange();
						}
						}
						required
						autoComplete="chrome-off"
						name="cat_id"
						disabled={isView}
						label="Insurance Category"
						placeholder="Select Insurance Category"
						rules={vsmInsuranceProducts.validation.cat_id}
						onFocus={() =>
							fetchCategory &&
							getInsCategoryList().then(() => setFetchCategory(false))
						}
						notFoundContent={
							fetchCategory ? <Spin size="small" /> : "No Record Found."
						}
						options={{
							values: InsuranceProductStore.dropdown_ins_category_list,
							value_key: "id",
							text_key: "name",
							rejected_keys:
								InsuranceProductStore.dropdown_ins_category_list &&
								InsuranceProductStore.dropdown_ins_category_list
									.filter((item) => item.status === 0)
									.map((item) => item.id),
						}}
					/>
				</Col>
				{addonInsurance.includes(props?.form?.getFieldValue("cat_id")) ?
					<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 5 }} >
						<InputComponent
							type="select"
							allowClear
							required={addonInsurance.includes(props?.form?.getFieldValue("cat_id"))}
							rules={addonInsurance.includes(props?.form?.getFieldValue("cat_id")) ? vsmInsuranceProducts.validation.product_base : [{ required: false, message: '' }]}
							disabled={isView}
							label="Product Based On"
							placeholder="Select Product Based On"
							name="product_base"
							onChange={() => {
								handleProductBaseChange();
								handleChange();
							}}
							options={{
								values: productBasedOn,
								value_key: "id",
								text_key: "name",
							}}
						/>

					</Col>
					:
					null
				}
				{editFields}
				<Col xs={{ span: 24 }}>
					<Divider />
					<h1 className="formTitle">{`${props?.form?.getFieldValue("product_base") === 10 || props?.form?.getFieldValue("product_base") === undefined ? "Model" : "Segment"} `} Information</h1>
				</Col>
			</Row>

			<Row gutter={30}>
				{modelWiseProduct.includes(props?.form?.getFieldValue("product_base")) || props?.form?.getFieldValue("product_base") === undefined ?
					<>
						<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }} >
							<InputComponent
								type="select"
								allowClear
								required={validateModelBased(props?.form?.getFieldValue("cat_id"), props?.form?.getFieldValue("product_base"))}
								autoComplete="chrome-off"
								label="Brand"
								name="brand_id"
								disabled={isView}
								placeholder="Select Brand"
								rules={validateModelBased(props?.form?.getFieldValue("cat_id"), props?.form?.getFieldValue("product_base")) ? vsmInsuranceProducts.validation.brand_id : vsmInsuranceProducts.validation.non_required}
								onChange={() => {
									handleChange();
									handleBrandChange();
								}}
								onFocus={() =>
									fetchBrand &&
									getBrandList().then(() => setFetchBrand(false))
								}
								notFoundContent={
									fetchBrand ? <Spin size="small" /> : "No Record Found."
								}
								options={{
									values: InsuranceProductStore.dropdown_brand_list,
									value_key: "id",
									text_key: "name",
									rejected_keys:
										InsuranceProductStore.dropdown_brand_list &&
										InsuranceProductStore.dropdown_brand_list
											.filter((item) => item.status === 0)
											.map((item) => item.id),
								}}
							/>
						</Col>

						<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }} >
							<InputComponent
								type="select"
								allowClear
								required={validateModelBased(props?.form?.getFieldValue("cat_id"), props?.form?.getFieldValue("product_base"))}
								autoComplete="chrome-off"
								label="Model"
								name="model_id"
								disabled={isView}
								placeholder="Select Model"
								rules={validateModelBased(props?.form?.getFieldValue("cat_id"), props?.form?.getFieldValue("product_base")) ? vsmInsuranceProducts.validation.model_id : vsmInsuranceProducts.validation.non_required}
								onChange={(value) => {
									handleChange();
									handleModalChange(value);
								}}
								options={{
									values: InsuranceProductStore.dropdown_model_list,
									value_key: "id",
									text_key: "name",
									rejected_keys:
										InsuranceProductStore.dropdown_model_list &&
										InsuranceProductStore.dropdown_model_list
											.filter((item) => item.status === 0)
											.map((item) => item.id),
								}}
							/>
						</Col>
					</>
					: null
				}

				<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }} >
					<InputComponent
						type="select"
						allowClear
						required={!modelWiseProduct.includes(props?.form?.getFieldValue("product_base"))}
						autoComplete="chrome-off"
						label="Passing Type"
						name="passing_type_id"
						disabled={isView}
						placeholder="Select Passing Type"
						rules={modelWiseProduct.includes(props?.form?.getFieldValue("product_base")) ? vsmInsuranceProducts.validation.non_required : vsmInsuranceProducts.validation.passing_type_id}
						onChange={() => { handleChange(); }}
						onFocus={() =>
							fetchPassingType &&
							getPassingTypeList().then(() => setFetchPassingType(false))
						}
						notFoundContent={
							fetchPassingType ? <Spin size="small" /> : "No Record Found."
						}
						options={{
							values: InsuranceProductStore.dropdown_passing_type_list,
							value_key: "id",
							text_key: "name",
							rejected_keys:
								InsuranceProductStore.dropdown_passing_type_list &&
								InsuranceProductStore.dropdown_passing_type_list
									.filter((item) => item.status === 0)
									.map((item) => item.id),
						}}
					/>
				</Col>

				<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }} className="tooltipText tooltipRightSpace">
					{!modelWiseProduct.includes(props?.form?.getFieldValue("product_base")) ?
						<InputComponent
							type="select"
							allowClear
							required={addonInsurance.includes(props?.form?.getFieldValue("cat_id"))}
							autoComplete="chrome-off"
							label="Segment"
							tooltip={
								<>
									{
										segmentTooltip()
									}
								</>
							}
							name="segment_id"
							disabled={isView || (!addonInsurance.includes(props?.form?.getFieldValue("cat_id")))}
							placeholder="Select Segment"
							rules={addonInsurance.includes(props?.form?.getFieldValue("cat_id")) ? vsmInsuranceProducts.validation.segment_id : vsmInsuranceProducts.validation.non_required}
							onChange={() => { handleChange(); }}
							options={{
								values: InsuranceProductStore.dropdown_ins_segment_list,
								value_key: "id",
								text_key: "name",
								rejected_keys:
									InsuranceProductStore.dropdown_ins_segment_list &&
									InsuranceProductStore.dropdown_ins_segment_list
										.filter((item) => item?.status === 0)
										.map((item) => item.id),
							}}
						/>
						:
						<InputComponent
							type="select"
							allowClear
							required={addonInsurance.includes(props?.form?.getFieldValue("cat_id"))}
							autoComplete="chrome-off"
							label="Segment"
							name="segment_id"
							disabled={isView || (!addonInsurance.includes(props?.form?.getFieldValue("cat_id")))}
							placeholder="Select Segment"
							rules={addonInsurance.includes(props?.form?.getFieldValue("cat_id")) ? vsmInsuranceProducts.validation.segment_id : vsmInsuranceProducts.validation.non_required}
							onChange={() => { handleChange(); }}
							notFoundContent={
								fetchSegment ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: InsuranceProductStore.dropdown_segment_list,
								value_key: "id",
								text_key: "name",
								rejected_keys:
									InsuranceProductStore.dropdown_segment_list &&
									InsuranceProductStore.dropdown_segment_list
										.filter((item) => item.status === 0)
										.map((item) => item.id),
							}}
						/>
					}
				</Col>

			</Row>

			<Row>
				<Col xs={{ span: 24 }}>
					<Divider />
					<h1 className="formTitle">Insurance Information</h1>
				</Col>
			</Row>

			<Row gutter={30}>

				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
					<InputComponent
						type="text"
						label="Fixed Amount"
						placeholder="Fixed Amount"
						name="fixed_amt"
						disabled={isView || tpInsurance.includes(props?.form?.getFieldValue("cat_id") ? props?.form?.getFieldValue("cat_id") : catID)}
						onChange={handleChange}
						defaultValue="0"
						className="text-right"
						rules={vsmInsuranceProducts.validation.fixed_amt}
					/>
				</Col>

				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
					<InputComponent
						type="text"
						label="Anti-theft (%)"
						placeholder="Anti-theft"
						name="anti_theft_per"
						disabled={isView || tpInsurance.includes(props?.form?.getFieldValue("cat_id") ? props?.form?.getFieldValue("cat_id") : catID)}
						onChange={handleChange}
						defaultValue="0"
						className="text-right"
						rules={vsmInsuranceProducts.validation.anti_theft_per}
					/>
				</Col>

				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
					<InputComponent
						type="text"
						label="CPA (1 Year)"
						placeholder="CPA (1 Year)"
						name="cpa"
						disabled={isView}
						onChange={handleChange}
						defaultValue="0"
						className="text-right"
						rules={vsmInsuranceProducts.validation.cpa}
					/>
				</Col>

				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
					<InputComponent
						type="text"
						label="PAD"
						placeholder="PAD"
						name="pad"
						disabled={isView}
						onChange={handleChange}
						defaultValue="0"
						className="text-right"
						rules={vsmInsuranceProducts.validation.pad}
					/>
				</Col>

				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
					<InputComponent
						type="text"
						label="PAP"
						placeholder="PAD"
						name="pap"
						disabled={isView}
						onChange={handleChange}
						defaultValue="0"
						className="text-right"
						rules={vsmInsuranceProducts.validation.pap}
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
					<InputComponent
						type="text"
						label="CPA (3 Year)"
						placeholder="CPA (3 Year)"
						name="cpa_3"
						disabled={isView}
						onChange={handleChange}
						defaultValue="0"
						className="text-right"
						rules={vsmInsuranceProducts.validation.cpa_3}
					/>
				</Col>
			</Row>

			{props.extraFields}
		</Form>
	) : null;
});

export default InsuranceProductFormComponent;
