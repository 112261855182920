import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import ServerGridConfig from "../../../config/ServerGridConfig";
import { getDefaultPayloadBookingStatus, globalStatus } from "../../../utils/GlobalFunction";

export default class CancelledStore {
	AUTH = null
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	reopenBookingValues = null;

	constructor(AUTHStore) {
		this.AUTH = AUTHStore
	};

	// set form values to view
	setReopenBookingValues = (data) => {
		this.reopenBookingValues = data;
	};


	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		const { api } = params
		var datasource = this.createDatasource(ServerGridConfig.options)
		api.setServerSideDatasource(datasource)
		let dateFilter;
		if (localStorage.getItem("cancelledDate")) {
			dateFilter = JSON.parse(localStorage.getItem("cancelledDate"));
			const filter = { ...dateFilter };
			this.agGrid.api.setFilterModel(filter);
		}
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel
		var final_sort = params.sortModel
		if (final_filter.status) {
			let values_changed = []
			final_filter.status.values.forEach(x => (
				values_changed.push(globalStatus('booking_status', 'value', x))
			))
			final_filter.status.values = values_changed
		}
		if (final_filter.rto_status) {
			let values_changed = []
			final_filter.rto_status.values.forEach(x => (
				values_changed.push(globalStatus('booking_status_common_status', 'value', x))
			))
			final_filter.rto_status.values = values_changed
		}
		if (final_filter.exchange_status) {
			let values_changed = []
			final_filter.exchange_status.values.forEach(x => (
				values_changed.push(globalStatus('booking_status_common_status', 'value', x))
			))
			final_filter.exchange_status.values = values_changed
		}
		if (final_filter.resale_status) {
			let values_changed = []
			final_filter.resale_status.values.forEach(x => (
				values_changed.push(globalStatus('booking_status_common_status', 'value', x))
			))
			final_filter.resale_status.values = values_changed
		}
		if (final_filter.finance_status) {
			let values_changed = []
			final_filter.finance_status.values.forEach(x => (
				values_changed.push(globalStatus('finance_status', 'value', x))
			))
			final_filter.finance_status.values = values_changed
		}

		if (final_filter["insurance_offer.status"]) {
			let values_changed = [];
			final_filter["insurance_offer.status"].values.forEach((x) =>
				values_changed.push(
					typeof x !== 'number' ? globalStatus("insurance_status", "value", x) : x
				)
			);
			final_filter["insurance_offer.status"].values = values_changed;
		}
		return { final_filter, final_sort }
	}

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request)
				var defaultStatus = [20]
				if (this.AUTH) {
					defaultStatus = getDefaultPayloadBookingStatus(this.AUTH?.user?.role_id)
				}
				const defaultPayload = { "filter_data": { "status": { "values": defaultStatus, "filterType": "set" } } }
				var payload = {
					filter_data: { ...defaultPayload.filter_data, ...filter_data.final_filter },
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				this.getList(payload).then((data) => {
					if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.list.data, data.list.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.colId)
						})
						// this.agGrid.columnApi.autoSizeColumns(allColumnIds)
					}
				})
			}
		}
	}

	// call api to get records
	getList = (payload) => {
		// var filter = this.agGrid.api.getFilterModel();
		return Axios.post(`/sales/manage_bookings/list_cancelled`, payload).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.date_completed = item.date_completed ? moment(item.date_completed).format("DD/MM/YYYY") : "N/A";
					item.date = item.date ? moment(item.date).format("DD/MM/YYYY") : "N/A";
					item.booking_model.promissed_delivery_date = item.booking_model.promissed_delivery_date ? moment(item.booking_model.promissed_delivery_date).format("DD/MM/YYYY") : "N/A";
					return null;
				});
			}
			this.list_data =
				data.list && data.list.data.length ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data
		});
	};


	ReopenBooking = (formdata) => {
		return Axios.post(`/sales/manage_bookings/reopen/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

}

decorate(CancelledStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	reopenBookingValues: observable
});
