import React, { useState } from "react";
import { Form, Button, Modal, Col, Row } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { vsmNotify } from "../../../../../../config/messages";
import useStore from "../../../../../../store";

const CancelMuniReqComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageZFormsStore
	} = useStore();

	const [saving, setSaving] = useState();
	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true);
		data = {
			booking_id: ManageZFormsStore.viewValues?.id,
			sc_id: ManageZFormsStore.viewValues?.sc_id,
			location_id: ManageZFormsStore.viewValues?.location_id
		}
		ManageZFormsStore.sendMunicipalRequest(data)
			.then((data) => {
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
				close();
			})
			.catch((e) => {
				if (e.errors) {
					vsmNotify.error({
                        message: data.STATUS.NOTIFICATION[0],
                    });
				}
			})
			.finally(() => {
				setSaving(false);
			});
	};

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
	};

	return  (
		<Modal
			centered
			className="cancelModal"
			style={{zIndex:999}}
			title="Municipality Not Needed?"
			zIndex={1005}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					No
				</Button>,
				<Button
					key="1"
					form="cancelMuniReqForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Yes
				</Button>,
			]}
		>
			<Form form={form} id="cancelMuniReqForm" onFinish={handleSubmit}>
				<Row align="middle">
					<Col span={24} className="text-center">
						<h3>
						Are you sure for municipality tax won't apply for this location? Clicking on Yes, request will go to RTO for approval and need to wait for reply.
						</h3>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
});

export default CancelMuniReqComponent;
