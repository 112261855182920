import { Button, PageHeader } from "antd";
import { observer } from "mobx-react";
import ListComponent from "./component/ListComponent";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import DRFComponent from "./component/ApplyDRFComponent";
import { useState } from "react";
import HistoryListComponent from "./component/HistoryListComponent";

// import { vsmNotify } from "../../../config/messages";

const ManageDRF = observer((props) => {
	const [drfModal, setDrfModal] = useState(false)
	const [viewType, setViewType] = useState(true);
	const {
		ManageDRFStore: {
			setPageSize,
			per_page,
			setEditValues,
			setTypeMode
		},
	} = useStore();

	// Open & Close  form for DRF
	const openDRFModal = (data, openHistory) => {
		setTypeMode(openHistory)
		setEditValues(data);
		setDrfModal(true);
	};
	const closeDRFModal = () => setDrfModal(false);

	const handleChange = () => {
		setViewType(!viewType)
	};


	return (
		<PageHeader
			title={BreadcrumbConfig.ManageDRF.title}
			className="tableAreaSec"
			extra={
				<BreadcrumbComponent items={BreadcrumbConfig.ManageDRF.path} />
			}
		>
			<div className="listCountNew">
				<Button key="1"
					onClick={handleChange}
				>
					{viewType ? "History" : "Pending"}
				</Button>
				<RecordPerPage
					key="2"
					style={{ width: "150px" }}
					defaultValue={per_page + " per page"}
					onChange={setPageSize}
				/>
			</div>
			{/* DRF */}
			<DRFComponent
				visible={drfModal}
				close={closeDRFModal}
			/>
			{viewType && (viewType === true) ?
				<ListComponent openDRFModal={openDRFModal} />
				:
				<HistoryListComponent openDRFModal={openDRFModal}  openForm="openHistory"/>
			}
		</PageHeader>
	);
});

export default ManageDRF;
