import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row, Drawer } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { vsmDRF, vsmNotify } from "../../../../config/messages";
import useStore from "../../../../store";
import debounce from "lodash/debounce";
import InputComponent from "../../../../component/InputComponent";
import moment from "moment";
import { default_roles, DRF_status } from "../../../../utils/GlobalFunction";
import LedgerComponent from "../../../bookingSales/ManageZForms/component/LedgerComponent";


const ApproveComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageDRFStore,
		ManageZFormsStore,
		AUTH,
		ManageDRFStore: { typeMode }
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [flagValue, setFlagValue] = useState(false);
	const [viewZformModal, setviewZformModal] = useState(false);
	const dateFormat = "DD/MM/YYYY";
	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((data) => {
				setDisabled(false);
			})
			.catch((e) => {
				setDisabled(true);
			});
	}, 500);
	useEffect(() => {
		// setIsDisabled(false)
		if (props.visible && ManageDRFStore.editValues && ManageDRFStore.editValues?.booking_drf) {
			form.setFieldsValue({
				delivery_date: moment(ManageDRFStore.editValues?.booking_drf?.delivery_date),
				notes: ManageDRFStore.editValues?.booking_drf?.notes
			})
		}
	}, [ManageDRFStore.editValues, form, props.type, props.visible])

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true);
		data.delivery_date = moment(data.delivery_date).format("YYYY-MM-DD");
		data.id = ManageDRFStore.editValues.id;
		ManageDRFStore.DrfApply(data)
			.then((data) => {
				close();
				ManageZFormsStore.refreshList();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => {
				setSaving(false);
			});
	};
	const handleCompletedSubmit = () => {
		setSaving(true);
		const formData = new FormData();
		formData.append("id", ManageDRFStore.editValues.id);
		formData.append("notes", form.getFieldValue("notes"));
		ManageDRFStore.DrfCompleted(formData, ManageDRFStore.editValues.id)
			.then((data) => {
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => {
				setSaving(false);
			});

	};
	const handleRejectedSubmit = () => {
		setSaving(true);
		const formData = new FormData();
		formData.append("id", ManageDRFStore.editValues.id);
		formData.append("notes", form.getFieldValue("notes"));
		ManageDRFStore.DrfRejected(formData, ManageDRFStore.editValues.id)
			.then((data) => {
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => {
				setSaving(false);
			});

	};
	const handleBacktoworkshopSubmit = () => {
		setSaving(true);
		const formData = new FormData();
		formData.append("id", ManageDRFStore.editValues.id);
		formData.append("notes", form.getFieldValue("notes"));
		ManageDRFStore.DrfBacktoworkshop(formData, ManageDRFStore.editValues.id)
			.then((data) => {
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => {
				setSaving(false);
			});

	};
	const disabledDate = (current) => {
		// if (current) {
		// 	var a = current <= moment().subtract(1, 'days').startOf('day')
		// 	var b = current >= moment().add(4, 'days').endOf('day')
		// 	return a || b
		// }

		let startDate = moment()
		let endDate = moment().add(4, 'days');
		return !moment(current).isBetween(startDate, endDate);
	};
	// Open & Close  form for edit State
	const openZformModal = (data) => {
		ManageZFormsStore.setViewValues(data);
		setviewZformModal(true);
	};
	const closeZformModal = () => setviewZformModal(false);
	// reset form and close add form
	const close = () => {
		props.close();
		setDisabled(true);
		form.resetFields();
		ManageDRFStore.setEditValues(null)
	};
	return (
		<>
		<Drawer
			className="addModal"
			title="Delivery Request Form"
			width="50%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-15"
					onClick={close}
				>
					Cancel
				</Button>,
				(AUTH.checkPrivileges("#8502#") && [default_roles.sales_consultant, default_roles.team_leader_sales].includes(AUTH?.user?.role_id) && ManageDRFStore.editValues?.booking_drf?.status !== 20 && ManageDRFStore.editValues?.booking_drf?.status !== 10 && typeMode !== "openHistory" && <Button
					key="1"
					disabled={disabled}
					form="addDRFForm"
					className="mr-15"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Apply
				</Button>),
				(AUTH.checkPrivileges("#8503#") && [default_roles.pdi].includes(AUTH?.user?.role_id) && typeMode !== "openHistory" &&
					<Button
						key="2"
						form="addDRFForm"
						loading={saving}
						className="mr-15"
						onClick={() => {
							handleCompletedSubmit();
							setFlagValue(false)
						}}
						type="primary"
					>
						Completed
					</Button>)
				,
				(AUTH.checkPrivileges("#8504#") && [default_roles.pdi].includes(AUTH?.user?.role_id) && ManageDRFStore?.editValues?.status === 20 && typeMode !== "openHistory" &&
					<Button
						key="3"
						disabled={disabled}
						form="addDRFForm"
						className="mr-15"
						loading={saving}
						onClick={() => {
							handleRejectedSubmit();
							setFlagValue(true)
						}}
						type="primary"
					>
						Rejected
					</Button>)
				,
				(AUTH.checkPrivileges("#8505#") && [default_roles.admin, default_roles.mis_executive].includes(AUTH?.user?.role_id) && ManageDRFStore?.editValues?.status === 20 && typeMode !== "openHistory" &&
					<Button
						key="3"
						disabled={disabled}
						form="addDRFForm"
						loading={saving}
						onClick={() => {
							handleBacktoworkshopSubmit();
							setFlagValue(true)
						}}
						type="primary"
					>
						Back to Workshop
					</Button>),
				(AUTH.checkPrivileges("#8010#") || AUTH.checkPrivileges("#8001#")) &&
				<Button
					key="4"
					htmlType="button"
					type="primary"
					onClick={() => {
						openZformModal({ id: ManageDRFStore.editValues.id})
					}}
				>
					View Z-Form
				</Button>
			]}
		>
			<Form
				form={form}
				id="addDRFForm"
				labelCol={{ span: 24 }}
				onChange={handleChange}
				onFinish={handleSubmit}
			>
				<Row gutter={30}>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} xl={{ span: 12 }}>
						<InputComponent
							type="date"
							mode="date"
							required
							format={dateFormat}
							onChange={handleChange}
							disabledDate={disabledDate}
							disabled={props.openFrom !== "DRFZfrom"}
							label="Delivery Date"
							placeholder="Delivery Date"
							name="delivery_date"
							rules={vsmDRF.validation.delivery_date}
						/>
					</Col>
					<Col xs={{ span: 24 }}>
						<InputComponent
							type="textarea"
							label="Note"
							required={props.openFrom !== "DRFZfrom"}
							placeholder="Note"
							name="notes"
							onChange={handleChange}
							disabled={props.openFrom === "DRFZfrom" || typeMode === "openHistory"}
							rules={[
								{ required: flagValue === true ? true : false, message: "Notes cannot be blank.", },
								{
									min: 10,
									message: "Note must have at least 10 characters",
								},
								{
									max: 100,
									message: "Maximum length for Note is 100 characters",
								}

							]}
						/>
					</Col>
					<Col xs={{ span: 24 }} style={{ fontSize: "16px" }}>
						<strong>Status :</strong> <span>  {(ManageDRFStore.editValues && ManageDRFStore.editValues?.booking_drf?.status) ? DRF_status[ManageDRFStore.editValues?.booking_drf?.status] : "N/A"} </span>
					</Col>
				</Row>
			</Form>
		</Drawer>
		<LedgerComponent visible={viewZformModal} close={closeZformModal} />
		</>
	
	);
});

export default ApproveComponent;
