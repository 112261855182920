import { Button, PageHeader } from "antd";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import ListComponent from "./component/ListComponent";
import AddComponent from "./component/AddComponent";
import EntryComponent from "./component/EntryComponent";
import EditComponent from "./component/EditComponent";
import ViewComponent from "./component/ViewComponent";
import DeleteComponent from "./component/DeleteComponent";
import DateExtendComponent from "./component/DateExtendComponent";
import { vsmNotify } from "../../../config/messages";
import PackageDefFreeChassisComponent from "./component/packageDefComponent/AddFreeChassisComponent";

const ManagePackages = observer((props) => {
	const [entryeModal, setEntryModal] = useState(false);
	const [addModal, setAddModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [viewModal, setViewModal] = useState(false);
	const [dateExtendModal, setdateExtendModal] = useState(false);
	const [freeChassisModal, setFreeChassisModal] = useState(false);
	const {
		ManagePackagesStore: {
			getList,
			setEditValues,
			setEntryValues,
			setViewValues,
			setDeleteValues,
			TogglepublishData,
			setPageSize,
			per_page,
			agGrid,
		},
		AUTH,
		ManagePackageDefStore
	} = useStore();

	// Open & Close  form for add new State
	const openAddModal = () => setAddModal(true);
	const closeAddModal = () => setAddModal(false);

	// Open & Close  form for edit State
	const openEntryodal = (data) => {
		setEntryValues(data);
		setEntryModal(true);
	};
	const closeEntryModal = () => setEntryModal(false);

	// Open & Close  form for edit State
	const openEditModal = (data) => {
		setEditValues(data);
		setEditModal(true);
	};
	const closeEditModal = () => setEditModal(false);

	// Open & Close  form for edit State
	const openDeleteModal = (data) => {
		setDeleteValues(data);
		setDeleteModal(true);
	};
	const closeDeleteModal = () => setDeleteModal(false);

	// Open & Close  form for edit State
	const openViewModal = (data) => {
		setViewValues(data);
		setViewModal(true);
	};
	const closeViewModal = () => setViewModal(false);

	const openDateExtendModal = (data) => {
		setEditValues(data);
		setdateExtendModal(true);
	};
	const closeDateExtendModal = () => setdateExtendModal(false);

	// Open & Close  form for edit State
	const openFreeChassisModal = (data) => {
		ManagePackageDefStore.setFreeChassisValues(data)
		setFreeChassisModal(true);
	};
	const closeFreeChassisModal = () => {
		setFreeChassisModal(false) 
		ManagePackageDefStore.setFreeChassisValues(null)
	};


	// Handle on switch data
	const onSwitchChange = (checked, data) => {
		handlePublish(data);
	};

	// Handle Publish and call function to active user
	const handlePublish = (data) => {
		let formdata = data;
		TogglepublishData(formdata)
			.then((data) => {
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch(() => {
				agGrid.api.refreshCells({ force: true });
			});
	};

	useEffect(() => {
		getList();
	}, [getList]);

	return (
		<PageHeader
			title={BreadcrumbConfig.ManagePackages.title}
			className="tableAreaSec"
			extra={
				<BreadcrumbComponent items={BreadcrumbConfig.ManagePackages.path} />
			}
		>
			<div className="listCountNew">
				{AUTH.checkPrivileges("#6205#") && (
					<Button key="1" onClick={openAddModal}>
						New
					</Button>
				)}
				<RecordPerPage
					key="2"
					style={{ width: "150px" }}
					defaultValue={per_page + " per page"}
					onChange={setPageSize}
				/>
			</div>
			<EntryComponent visible={entryeModal} close={closeEntryModal} openFreeChassisModal={openFreeChassisModal}/>
			<AddComponent visible={addModal} close={closeAddModal} />
			<EditComponent visible={editModal} close={closeEditModal} />
			<ViewComponent visible={viewModal} close={closeViewModal}/>
			<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
			<DateExtendComponent visible={dateExtendModal} close={closeDateExtendModal} />
			<PackageDefFreeChassisComponent visible={freeChassisModal} close={closeFreeChassisModal}/>
			<ListComponent
				openEntryodal={openEntryodal}
				openEditModal={openEditModal}
				openDeleteModal={openDeleteModal}
				openViewModal={openViewModal}
				onSwitchChange={onSwitchChange}
				openDateExtendModal={openDateExtendModal}
			/>
		</PageHeader>
	);
});

export default ManagePackages;
