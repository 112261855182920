import React, { useState } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import useStore from "../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { vsmNotify } from '../../../config/messages';

const MarkAsClaimedComponent = observer((props) => {
	const [form] = Form.useForm()
	const { CorporatePayoutsAllStore: { MarkAsClaimedData, payout_detail } } = useStore()
	const [saving, setSaving] = useState()

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		MarkAsClaimedData(payout_detail.corporate_payout?.id, data).then((res) => {
			vsmNotify.success({
				message: res.STATUS.NOTIFICATION[0],
			});
			close();
		})
			.catch((e) => {
				// console.log("error......", e);
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};


	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
	};

	return (
		<Modal
			centered
			title={`Mark as Claimed`}
			visible={props.visible}
			onCancel={close}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button
					key="1"
					className="mr-35"
					form="corporatemarkAsClaimedForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn"
					onClick={close}
				>
					Cancel
				</Button>,
			]}
		>
			<Form form={form} layout="vertical" id="corporatemarkAsClaimedForm" onFinish={handleSubmit} >
				<Row align="middle">
					<Col span={24}>
						{`Assuming that you have sent a claim request to the manufacturer. Would you like to mark as claimed?`}
					</Col>

				</Row>
			</Form>
		</Modal >
	)
})

export default MarkAsClaimedComponent
