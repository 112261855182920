import { useState, useEffect } from 'react'
import { PageHeader, Select } from "antd";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import ListComponent from "./ListComponent";
import ViewComponent from "../../quotations/ViewComponent";
import HistoryList from "../../quotations/HistoryList/ListComponent";
import PendingApproval from "../../quotations/PendingApproval/ListComponent";
const { Option } = Select;
const PendingList = observer((props) => {

	const [viewModal, setviewModal] = useState(false);
	const [viewType, setViewType] = useState('Pending Approval');

	const {
		InsuranceOfferStore,
		InsuranceQuotationPendingStore,
		InsuranceQuotationStore: {
			setViewValues
		},
		InsuranceQuotationPendingStore: {
			setPageSize,
			per_page,
		},
	} = useStore();

	const openViewModal = (data) => {
		setViewValues(data);
		InsuranceOfferStore.insurance_detail = {
			id: data.ins_offer_id
		}
		setviewModal(true);
	}

	const closeViewModal = () => {
		setviewModal(false);
		setViewValues(null);
		if (InsuranceQuotationPendingStore.agGrid) {
			InsuranceQuotationPendingStore.setupGrid(InsuranceQuotationPendingStore.agGrid);
		}
		InsuranceOfferStore.insurance_detail = null;
	}

	const handleChange = (value) => {
		setViewType(value)
	};

	useEffect(() => {

		if (localStorage.getItem("quotation_type")) {
			let viewType = JSON.parse(localStorage.getItem("quotation_type"))
			setViewType(viewType)
		}
	}, [])
	useEffect(() => {
		return () => {
			localStorage.removeItem("insuranceQuotation");
			localStorage.removeItem("quotation_type");
		}
	}, [])

	return (
		<PageHeader
			title={BreadcrumbConfig.InsuranceQuotationPending.title + ((viewType === "Pending Approval" && " - Pending Approval") || (viewType === "Discount Approval" && " - Discount Approval") || (viewType === "Discount History" && " - Discount History"))}
			className="tableAreaSec"
			extra={
				<BreadcrumbComponent items={BreadcrumbConfig.InsuranceQuotationPending.path} />
			}
		>
			<div className="listCountNew">

				<Select style={{ width: 130 }} value={viewType} onChange={handleChange}>
					<Option key={0} value='Pending Approval'>Pending Approval</Option>
					<Option key={2} value='Discount Approval'>Discount Approval</Option>
					<Option key={1} value='Discount History'>Discount History</Option>
				</Select>
				<RecordPerPage
					key="2"
					style={{ width: "150px" }}
					defaultValue={per_page + " per page"}
					onChange={setPageSize}
				/>
			</div>

			<ViewComponent visible={viewModal} close={closeViewModal} />
			{viewType && (viewType === "Pending Approval") ?
				<ListComponent
					openViewModal={openViewModal}
				/>
				: (viewType === "Discount Approval") ?
					<PendingApproval
						openViewModal={openViewModal}
					/>
					:
					<HistoryList
						openViewModal={openViewModal}
					/>
			}

		</PageHeader>
	);
});

export default PendingList;
