import React, { useState } from "react";
import { Form, Button, Drawer, Row, Col, Spin, Divider } from "antd";
import { vsmNotify, vsmUsers, vsmProfile } from "../../../../config/messages";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../../../component/InputComponent";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "@ag-grid-community/react";
import { vsmCommon } from "../../../../config/messages";

// import Checkbox from "antd/lib/checkbox/Checkbox";
// import moment from "moment";
import debounce from "lodash/debounce";

const ChangePasswordComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageUserStore,
		ManageUserStore: {
			ChangeAllPasswordData,
			location_wise_user_data,
			setupLocationUserGrid,
			getListBasedOnLocation,
		},
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [fetchLocation, setFetchLocation] = useState(true);
	const [locationWiseUerData, setLocationWiseUerData] = useState([]);
	const [selectedRowKeysdisabled, setSelectedRowKeysdisabled] = useState(true);

	const handleLocationUserData = (location_id) => {
		setSelectedRowKeysdisabled(true);
		setDisabled(true);
		ManageUserStore.location_wise_user_data = null;
		if (location_id) {
			getListBasedOnLocation({
				location_id: location_id,
			});
		}
		// 	.then((data) => {
		// 		debugger;
		// 		setLocationWiseUerData(data.list.data);
		// 	})
		// 	.catch(({ response: { data } }) => {
		// 		var errors = [];
		// 		var notify = null;
		// 		const { NOTIFICATION, ...fieldErrors } = data.STATUS;
		// 		if (data && data.STATUS) {
		// 			if (NOTIFICATION) {
		// 				notify = NOTIFICATION[0];
		// 			}
		// 			Object.keys(fieldErrors).forEach((name) => {
		// 				errors.push({ name, errors: data.STATUS[name] });
		// 			});
		// 		}
		// 		return Promise.reject({ errors, notify });
		// 	});
	};

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true);
		if (locationWiseUerData && locationWiseUerData.length > 0) {
			data.user_ids = locationWiseUerData; //.toString();
			ChangeAllPasswordData(data)
				.then((data) => {
					close();
					vsmNotify.success({
						message: data.STATUS.NOTIFICATION[0],
					});
				})
				.catch((e) => {
					if (e.errors) {
						form.setFields(e.errors);
					}
				})
				.finally(() => setSaving(false));
		} else {
			setSaving(false);
			vsmNotify.error({
				message: vsmUsers.messages.user_ids_required,
			});
		}
	};

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				if (d.errorFields && d.errorFields.length > 0) {
					setDisabled(true);
				}
			});
	}, 500);

	// handle password tool tip visiblility
	const handleChangePassword = (e) => {
		form
			.validateFields()
			.then((data) => {
				setDisabled(false);
			})
			.catch((e) => {
				setDisabled(true);
			});
	};

	// reset form and close add form
	const close = () => {
		props.close();
		setLocationWiseUerData(null);
		form.resetFields();
		setDisabled(true);
		setSelectedRowKeysdisabled(true);
		setFetchLocation(true);
		// setFetchLevel(true);
		ManageUserStore.dropdown_locations_list = null;
		ManageUserStore.location_wise_user_data = null;
	};
	const gridOptions = {
		columnDefs: [
			{
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true,
				filter: false,
				sortable: false,
				floatingFilter: false,
				suppressMenu: true,
				pinned: "left",
				minWidth: 60,
				width: 60,
			},
			{
				headerName: "ID",
				field: "id",
				pinned: "left",
				filter: "agNumberColumnFilter",
				// headerCheckboxSelection: true,
				// checkboxSelection: true,
				minWidth: 120,
				width: 120,
			},
			{
				headerName: "Name",
				field: "name",
			},
			{
				headerName: "Username",
				field: "username",
			},
			{
				headerName: "Role",
				field: "role.name",
			},
			{
				headerName: "Department",
				field: "department.name",
			},
		],
	};

	const updateLocationWiseUserData = (event) => {
		let selected = event.api.getSelectedRows();
		if (selected.length > 0) {
			form.setFieldsValue({
				total_record: selected.length,
			});
			setLocationWiseUerData(selected.map((item) => item.id));
			setSelectedRowKeysdisabled(false);
		} else {
			form.setFieldsValue({
				total_record: 0,
			});
			setSelectedRowKeysdisabled(true);
			setDisabled(true);
			setLocationWiseUerData(null);
		}
		return true;
	};
	// const rowSelection = {
	// 	onChange: (selectedRowKeys, selectedRows) => {
	// 		if (selectedRows) {
	// 			setSelectedRowKeysdisabled(false);
	// 		}
	// 	},
	// };
	LocalGridConfig.options.paginationPageSize = 10;
	return (
		<Drawer
			className="changePassword"
			title="Change Password"
			width={"80%"}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					form="changePasswordForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
			]}
		>
			<Form
				form={form}
				id="changePasswordForm"
				onFinish={handleSubmit}
				layout="vertical"
				labelCol={{ span: 24 }}
			>
				<Row gutter={30}>
					<Col xs={{ span: 8 }}>
						<InputComponent
							type="select"
							autoComplete="chrome-off"
							filterOption="false"
							allowClear
							required
							label="Location"
							name="location_id"
							placeholder="Select"
							mode="single"
							rules={vsmUsers.validation.location_id}
							onChange={(e) => {
								handleChange();
								handleLocationUserData(e);
							}}
							onFocus={() =>
								fetchLocation &&
								ManageUserStore.getLocationsList().then(() =>
									setFetchLocation(false)
								)
							}
							notFoundContent={
								fetchLocation ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageUserStore.dropdown_locations_list,
								value_key: "id",
								text_key: "name",
								rejected_keys:
									ManageUserStore.dropdown_locations_list &&
									ManageUserStore.dropdown_locations_list
										.filter((item) => item.status === 0)
										.map((item) => item.id),
							}}
						/>
					</Col>
				</Row>

				{location_wise_user_data && (
					<>
						<Divider />
						<div className="ag-theme-alpine grid_wrapper">
							<AgGridReact
								domLayout="autoHeight"
								rowSelection={"multiple"}
								suppressRowClickSelection={true}
								rowHeight={LocalGridConfig.rowHeight}
								headerHeight={LocalGridConfig.headerHeight}
								rowData={location_wise_user_data}
								modules={AllModules}
								columnDefs={gridOptions.columnDefs}
								defaultColDef={LocalGridConfig.defaultColDef}
								columnTypes={LocalGridConfig.columnTypes}
								overlayNoRowsTemplate={vsmCommon.noRecord}
								onGridReady={setupLocationUserGrid}
								gridOptions={LocalGridConfig.options}
								onSelectionChanged={(e) => {
									updateLocationWiseUserData(e);
								}}
							/>
						</div>
					</>
				)}
				<Divider />
				<Row gutter={30}>
					<Col xs={{ span: 8 }}>
						<InputComponent
							type="text"
							disabled
							required
							label="Total Record"
							placeholder="Total Record"
							name="total_record"
						/>
					</Col>
					<Col xs={{ span: 8 }}>
						<InputComponent
							hasFeedback
							required
							disabled={selectedRowKeysdisabled}
							label="New Password"
							type="password"
							name="new_password"
							placeholder="New Password"
							onChange={handleChangePassword}
							onFocus={handleChangePassword}
							rules={vsmProfile.validation.new_password}
						/>
					</Col>
					<Col xs={{ span: 8 }}>
						<InputComponent
							hasFeedback
							required
							disabled={selectedRowKeysdisabled}
							label="Confirm Password"
							type="password"
							name="confirm_password"
							placeholder="Confirm Password"
							onChange={handleChangePassword}
							onFocus={handleChangePassword}
							rules={vsmProfile.validation.confirm_password}
						/>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
});

export default ChangePasswordComponent;
