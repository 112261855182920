import ServerGridConfig from "../../../config/ServerGridConfig";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import useStore from "../../../store";
import { observer } from "mobx-react";
import { vsmCommon } from "../../../config/messages";
import { CurrencyFormat, DateComparator } from "../../../utils/GlobalFunction";
import Unauthorized from "../../commonpage/Unauthorized";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import { PageHeader, DatePicker, Button, Form } from "antd";
import moment from "moment";
import { useEffect } from "react";
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const FinanceReportListComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		FinanceReportStore: { list_data, setupGrid, setPageSize, per_page, setListCustomDate, fromDate, toDate  },
		AUTH: { checkPrivileges },
	} = useStore();

	useEffect(() => {
		let datecompleted = []
		datecompleted.push(moment().subtract(1, "month"), moment().subtract(1, "days"))
		form.setFieldsValue({
			date_completed: datecompleted
		})
	}, [form])


	const gridOptions = {
		columnDefs: [
			{
				headerName: "# ID",
				field: "srno",
				filter: "agNumberColumnFilter",
				pinned: "left",
				minWidth: 120,
				width: 120,
			},
			{
				headerName: "Date",
				field: "date_completed",
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator,
					browserDatePicker: true
				},
			},
			{
				headerName: "Location Name",
				field: "location.name",
				valueGetter: (params) => (params.data && params.data.location) ? params.data.location?.name : "N/A",
			},
			{
				headerName: "Customer Name",
				field: "booking_customer.full_name",
				valueGetter: (params) => (params.data && params.data?.booking_customer?.changed_name) ? params.data?.booking_customer?.changed_name : params.data?.booking_customer?.full_name
			},
			{
				headerName: "Sales Consultant",
				field: "sales_consultant.name",
				valueGetter: (params) => (params.data && params.data.sales_consultant) ? params.data.sales_consultant?.name : "N/A",
			},
			{
				headerName: "Zform ID",
				field: "id",
				filter: "agNumberColumnFilter",
				minWidth: 120,
				width: 120,
				valueGetter: (params) => (params.data && params.data.booking_customer) ? params.data.booking_customer?.booking_id : "N/A"
			},
			{
				headerName: "Bank Name",
				field: "finance_offer.bank.name",
				valueGetter: (params) => (params.data && params.data.finance_offer) ? params.data.finance_offer?.bank?.name : "N/A"
			},
			{
				headerName: "Loan Type",
				field: "finance_offer.loan_source.name",
				valueGetter: (params) => (params.data && params.data.finance_offer?.loan_source?.name) ? params.data.finance_offer?.loan_source?.name : "N/A"
			},
			{
				headerName: "Loan Amount",
				field: "finance_offer.loan_amount",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.finance_offer?.loan_amount} />
				},
			},
			{
				headerName: "Base Commission",
				field: "finance_offer.dsa_comm",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.finance_offer.dsa_comm} />
				},
			},
			{
				headerName: "Plough Back",
				field: "finance_offer.rev_plow",
				filter: "agNumberColumnFilter",
			},
			{
				headerName: "Gross Income",
				field: "finance_offer.gross_income",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.finance_offer.gross_income} />
				},
			},
			{
				headerName: "Actual Payout",
				field: "0",
				filter: false,
				valueFormatter: () => { return ("0") }
			},
			{
				headerName: "Difference",
				field: "0",
				filter: false,
				valueFormatter: () => { return ("0") }
			},
			{
				headerName: "Bank IRR",
				field: "finance_offer.irr_comp",
				filter: "agNumberColumnFilter",
			},
			{
				headerName: "Customer IRR",
				field: "finance_offer.irr_cust",
				filter: "agNumberColumnFilter",
			},
			{
				headerName: "Scheme Discount",
				field: "scheme_offer.approved_amt",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.scheme_offer?.approved_amt} />
				},
			},
			{
				headerName: "Chassis Number",
				field: "booking_model.chassis_no",
			},
		],
	};
	ServerGridConfig.options.paginationPageSize = 5000
	ServerGridConfig.options.cacheBlockSize = 5000
	return (
		<>
			{!checkPrivileges("#54006#") ? (
				<Unauthorized />
			) : (
				<PageHeader
					title={BreadcrumbConfig.FinanceReport.title}
					className="tableAreaSec"
					extra={
						<BreadcrumbComponent items={BreadcrumbConfig.FinanceReport.path} />
					}
				>
					<div className="listCountNew">
						<Form
							form={form}
							id="editPasswordForm"
							layout="vertical"
							className="defaultdatefliter"
						>
							<Form.Item
								name="date_completed">
								<RangePicker
									defaultValue={[moment(fromDate), moment(toDate)]}
									format={dateFormat}
									allowClear={false}

								//name="date_completed"
								//onChange={(date, dateString) => setListCustomDate(dateString)}
								/>
							</Form.Item>
							<Button
								key="1"
								form="editPasswordForm"
								htmlType="submit"
								type="primary"
								onClick={() => {
									if (form.getFieldsValue('date_completed')) {
										setListCustomDate([form.getFieldsValue('date_completed')])

									} else {
										setListCustomDate([moment().subtract(1, "month"), moment().subtract(1, "days")])
									}
								}}
							>
								Filter
							</Button>
						</Form>
						{/* {AUTH.checkPrivileges("#6305#") && ( */}
						<RecordPerPage
							key="2"
							style={{ width: "150px" }}
							defaultValue={per_page + " per page"}
							onChange={setPageSize}
						/>

					</div>
					<div className="ag-theme-alpine grid_wrapper">
						<AgGridReact
							rowHeight={ServerGridConfig.rowHeight}
							headerHeight={ServerGridConfig.headerHeight}
							rowData={list_data}
							modules={AllModules}
							columnDefs={gridOptions.columnDefs}
							defaultColDef={ServerGridConfig.defaultColDef}
							columnTypes={ServerGridConfig.columnTypes}
							overlayNoRowsTemplate={vsmCommon.noRecord}
							onGridReady={setupGrid}
							gridOptions={ServerGridConfig.options}
						/>
					</div>
				</PageHeader>
			)}
		</>
	);
});

export default FinanceReportListComponent;
