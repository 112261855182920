import { Button, Checkbox, Col, Form, Row } from "antd"
import { observer } from "mobx-react"
import InputComponent from "../../../../component/InputComponent"
import { vsmInsuranceSegement } from "../../../../config/messages"
import useStore from "../../../../store"

const FormComponent = observer((props) => {
	const { ManageZFormsStore, InsuranceProductStore, } = useStore();
	const { form, id, handleSubmit, handleChange, modelList, setModelList, selectedmodelList, setselectedmodelList, isView = false } = props;




	const onCheckboxLeftChange = (e, obj) => {
		let currentSelectedModelList = modelList;
		let currentSelectedIndex = currentSelectedModelList.findIndex(x => x.id === obj.id);
		if (currentSelectedIndex !== -1) {
			currentSelectedModelList[currentSelectedIndex]["is_selected"] = e.target.checked;
		}
		setModelList([...currentSelectedModelList]);
	}

	const onCheckboxRightChange = (e, obj) => {
		let currentSelectedModelList = selectedmodelList;
		let currentSelectedIndex = currentSelectedModelList.findIndex(x => x.id === obj.id);
		if (currentSelectedIndex !== -1) {
			currentSelectedModelList[currentSelectedIndex]["is_selected"] = e.target.checked;
		}
		setselectedmodelList([...currentSelectedModelList]);
	}

	const onAddSelected = () => {
		let addSelectedModels = modelList.filter(x => x.is_selected === true);
		let removeSelectedModels = modelList.filter(x => !x.is_selected);
		let currentSelectedList = selectedmodelList;
		addSelectedModels.map(x => delete x.is_selected);
		setselectedmodelList([...currentSelectedList, ...addSelectedModels]);
		setModelList(removeSelectedModels);
	}

	const onRemoveSelected = () => {
		let addSelectedModels = selectedmodelList.filter(x => x.is_selected === true);
		let removeSelectedModels = selectedmodelList.filter(x => !x.is_selected);
		let currentSelectedList = modelList;
		addSelectedModels.map(x => delete x.is_selected);
		setModelList([...currentSelectedList, ...addSelectedModels]);
		setselectedmodelList(removeSelectedModels);
	}


	const viewLeftSection = () => {
		return modelList?.map((obj) => {
			return (
				<tr>
					<td>
						<Checkbox
							checked={obj.is_selected}
							onChange={(e) => {
								handleChange();
								onCheckboxLeftChange(e, obj);
							}}
						/>
					</td>
					<td className="pl-10">{obj.brand ? obj.brand.name : ""} - {obj.name}</td>
				</tr>
			)
		})
	}

	const viewRightSection = () => {
		return selectedmodelList?.map((obj) => {
			return (
				<tr>
					<td>
						<Checkbox
							checked={obj.is_selected}
							onChange={(e) => {
								handleChange();
								onCheckboxRightChange(e, obj);
							}}
						/>
					</td>
					<td className="pl-10">{obj.brand ? obj.brand.name : ""} - {obj.name}</td>
				</tr>
			)
		})
	}

	const viewModelsSection = () => {
		return selectedmodelList?.map((obj) => {
			return (
				<Col xl={6}>
					<div className="modelBorderText">{obj.brand ? obj.brand.name : ""} - {obj.name}</div>
				</Col>)
		})
	}

	return (
		<Form
			form={form}
			id={id}
			onFinish={handleSubmit}
			labelCol={{ span: 24 }}
			onChange={handleChange}
		>
			<Row gutter={24}>
				<Col xs={{ span: 12 }}>
					<InputComponent
						type="select"
						allowClear
						autoComplete="chrome-off"
						label="Company"
						name="company_id"
						required
						disabled={isView}
						rules={vsmInsuranceSegement.validation.company_id}
						placeholder="Company"
						onChange={handleChange}
						options={{
							values: ManageZFormsStore.dropdown_insu_company_list,
							value_key: "id",
							text_key: "name",
							rejected_keys:
								ManageZFormsStore.dropdown_insu_company_list &&
								ManageZFormsStore.dropdown_insu_company_list
									.filter((item) => item?.status === 0)
									.map((item) => item.id)
						}}
					/>
				</Col>
				<Col xs={{ span: 12 }}>
					<InputComponent
						type="select"
						allowClear
						autoComplete="chrome-off"
						label="Segment"
						name="segment_id"
						required
						disabled={isView}
						rules={vsmInsuranceSegement.validation.segment_id}
						placeholder="Segment"
						onChange={handleChange}
						options={{
							values: InsuranceProductStore.dropdown_segment_list,
							value_key: "id",
							text_key: "name",
							rejected_keys:
								InsuranceProductStore.dropdown_segment_list &&
								InsuranceProductStore.dropdown_segment_list
									.filter((item) => item?.status === 0)
									.map((item) => item.id)
						}}
					/>
				</Col>
			</Row>
			{isView ?
				<>
					<Row gutter={24}>
						<Col xs={{ span: 24 }}>
							Models
						</Col>
						{viewModelsSection()}
					</Row>
				</>

				:
				<>
					<Row gutter={24}>
						<Col xs={{ span: 8 }}>

							<Row gutter={24}>
								<Col xs={{ span: 24 }}>
									Models
								</Col>
								<Col xs={{ span: 24 }}>
									<table >
										{viewLeftSection()}
									</table>
								</Col>
							</Row>
						</Col>
						<Col xs={{ span: 8 }}>
							<Row gutter={24}>
								<Col xs={{ span: 24 }}>
									<Button
										key="1"
										style={{ width: '183px' }}
										type="primary"
										className="mb-15"
										onClick={() => {
											onAddSelected();
										}}
									>
										Add Selected
									</Button>
								</Col>
								<Col xs={{ span: 24 }}>
									<Button
										key="2"
										danger
										type="primary"
										onClick={() => {
											onRemoveSelected();
										}}
									>
										Remove Selected
									</Button>
								</Col>
							</Row>
						</Col>
						<Col xs={{ span: 8 }}>
							<Row gutter={24}>
								<Col xs={{ span: 24 }}>
									Selected	Models
								</Col>
								<Col xs={{ span: 24 }}>
									<table >
										{viewRightSection()}
									</table>
								</Col>
							</Row>
						</Col>
					</Row>
				</>
			}
		</Form>
	)
})

export default FormComponent;
