import ServerGridConfig from "../../../config/ServerGridConfig";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import useStore from "../../../store";
import { observer } from "mobx-react";
import { vsmCommon } from "../../../config/messages";
import { CurrencyFormat, DateComparator, default_roles } from "../../../utils/GlobalFunction";
import Unauthorized from "../../commonpage/Unauthorized";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import { PageHeader} from "antd";


const HistoryListComponent = observer((props) => {
	const {
		SalesReportStore: { list_data, setupGrid, setPageSize, per_page,},
		AUTH: { checkPrivileges },
		AUTH
	} = useStore();

	

	const gridOptions = {
		columnDefs: [
			{
				headerName: "# ID",
				field: "srno",
				filter: "agNumberColumnFilter",
				pinned: "left",
				minWidth: 120,
				width: 120,
			},
			{
				headerName: "Invoice Date",
				field: "inv_date",
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator,
					browserDatePicker: true
				},
			},
			{
				headerName: "Invoice Number",
				field: "inv_no",
				filter: "agNumberColumnFilter",
			},
			{
				headerName: "CO NO",
				field: "co_no",
				valueGetter: (params) => (params.data && params.data.co_no) ? params.data.co_no : "N/A"
			},
			{
				headerName: "Customer Name",
				field: "booking_customer.full_name",
				valueGetter: (params) => (params.data && params.data?.booking_customer?.changed_name) ? params.data?.booking_customer?.changed_name : params.data?.booking_customer?.full_name
			},
			{
				headerName: "Chassis No",
				field: "booking_model.chassis_no",
				valueGetter: (params) => (params.data && params.data.booking_model) ? params.data.booking_model?.chassis_no : "N/A",
			},
			{
				headerName: "Model",
				field: "booking_model.model.name",
				valueGetter: (params) => (params.data && params.data.booking_model) ? params.data.booking_model?.model?.name : "N/A",
			},
			{
				headerName: "Variant",
				field: "booking_model.variant.name",
				valueGetter: (params) => (params.data && params.data.booking_model) ? params.data.booking_model?.variant?.name : "N/A",
			},
			{
				headerName: "Color",
				field: "booking_model.color.name",
				valueGetter: (params) => (params.data && params.data.booking_model) ? params.data.booking_model?.color?.name : "N/A",
			},
			{
				headerName: "Sales Consultant",
				field: "sales_consultant.name",
				valueGetter: (params) => (params.data && params.data.sales_consultant) ? params.data.sales_consultant?.name : "N/A",
			},
			{
				headerName: "Sales Value",
				field: "booking_ledger.on_road_price",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger.on_road_price} />
				},
			},
			{
				headerName: "Cash Discount",
				field: "booking_ledger.excess_disc",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger.excess_disc} />
				},
			},
			{
				headerName: "Corporate Discount",
				field: "booking_ledger.corporate_offer.approved_amt",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger?.corporate_offer.approved_amt} />
				},
			},
			{
				headerName: "Location Name",
				field: "location.name",
				valueGetter: (params) => (params.data && params.data.location) ? params.data.location?.name : "N/A",
			},
			{
				headerName: "Expected Delivery Date",
				field: "booking_model.promised_delivery_date",
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator,
					browserDatePicker: true
				},
			},
			{
				headerName: "Ex-Showroom Price",
				field: "booking_ledger.ex_showroom",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger?.ex_showroom} />
				},
			},
			{
				headerName: "Ex-Showroom Price AD",
				field: "booking_ledger.ex_showroom_discounted",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger?.ex_showroom_discounted} />
				},
			},
			{
				headerName: "RTO Amount",
				field: "booking_ledger.rto_offer.rto_tax_discounted",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger?.rto_offer.rto_tax_discounted} />
				},
			},
			{
				headerName: "Total Insurance Amount",
				field: "booking_ledger.insurance_offer.ins_premium_discounted",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger?.insurance_offer.ins_premium_discounted} />
				},
			},
			{
				headerName: "Accessory Before Discount",
				field: "booking_ledger.acc_offer.sub_total",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger.acc_offer.sub_total} />
				},
			},
			{
				headerName: "Accessory After Discount",
				field: "booking_ledger.acc_offer.total_amt",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger?.acc_offer.total_amt} />
				},
			},
			{
				headerName: "Accessory Discount",
				field: "booking_ledger.acc_offer.total_disc",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data?.booking_ledger?.acc_offer?.total_disc} />
				},
			},
			{
				headerName: "Excess Discount Amount",
				field: "booking_ledger.excess_disc",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger?.cash_discount} />
				},
			},
			{
				headerName: "Muni Tax",
				field: "booking_ledger.muni_tax_discounted",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger?.muni_tax_discounted} />
				},
			},
			{
				headerName: "TCS",
				field: "booking_ledger.tcs_discounted",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger.tcs_discounted} />
				},
			},
			{
				headerName: "L&H",
				field: "booking_ledger.hc_discounted",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger.hc_discounted} />
				},
			},
			{
				headerName: "PMS",
				field: "booking_ledger.pms_discounted",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger.pms_discounted} />
				},
			},
			{
				headerName: "FASTag",
				field: "booking_ledger.fastag_discounted",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger.fastag_discounted} />
				},
			},
			{
				headerName: "Extended Warrenty",
				field: "booking_ledger.ew_discounted",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger.ew_discounted} />
				},
			},
			{
				headerName: "Balance Amount",
				field: "booking_ledger.balance",
				filter: "agNumberColumnFilter",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger.balance} />
				},
			},
			{
				headerName: "Finance Type",
				field: "booking_ledger.finance_offer.loan_source.name",
				valueGetter: (params) => (params.data && params.data.booking_ledger.finance_offer?.loan_source) ? params.data.booking_ledger.finance_offer.loan_source.name : "N/A",
			},
			{
				headerName: "Fin. Bank Name",
				field: "booking_ledger.finance_offer.bank.name",
				valueGetter: (params) => (params.data && params.data.booking_ledger.finance_offer?.bank) ? params.data.booking_ledger.finance_offer.bank.name : "N/A",
			},
			{
				headerName: "DSA Name",
				field: "booking_ledger.finance_offer.dsa.name",
				valueGetter: (params) => (params.data && params.data.booking_ledger.finance_offer?.dsa) ? params.data.booking_ledger.finance_offer.dsa.name : "N/A",
			},
			{
				headerName: "Insurance",
				field: "booking_ledger.insurance_offer.need_insurance",
				filter: "agSetColumnFilter",
				filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						params.success(["Yes", "No"])
					}
				}
			},
			{
				headerName: "Insurance Company Name",
				field: "booking_ledger.insurance_offer.ins_company.name",
				valueGetter: (params) => (params.data && params.data.booking_ledger?.insurance_offer?.ins_company) ? params.data.booking_ledger?.insurance_offer?.ins_company?.name : "N/A",
			},
			{
				headerName: "Mobile Nos.",
				field: "booking_customer.phone1",
				hide: [default_roles.admin].includes(AUTH?.user?.role_id) ? false : true,
				valueGetter: (params) => (params.data && params.data.booking_customer?.phone1) ? params.data.booking_customer?.phone1 : "N/A",
			},
			{
				headerName: "Email ID",
				field: "booking_customer.email",
				hide: [default_roles.admin].includes(AUTH?.user?.role_id) ? false : true,
				valueGetter: (params) => (params.data && params.data.booking_customer?.email) ? params.data.booking_customer?.email : "N/A",
			},
		],
	};
	ServerGridConfig.options.paginationPageSize = 5000
	ServerGridConfig.options.cacheBlockSize = 5000
	return (
		<>
			{!checkPrivileges("#53009#") ? (
				<Unauthorized />
			) : (
				<PageHeader
					title={BreadcrumbConfig.SalesReport.title}
					className="tableAreaSec"
					extra={
						<BreadcrumbComponent items={BreadcrumbConfig.SalesReport.path} />
					}
				>
					<div className="listCountNew">						
						<RecordPerPage
							key="2"
							style={{ width: "150px" }}
							defaultValue={per_page + " per page"}
							onChange={setPageSize}
						/>

					</div>
					<div className="ag-theme-alpine grid_wrapper">
						<AgGridReact
							rowHeight={ServerGridConfig.rowHeight}
							headerHeight={ServerGridConfig.headerHeight}
							rowData={list_data}
							modules={AllModules}
							columnDefs={gridOptions.columnDefs}
							defaultColDef={ServerGridConfig.defaultColDef}
							columnTypes={ServerGridConfig.columnTypes}
							overlayNoRowsTemplate={vsmCommon.noRecord}
							onGridReady={setupGrid}
							gridOptions={ServerGridConfig.options}
						/>
					</div>
				</PageHeader>
			)}
		</>
	);
});

export default HistoryListComponent;
