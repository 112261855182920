import { Card, Col, Empty, Row } from "antd";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
// import RecordPerPage from "../../component/RecordPerPage";
import useStore from "../../store";
// import ListComponent from "./ListComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

const GeneralReports = observer((props) => {
	const { AUTH: { reportsMenu, checkPrivileges } } = useStore();
	let history = useHistory()

	// const redirectTo = () => {
	// 	history.push('/reports/details');
	// }

	const viewReports = () => {
		return reportsMenu?.submenu?.map((obj, index) => {
			let modalName = obj.id;
			if (modalName === 53001) {
				return (<Col key={index} xs={{ span: 24 }} xxl={{ span: 12 }}>
					<Card className="mb-30 reportWrap" title={obj.name}>
						{checkPrivileges("#53006#") &&
							(<div className="reportInner" onClick={() => { history.push('/sales/booking-sales-receipt') }}>
								<div className="reportIcon"><FontAwesomeIcon icon={faFileDownload} /></div>
								<p>Receipt Report
								</p>
							</div>)
						}
						{checkPrivileges("#53007#") &&
							(<div className="reportInner" onClick={() => { history.push('/sales/finance-pipeline-receipt') }}>
								<div className="reportIcon"><FontAwesomeIcon icon={faFileDownload} /></div>
								<p>Finance Pipeline Summary</p>
							</div>)
						}
						{checkPrivileges("#53008#") &&
						(<div className="reportInner" onClick={() => { history.push('/sales/finance-pipeline-detail') }}>
							<div className="reportIcon"><FontAwesomeIcon icon={faFileDownload} /></div>
							<p>Finance Pipeline Report Detail</p>
						</div>)
						}
						{checkPrivileges("#53009#") &&
						(<div className="reportInner" onClick={() => { history.push('/sales/sales-report') }}>
							<div className="reportIcon"><FontAwesomeIcon icon={faFileDownload} /></div>
							<p>Sales Report</p>
						</div>)}
						{checkPrivileges("#53010#") &&
						(<div className="reportInner" onClick={() => { history.push('/sales/corporate-report') }}>
							<div className="reportIcon"><FontAwesomeIcon icon={faFileDownload} /></div>
							<p>Corporate Report</p>
						</div>)}
					</Card>
				</Col>)
			}
			else if (modalName === 54001) {
				return (<Col key={index} xs={{ span: 24 }} xxl={{ span: 12 }}>
					<Card className="mb-30 reportWrap" title={obj.name}>
					{checkPrivileges("#54006#") &&
						(<div className="reportInner" onClick={() => { history.push('/finance/finance-report') }}>
							<div className="reportIcon"><FontAwesomeIcon icon={faFileDownload} /></div>
							<p>Finance Report</p>
						</div>)}
						{checkPrivileges("#54007#") &&
						(<div className="reportInner" onClick={() => { history.push('/finance/dsa-report') }}>
							<div className="reportIcon"><FontAwesomeIcon icon={faFileDownload} /></div>
							<p>DSA Report</p>
						</div>)}
					</Card>
				</Col>)
			}
			else if (modalName === 52001) {
				return (<Col key={index} xs={{ span: 24 }
				} xxl={{ span: 12 }
				}>
					<Card className="mb-30 reportWrap" title={obj.name}>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="m-0" />
					</Card>
				</Col >)
			}
			// break;
			else if (modalName === 55001) {
				return (<Col key={index} xs={{ span: 24 }} xxl={{ span: 12 }}>
					<Card className="mb-30 reportWrap" title={obj.name}>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="m-0" />
					</Card>
				</Col>)
			}
			// break;
			else if (modalName === 51001) {
				return (<Col key={index} xs={{ span: 24 }} xxl={{ span: 12 }}>
					<Card className="mb-30 reportWrap" title={obj.name}>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="m-0" />
					</Card>
				</Col>)
			}
			return null;

		})
	}
	return (
		<>
			<Row gutter={30}>{viewReports()}</Row>
			{/* <Card className="mb-30" title="User">User</Card>
			<Card title="Inquiries">Inquiries</Card> */}
		</>
	);
});

export default GeneralReports;
