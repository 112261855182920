import LocalGridConfig from "../../../../config/LocalGridConfig";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { Button, Switch } from "antd";
import { vsmCommon } from "../../../../config/messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faPencilAlt,
	faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { CurrencyFormat, DateComparator } from "../../../../utils/GlobalFunction";

const ActionRenderer = observer((props) => {
	const { AUTH } = useStore();
	const {
		openEditModal,
		openDeleteModal,
		openViewModal,
		openDateExtendModal
	} = props.agGridReact.props.frameworkComponents;

	return (
		<div className="action-column">
			{AUTH.checkPrivileges("#6270#") && (
				<Button
					type="text"
					title={"View"}
					className="viewIcon mr-15"
					size="large"
					style={{ padding: 7 }}
					onClick={() => {
						openViewModal(props.data);
					}}
				>
					<FontAwesomeIcon icon={faEye} />
				</Button>
			)}
			{AUTH.checkPrivileges("#6260#") && (
				<Button
					type="text"
					title={"Edit"}
					className="editIcon mr-15"
					size="large"
					style={{ padding: 7 }}
					disabled={props.data.is_used === 1 ? true : false}
					onClick={() => {
						openEditModal(props.data);
					}}
				>
					<FontAwesomeIcon icon={faPencilAlt} />
				</Button>
			)}
			{AUTH.checkPrivileges("#6255#") && (
				<Button
					type="text"
					title={"Date Extend"}
					className="resignIcon  mr-15"
					size="large"
					style={{ padding: 7 }}
					onClick={() => {
						openDateExtendModal(props.data);
					}}
				>
					<svg id="Layer_1" class="svg-inline--fa fa-pencil-alt fa-w-17 " width="17" fill="#d72ddf" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15"><path d="M16,1.78V13.22c0,.13-.06.26-.1.39a1.88,1.88,0,0,1-1.84,1.26c-4,0-8.08,0-12.12,0a.56.56,0,0,1-.13,0,1.88,1.88,0,0,1-1.43-.74A2.16,2.16,0,0,1,0,13.22V1.78c0-.13.06-.26.1-.39A1.88,1.88,0,0,1,1.94.13c4,0,8.08,0,12.12,0a.56.56,0,0,1,.13,0,1.88,1.88,0,0,1,1.43.74A2.16,2.16,0,0,1,16,1.78ZM1.25,5.13v7.76a.64.64,0,0,0,.73.73q6,0,12,0a.64.64,0,0,0,.73-.73V5.13Zm13.5-1.26V2.07a.63.63,0,0,0-.69-.69H1.94a.63.63,0,0,0-.69.69V3.74s0,.09,0,.13Z"/><path d="M10.61,7.46,10,6.85,10.84,6,13,8.11l-2.15,2.16L10,9.41l.67-.66H7.35V7.51h3.24Z"/><path d="M5.18,8.48,6,9.33,5.38,10l0,.05H8.59v1.24H5.39l.66.64-.89.89L3,10.64Z"/><path d="M3.44,2.62A.63.63,0,1,1,2.8,2,.63.63,0,0,1,3.44,2.62Z"/><path d="M5,3.25a.63.63,0,0,1-.62-.63A.63.63,0,0,1,5,2,.63.63,0,0,1,5,3.25Z"/></svg>
				</Button>
			)}
			{AUTH.checkPrivileges("#6275#") && (
				<Button
					type="text"
					title={"Delete"}
					className="deleteIcon"
					size="large"
					style={{ padding: 7 }}
					disabled={props.data.is_used === 1 ? true : false}
					onClick={() => {
						openDeleteModal(props.data);
					}}
				>
					<FontAwesomeIcon icon={faTrashAlt} />
				</Button>
			)}
		</div>
	);
});

const ListComponent = observer((props) => {
	const {
		openEditModal,
		openDeleteModal,
		openViewModal,
		openDateExtendModal,
		onSwitchChange,
	} = props;
	const {
		ManageSchemeStore: { list_data, setupGrid, onFilterChanged },
		AUTH,
	} = useStore();

	const gridOptions = {
		columnDefs: [
			{
				headerName: "# ID",
				field: "srno",
				filter: "agNumberColumnFilter",
				pinned: "left",
				minWidth: 120,
				width: 120,
			},
			{
				headerName: "From Date",
				field: "from_date_changed",
				filter: 'agDateColumnFilter',
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator
				}
			},
			{
				headerName: "To Date",
				field: "to_date_changed",
				filter: 'agDateColumnFilter',
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator
				}
			},
			{
				headerName: "Brand Name",
				field: "brand.name",
			},
			{
				headerName: "Model Name",
				field: "model.name",
			},
			{
				headerName: "Variant Name",
				field: "variant.name",
			},
			{
				headerName: "Ex-showroom Price",
				field: "ex_showroom",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.ex_showroom} />
				},
			},
			{
				headerName: "Prev. year discount",
				field: "prev_year_disc",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.prev_year_disc} />
				},
			},
			{
				headerName: "Curr. year discount",
				field: "cur_year_disc",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.cur_year_disc} />
				},
			},
			{
				headerName: "Active",
				field: "status_name",
				filter: "agSetColumnFilter",
				filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						params.success(["Yes", "No"])
					}
				},
				hide: AUTH.checkPrivileges("#6265#") ? false : true,
				cellRendererFramework: function (data) {
					const { onSwitchChangeGrid } = data.agGridReact.props;
					return (
						<Switch
							defaultChecked={data.data.status}
							onChange={(val) => onSwitchChangeGrid(val, data.data)}
						/>
					);
				},
			},
			{
				headerName: "Actions",
				field: "actions",
				type: "actionColumn",
				filter: false,
				sortable: false,
				pinned: "right",
				minWidth: 180,
				width: 180,
			},
		],
	};

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowHeight={LocalGridConfig.rowHeight}
				headerHeight={LocalGridConfig.headerHeight}
				rowData={list_data}
				modules={AllModules}
				columnDefs={gridOptions.columnDefs}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer,
					openEditModal,
					openDeleteModal,
					openViewModal,
					openDateExtendModal
				}}
				onGridReady={setupGrid}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={onFilterChanged}
				onSortChanged={onFilterChanged}
				onSwitchChangeGrid={onSwitchChange}
			/>
		</div>
	);
});

export default ListComponent;
