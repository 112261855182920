import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "antd";
import useStore from "../../../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { vsmNotify, vsmOnroadPriceCalculator } from "../../../../../../config/messages";
import InputComponent from "../../../../../../component/InputComponent";
import debounce from "lodash/debounce";
import { CurrencyFormat } from "../../../../../../utils/GlobalFunction";

const OnroadPriceCalculatorComponent = observer((props) => {
	const [form] = Form.useForm();
	const
		{ ManageZFormsStore,
			ManageZFormsStore: { OnroadPriceCalculator }
		} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [exshowroom, setExshowroom] = useState(0);
	const [finalOnroadPrice, setfinalOnroadPrice] = useState(0);

	useEffect(() => {
		if (props.visible && ManageZFormsStore.viewValues) {
			setExshowroom(ManageZFormsStore.viewValues.booking_ledger.ex_showroom)
		}
	}, [form, props, ManageZFormsStore, ManageZFormsStore.viewValues])

	const handleSubmit = (data) => {
		setSaving(true);
		data.booking_id = ManageZFormsStore.viewValues.id;
		OnroadPriceCalculator(data)
			.then((data) => {
				setfinalOnroadPrice(data.on_road_price)
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};


	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);

	// reset form and close add form
	const close = () => {
		props.close();
		setExshowroom(0)
		setfinalOnroadPrice(0)
		form.resetFields();
		setDisabled(true);
	};
	return ManageZFormsStore.viewValues ? (
		<Modal
			className="addModal"
			centered
			title="Onroad Price Calculator"
			width={534}
			zIndex={1005}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Close
				</Button>,
				<Button
					key="1"
					form="revertSchemeForm"
					disabled={disabled}
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Calculate
				</Button>,
			]}
		>
			<Form form={form} className="calc_popup_design" id="revertSchemeForm" onFinish={handleSubmit}>
				<Row gutter={30}>
					<Col xs={{ span: 24 }}>
						<div className="scheme_discount">
							<div className="scheme_discount_text">
								<p>Ex-Showroom</p>
							</div>
							<div className="scheme_discount_digit">
								<p><CurrencyFormat value={exshowroom} /></p>
							</div>
						</div>
					</Col>
					<Col xs={{ span: 24 }}>
						<div className="scheme_discount">
							<InputComponent
								type="text"
								required
								label="Scheme Amount"
								placeholder="Scheme Amount"
								name="scheme_amount"
								onChange={handleChange}
								rules={vsmOnroadPriceCalculator.validation.scheme_amount}
							/>
						</div>
					</Col>
					<Col xs={{ span: 24 }}>
						<div className="scheme_discount scheme_discount_final">
							<div className="scheme_discount_text">
								<p>Final Onroad Price</p>
							</div>
							<div className="scheme_discount_digit ">
								<p><CurrencyFormat value={finalOnroadPrice} /></p>
							</div>
						</div>
					</Col>
				</Row>
			</Form>
		</Modal>
	) : null
});

export default OnroadPriceCalculatorComponent;
