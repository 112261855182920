import React, { useEffect, useState } from "react";
import { Form, Button, Drawer } from "antd";
import { vsmNotify } from "../../../../config/messages";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash/debounce";
import FormComponent from "./FormComponent";

const ViewComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageZFormsStore,
		InsuranceProductStore,
		ManageInsuranceSegmentStore,
		ManageInsuranceSegmentStore: { EditData, editValues, getInsuranceSegmentDetail, ins_segment_detail },
		InsuranceProductStore: { getModelListByBrand },
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [fetchModel, setFetchModel] = useState(true);

	const [fetchCompnay, setFetchCompnay] = useState(true);
	const [fetchSegment, setFetchSegment] = useState(true);

	const [modelList, setModelList] = useState([]);
	const [selectedmodelList, setselectedmodelList] = useState([]);

	useEffect(() => {
		if (fetchModel && props.visible) {
			getModelListByBrand({}).then((data) => {
				setModelList(data.list.data);
			})
			setFetchModel(false)
		}
	}, [getModelListByBrand, fetchModel, props.visible])


	useEffect(() => {
		if (fetchCompnay && props.visible) {
			ManageZFormsStore.getInsuranceCompanyList().then(() => setFetchCompnay(false))
		}
	}, [ManageZFormsStore, fetchCompnay, props.visible])


	useEffect(() => {
		if (fetchSegment && props.visible) {
			InsuranceProductStore.getSegmentsList().then(() => setFetchSegment(false))
		}
	}, [InsuranceProductStore, fetchSegment, props.visible])

	useEffect(() => {
		if (editValues && props.visible && !ins_segment_detail && !fetchModel) {
			getInsuranceSegmentDetail(editValues.id).then((data) => {

				form.setFieldsValue({
					company_id: data.company_id,
					segment_id: data.segment_id,
				})

				let selectedModels = data.model_ids;
				if (selectedModels) {
					selectedModels = selectedModels.split(",");
				}

				let filteredData = InsuranceProductStore.dropdown_model_list;
				let selectedData = [];
				selectedModels.map((obj) => {
					filteredData = filteredData.filter(x => x.id !== Number(obj));
					let tempData = InsuranceProductStore.dropdown_model_list.findIndex(x => x.id === Number(obj));

					if (tempData !== -1) {
						selectedData.push(InsuranceProductStore.dropdown_model_list[tempData]);
					}
					return null
				});

				setselectedmodelList(selectedData);
				setModelList(filteredData);

			})
		}
	}, [editValues, fetchModel, form, ins_segment_detail, InsuranceProductStore, getInsuranceSegmentDetail, props.visible])


	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		data.model_ids = selectedmodelList.map((obj) => obj.id);
		data.id = ins_segment_detail.segment_ins?.id;
		setSaving(true);
		EditData(data)
			.then((data) => {
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
		ManageInsuranceSegmentStore.ins_segment_detail = null;
		ManageInsuranceSegmentStore.editValues = null;
		setModelList([]);
		setselectedmodelList([]);
		setFetchModel(true);
		setDisabled(true);
	};



	return (
		<Drawer
			className="addModal"
			title="View Segment for Insurance"
			width="70%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					form="viewInsuranceSegmentForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
			]}
		>
			<FormComponent
				form={form}
				id="viewInsuranceSegmentForm"
				selectedmodelList={selectedmodelList}
				modelList={modelList}
				isView={true}
				setModelList={setModelList}
				setselectedmodelList={setselectedmodelList}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
			/>
		</Drawer>
	);
});

export default ViewComponent;
