import ServerGridConfig from "../../../config/ServerGridConfig";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import useStore from "../../../store";
import { observer } from "mobx-react";
import { vsmCommon } from "../../../config/messages";
import { CurrencyFormat } from "../../../utils/GlobalFunction";
import Unauthorized from "../../commonpage/Unauthorized";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import { PageHeader } from "antd";

const HistoryListComponent = observer((props) => {
	const {
		FinancePipelineDetailStore: { list_data, setupGrid, setPageSize, per_page },
		AUTH: { checkPrivileges },
	} = useStore();
	
	const gridOptions = {
		columnDefs: [
			{
				headerName: "# ID",
				field: "srno",
				filter: "agNumberColumnFilter",
				pinned: "left",
				minWidth: 120,
				width: 120,
			},
			{
				headerName: "Location Name",
				field: "location.name",
				valueGetter: (params) => (params.data && params.data.location) ? params.data.location?.name : "N/A",
			},
			{
				headerName: "SC Name",
				field: "sales_consultant.name",
				valueGetter: (params) => (params.data && params.data.sales_consultant) ? params.data.sales_consultant?.name : "N/A",
			},
			{
				headerName: "Customer Name",
				field: "booking_customer.full_name",
				valueGetter: (params) => (params.data && params.data.booking_customer.changed_name) ? params.data.booking_customer.changed_name : params.data.booking_customer?.full_name
			},
			{
				headerName: "Model",
				field: "booking_model.model.name",
				valueGetter: (params) => (params.data && params.data.booking_model) ? params.data.booking_model?.model?.name : "N/A",
			},
			{
				headerName: "Variant",
				field: "booking_model.variant.name",
				valueGetter: (params) => (params.data && params.data.booking_model) ? params.data.booking_model?.variant?.name : "N/A",
			},
			{
				headerName: "Color",
				field: "booking_model.color.name",
				valueGetter: (params) => (params.data && params.data.booking_model) ? params.data.booking_model?.color?.name : "N/A",
			},
			{
				headerName: "Chassis No",
				field: "booking_model.chassis_no",
				valueGetter: (params) => (params.data && params.data.booking_model) ? params.data.booking_model?.chassis_no : "N/A",
			},
			{
				headerName: "Order No",
				field: "co_no",
			},
			{
				headerName: "Order Days",
				field: "order_days",
				filter: "agNumberColumnFilter",
			},
			{
				headerName: "Deal Value",
				field: "booking_ledger.on_road_price",
				filter: "agNumberColumnFilter",
				valueGetter: (params) => (params.data && params.data.booking_ledger) ? params.data.booking_ledger?.on_road_price : "N/A",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger?.on_road_price} />
				},
			},
			{
				headerName: "Amount Received",
				field: "booking_ledger.total_credits",
				filter: "agNumberColumnFilter",
				valueGetter: (params) => (params.data && params.data.booking_ledger) ? params.data.booking_ledger?.total_credits : "N/A",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger?.total_credits} />
				}
			},
			{
				headerName: "Amount Due",
				field: "booking_ledger.due",
				filter: "agNumberColumnFilter",
				valueGetter: (params) => (params.data && params.data.booking_ledger) ? params.data.booking_ledger?.due : "N/A",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.booking_ledger?.due} />
				}
			},
			{
				headerName: "Ghost",
				field: "is_ghost",
				filter: "agSetColumnFilter",
				filterParams: {
					defaultToNothingSelected: false,
					values: (params) => {
						params.success(["Yes", "No"])
					}
				}
			},
			
		],
	};
	ServerGridConfig.options.paginationPageSize = 5000
	ServerGridConfig.options.cacheBlockSize = 5000
	return (
		<>
			{!checkPrivileges("#53008#") ? (
				<Unauthorized />
			) : (
				<PageHeader
					title={BreadcrumbConfig.FinancePipelineDetailsReportRequest.title}
					className="tableAreaSec"
					extra={
						<BreadcrumbComponent items={BreadcrumbConfig.FinancePipelineDetailsReportRequest.path} />
					}
				>
					<div className="listCountNew">
						{/* {AUTH.checkPrivileges("#6305#") && ( */}
						<RecordPerPage
							key="2"
							style={{ width: "150px" }}
							defaultValue={per_page + " per page"}
							onChange={setPageSize}
						/>
					</div>
					<div className="ag-theme-alpine grid_wrapper">
						<AgGridReact
							rowHeight={ServerGridConfig.rowHeight}
							headerHeight={ServerGridConfig.headerHeight}
							rowData={list_data}
							modules={AllModules}
							columnDefs={gridOptions.columnDefs}
							defaultColDef={ServerGridConfig.defaultColDef}
							columnTypes={ServerGridConfig.columnTypes}
							overlayNoRowsTemplate={vsmCommon.noRecord}
							onGridReady={setupGrid}
							gridOptions={ServerGridConfig.options}
						/>
					</div>
				</PageHeader>
			)}
		</>
	);
});

export default HistoryListComponent;
