import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Drawer } from "antd";
import { observer } from "mobx-react";
import { vsmNotify } from "../../../../config/messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../../store";
import InsuranceProductFormComponent from "./InsuranceProductFormComponent";
import { debounce } from "@syncfusion/ej2-base";

const EditComponent = observer((props) => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState();
	const [catID, setcatID] = useState(null)
	const {
		InsuranceProductStore
	} = useStore();

	const [disabled, setDisabled] = useState(true);
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		data.id = InsuranceProductStore.editValues.id;
		if (!data.product_base) {
			data.product_base = 10
		}
		InsuranceProductStore.EditData(data).then((data) => {
			vsmNotify.success({
				message: data.STATUS.NOTIFICATION[0],
			});
			close();
		})
			.catch((e) => {
				if (e.errors) {
					// console.log("error......", e);
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
		forceUpdate();
	}, 500);



	// reset form and close add form
	const close = () => {
		form.resetFields();
		setDisabled(true);
		props.close();
	};

	useEffect(() => {
		if (props.visible) {
			InsuranceProductStore.getEditDetails(InsuranceProductStore.editValues.id).then(data => {
				form.setFieldsValue({
					name: data.product.name,
					brand_id: data.product.brand_id,
					model_id: data.product.model_id,
					passing_type_id: data.product.passing_type_id,
					segment_id: data.product.segment_id,
					zone_id: data.product.zone_id,
					company_id: data.product.company_id,
					cat_id: data.product.cat_id,
					fixed_amt: data.product.fixed_amt,
					anti_theft_per: data.product.anti_theft_per,
					cpa: data.product.cpa,
					cpa_3: data.product.cpa_3,
					pad: data.product.pad,
					pap: data.product.pap,
					product_base: data.product.product_base,
				});
				InsuranceProductStore.dropdown_ins_segment_list = data.product?.segment ? [data.product.segment] : [];
				setcatID(data.product.cat_id);
				if (data.product.brand_id) {
					const formData = { brand_id: data.product.brand_id };
					InsuranceProductStore.getModelListByBrand(formData).then(res => {

						let segmentObj = InsuranceProductStore.dropdown_model_list.filter(x => x.id === data.product.model_id)[0];
						form.setFieldsValue({
							segment_name: segmentObj?.segment?.name
						})
					});
				}
			});
		}
	}, [InsuranceProductStore, props, form])
	return (
		<Drawer
			className="editModal"
			title={`Edit Insurance Product`}
			width="80%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					form="editInsuranceProduct"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
			]}
		>
			<InsuranceProductFormComponent
				form={form}
				handleChange={handleChange}
				setDisabled={setDisabled}
				catID={catID}
				handleSubmit={handleSubmit}
				id="editInsuranceProduct"
			/>
		</Drawer>
	);
});

export default EditComponent;
