import React
	from "react";
import { Row, Col, Upload, Form, message } from "antd";
import { observer } from "mobx-react";
import { UploadIcon } from "../../../../config/IconsConfig";
import debounce from "lodash/debounce";
import { validFileTypes } from "../../../../utils/GlobalFunction";
import { vsmApplyInsurance } from "../../../../config/messages";

const UploadNCBProof = observer((props) => {

	const { updateFileList, fileList, handleChange, setisImageUploaded, form, fileRequired, setDisabled, isView = false } = props;

	const uploadButton = (
		<div className="upload_btn">
			<UploadIcon />
			<p>Drag files to upload, or browse</p>
		</div>
	);

	const eventProps = {
		onChange: debounce(({ fileList: newFileList }) => {
			updateFileList(newFileList);
			setisImageUploaded(true);
			handleChange();
		}, 500),
		fileList,
		beforeUpload: (file) => {
			const isValid = validFileTypes.includes(file.type);
			if (!isValid) {
				message.error('Please upload valid file');
				setisImageUploaded(false)
				setDisabled(true);
				return true;
			}
			else {
				if (file.type.includes("image")) {
					[file][0]["url"] = URL.createObjectURL(file);
				}
				updateFileList([file]);
				setisImageUploaded(true);
				return false;
			}
		},
	};
	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};

	const onRemoveImage = () => {
		form.setFields([{ name: "ncb_proof", errors: [] }]);
		updateFileList([]);
		setDisabled(true);
		setisImageUploaded(false);
	};


	return (
		<>
			<h2 align="middle" className="document_title">{"NCB Proof"}</h2>
			<div className="upload_verify_sec borderUpload">
				<Row justify="space-between" align="middle">
					<Col xs={{ span: 24 }} sm={{ span: 15 }}>
						<div className="upload_left_sec">
							{/* <p>Choose File To Upload</p> */}
							<ul>
								<li>Maximum file size is 10 MB</li>
								<li>It allows valid image (JPG,JPEG,PNG)</li>
								<li>It allows PDF file</li>
								<li>It allows valid document (TXT,DOC,DOCX)</li>
								<li>It allows valid spreadsheet (XLS,XLSX)</li>
								<li>It allows valid presentation (PPT,PPTX)</li>
							</ul>
						</div>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 9 }} className="upload_right_sec">
						<Form.Item required={fileRequired} name="ncb_proof" rules={fileRequired ? vsmApplyInsurance.validation.ncb_proof : vsmApplyInsurance.validation.ncb_proofNotRequired}>
							<Upload
								accept=".png,.jpeg,.jpg,.gif,.txt,.doc,.docx,.xlx,.xlsx,.ppt,.pptx,.pdf"
								required={fileRequired}
								disabled={isView}
								fileList={fileList}
								maxCount={1}
								onRemove={onRemoveImage}
								onPreview={onPreview}
								listType="picture-card"
								multiple={false}
								showUploadList={true}
								name="ncb_proof"
								{...eventProps}
							>
								{fileList.length >= 1 ? null : uploadButton}
							</Upload>
						</Form.Item>
					</Col>
				</Row>
			</div>

			<Row>
				<Col xs={{ span: 24 }}>
					<h3>Notes:</h3>
					<ul>
						<li className="blueText">Customer name should be match with order booking form and NCB certificate.</li>
						<li className="blueText">NCB transfer is only allow between private car to private car.</li>
						<li className="blueText">Original NCB certificate should be submitted to insurance department before car delivery.</li>
					</ul>
				</Col>
			</Row>
		</>
	)
})

export default UploadNCBProof
