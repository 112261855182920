import React, { useState } from "react";
import { Form, Button, Modal, Col, Row } from "antd";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { vsmNotify, vsmQuotationApproval } from "../../../../../config/messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../../../../component/InputComponent";
import debounce from "lodash/debounce";

const RevertDeliveryComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageZFormsStore,
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true);
		data.id = ManageZFormsStore.revertDeliveryValues.id;
		ManageZFormsStore.RevertDeliveryZForm(data)
			.then((data) => {
				close(true);
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => {
				setSaving(false);
			});
	};
	// check for valid form values then accordingly make save button disable/enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);

	// reset form and close add form
	const close = (reload = false) => {
		props.close(reload);
		form.resetFields();
	};

	return ManageZFormsStore.revertDeliveryValues ? (
		<Modal
			centered
			className="deleteModal"
			title={"Revert"}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={() => close()}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={() => close()}
				>
					No
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					form="revertDeliveryForm"
					loading={saving}
					htmlType="submit"
					type="primary"
					danger
				>
					Yes
				</Button>,
			]}
		>
			<Form form={form} id="revertDeliveryForm" onFinish={handleSubmit} layout="vertical">
				{
					<Row align="middle">

						<Col span={24}>
							<div className="revert_package_sec">
								<ul>
									<li>This case revert back to completed stage.</li>
									<li>Accountant can further initiate refund process, if needed.</li>
									<li>This is irreversible process.</li>
								</ul>
							</div>
						</Col>
						<Col span={24} className="text-center">
							<h3>
								Would you like to continue?
							</h3>
						</Col>
						<Col xs={{ span: 24 }}>
							<InputComponent
								type="textarea"
								label="Remarks"
								placeholder="Remarks"
								name="remarks"
								required
								onChange={handleChange}
								rules={vsmQuotationApproval.validation.remark}
							/>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null;
});

export default RevertDeliveryComponent;
