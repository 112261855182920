import React, { useEffect, useState} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { Form, Button, Row, Col, Drawer } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../../../store";
import { vsmCommon, vsmNotify } from "../../../../../config/messages";
import LocalGridConfig from "../../../../../config/LocalGridConfig";
import { booking_status, CurrencyFormat, DateComparator } from "../../../../../utils/GlobalFunction";

const AddComponent = observer((props) => {
	const [form] = Form.useForm();
	const { ManagePackageDefStore, ManagePackageDefStore: { viewFreeChassisValues, UpdateFreeChassis }, ManagePackagesStore } = useStore();
	const [inwardEntries, setinwardEntries] = useState(null)
	const [disabled, setDisabled] = useState(true);
	// Handle submit and call function to save new record

	useEffect(() => {
		if (props.visible && ManagePackageDefStore.viewFreeChassisValues) {
			ManagePackageDefStore.getFreeChassisList({ brand_id: viewFreeChassisValues.brand_id, model_id: viewFreeChassisValues.model_id, variant_id: viewFreeChassisValues.variant_id, color_ids: viewFreeChassisValues.colors})
		}
		else if (props.visible && ManagePackageDefStore.viewFreeChassisEditValues) {
			ManagePackageDefStore.getFreeChassisList({ id: ManagePackageDefStore.viewFreeChassisEditValues.id, brand_id: ManagePackageDefStore.viewFreeChassisEditValues.brand_id, model_id: ManagePackageDefStore.viewFreeChassisEditValues.model_id, variant_id: ManagePackageDefStore.viewFreeChassisEditValues.variant_id, color_ids: ManagePackageDefStore.viewFreeChassisEditValues.package_colors.map((item) => item.id) })
		}
	}, [ManagePackageDefStore, ManagePackagesStore.viewValues, props.visible, viewFreeChassisValues]);

	// const handleLocationChange = () => {
	// 	ManagePackageDefStore.free_Chassis_list_data = null
	// 	setinwardEntries(null)
	// 	form.setFieldsValue({
	// 		total_record: 0,
	// 		total_amount: 0
	// 	})
	// 	const location_id = form.getFieldValue("location_id")
	// 	if (location_id && location_id !== undefined) {
	// 		const data = { location_id };
	// 		ManagePackageDefStore.getInwardList(data)
	// 	}
	// }

	// check for valid form values then accordingly make save button disable / enable

	const AddChassisNumber = (data) => {
		if (inwardEntries && !ManagePackageDefStore.viewFreeChassisEditValues && ManagePackageDefStore.viewFreeChassisValues) {
			ManagePackageDefStore.setAddChassisValues(inwardEntries)
			close();
		}
		else if (ManagePackageDefStore.viewFreeChassisEditValues && !ManagePackageDefStore.viewFreeChassisValues) {
			data.id = ManagePackageDefStore.viewFreeChassisEditValues.id
			data.chassis_nos = inwardEntries
			UpdateFreeChassis(data)
				.then((data) => {
					close();
					vsmNotify.success({
						message: data.STATUS.NOTIFICATION[0],
					});
				})
				.catch((e) => {
					if (e.errors) {
						form.setFields(e.errors);
					}
				})
		}

	}
	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
		ManagePackageDefStore.get_list_location = null;
		ManagePackageDefStore.free_Chassis_list_data = null;
		ManagePackageDefStore.viewFreeChassisValues = null
		ManagePackageDefStore.viewFreeChassisEditValues = null	};


	  const getRowClass = (params) => {
		if (!!ManagePackageDefStore.allotedChassis.find((item) => item.id === params.data.id)) {
		  return "green-row";
		}	
		return undefined;
	  };

	const gridOptions = {
		columnDefs: [
			{
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true,
				// checkboxSelection: (params) => {
				// 	// return !!params.data && params.data.is_avail_disc === 1;
				// 	// return  true
				// 	return !ManagePackageDefStore.allotedChassis.find((item ) => item.id === params.data.id)
				// },					
				showDisabledCheckboxes: true,				
				filter: false,
				sortable: false,
				floatingFilter: false,
				suppressMenu: true,
				pinned: 'left',
				minWidth: 60,
				width: 60,
			},
			{
				headerName: "# ID",
				field: "srno",
				filter: false,
				maxWidth: 120,
				width: 120,
			},
			{
				headerName: "Chassis Number",
				field: "chassis_no",
			},
			{
				headerName: "Date Inward",
				field: "date_inward_changed",
				filter: 'agDateColumnFilter',
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator
				}
			},
			{
				headerName: "VRN",
				field: "vrn",
			},
			{
				headerName: "Supplier",
				field: "supplier.name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Invoice Number",
				field: "invoice_no",
			},
			{
				headerName: "Invoice Date",
				field: "invoice_date_changed",
				filter: 'agDateColumnFilter',
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: DateComparator
				}
			},
			{
				headerName: "Location",
				field: "location.name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Yard",
				field: "premises.name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Brand",
				field: "brand.name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Model",
				field: "model.name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Variant",
				field: "variant.name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Color",
				field: "color.name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Engine Number",
				field: "engine_no",
			},
			{
				headerName: "Bill Amount",
				field: "bill_amount",
				cellRendererFramework: function (params) {
					return <CurrencyFormat value={params.data.bill_amount} />
				},
			},
			{
				headerName: "Invoice Funding By",
				field: "invoice_funding_by",
			},
			{
				headerName: "Purchase Year",
				field: "purchase_year",
			},
			{
				headerName: "Mfg. Year",
				field: "mfg_year",
			},
			{
				headerName: "VIN Year",
				field: "vin_year",
			},
			{
				headerName: "Vehicle Type",
				field: "vehicle_type.name",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Age",
				field: "age",
			},
			{
				headerName: "Disc. Available?",
				field: "avail_disc",
				filter: "agSetColumnFilter",
			},
			{
				headerName: "Status",
				field: "status",
				filter: "agSetColumnFilter",
				pinned: "right",
				minWidth: 120,
				width: 120,
				valueGetter: (params) => (params.data && params.data.status) ? booking_status[params.data.status] : "N/A",
				filterParams: {
					defaultToNothingSelected: false,
					buttons: ['apply', 'reset'],
					values: (params) => {
						params.success([booking_status[10], booking_status[20], booking_status[22], booking_status[25], booking_status[30], booking_status[40], booking_status[50], booking_status[100]])
					}
				}
			},
		],
	};

	return (
		<Drawer
			className="addModal"
			title="Free Chassis"
			width="80%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					htmlType="button"
					type="primary"
					onClick={AddChassisNumber}
				>
					Apply
				</Button>,
			]}
		>
			<Row>
				<Col xs={{ span: 24 }} className="mb-30">
					{ManagePackageDefStore.free_Chassis_list_data &&
						<div className="ag-theme-alpine grid_wrapper">
							<AgGridReact
								domLayout="autoHeight"
								rowSelection={'multiple'}
								suppressRowClickSelection={true}
								rowHeight={LocalGridConfig.rowHeight}
								headerHeight={LocalGridConfig.headerHeight}
								rowData={ManagePackageDefStore.free_Chassis_list_data}
								modules={AllModules}
								columnDefs={gridOptions.columnDefs}
								defaultColDef={LocalGridConfig.defaultColDef}
								columnTypes={LocalGridConfig.columnTypes}
								overlayNoRowsTemplate={vsmCommon.noRecord}
								frameworkComponents={{}}
								onGridReady={ManagePackageDefStore.setupGrid_FreeChassis}
								gridOptions={LocalGridConfig.options}
								onFilterChanged={ManagePackageDefStore.onFilterChanged_free_chassis}
								onSortChanged={ManagePackageDefStore.onFilterChanged_free_chassis}
								//isRowSelectable={isRowSelectable}
								onFirstDataRendered={ManagePackageDefStore.onFirstDataRendered}
								getRowClass={getRowClass}
								onSelectionChanged={
									function (event) {
										let selected = event.api.getSelectedRows()
										if (selected.length > 0) {
											setinwardEntries(selected.map(item => item.chassis_no))
											setDisabled(false)
										}
										else {
											setinwardEntries(null)
											setDisabled(true)
										}
										return true
									}}
							/>
						</div>
					}
				</Col>
			</Row>
		</Drawer >
	);
});

export default AddComponent;
