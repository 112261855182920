import React from "react";
import { Button, Modal, Col, Row } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ProxyConfirmComponent = observer((props) => {

	const { handleProxy = () => { }, login, setlogin, username } = props;

	const doLogin = () => {
		setlogin(true)
		handleProxy();
	}

	const close = () => {
		props.close();
	}

	return (
		<Modal
			centered
			className="addModal"
			title="Proxy Login"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					No
				</Button>,
				<Button
					key="1"
					loading={login}
					onClick={() => doLogin()}
					htmlType="submit"
					type="primary"
				>
					Yes
				</Button>,
			]}
		>

			<Row align="middle">
				<Col span={24} className="text-center">
					<h3>
						{`Are you sure you want to Login as ${username}`}?
					</h3>
				</Col>
			</Row>
		</Modal>
	)
})

export default ProxyConfirmComponent;
