import { observer } from "mobx-react";
import moment from "moment";
import { useEffect, useState } from "react";
import useStore from "../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { Button, Col, Modal, Row, Select, Spin } from "antd";
import { faChevronLeft, faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;
const InformationComponent = observer((props) => {
	const {
		NotificationStore: {
			getInfoList,
			readInfo,
			changeInfoFilter,
			list_indexObj,
			infoList,
		},
		AUTH: {
			user
		}
	} = useStore();

	const [infoModal, setInfoModal] = useState(false);
	const [infoModalData, setInfoModalData] = useState(null);
	const [dateRange, setdateRange] = useState()
	const [selectType, setselectType] = useState(null);
	const [showSpin, setshowSpin] = useState(true);

	const openInfoModal = (obj) => {
		readInfo(obj.id)
		setInfoModal(true);
		setInfoModalData(obj)
	}


	//  close Info Modal
	const close = () => {
		setInfoModal(false);
		applyFilter(list_indexObj.current_page);
	};

	useEffect(() => {
		getInfoList(changeInfoFilter(1)).then((data) => {
			setshowSpin(false);
		}).catch((erorr) => {
			setshowSpin(false);
		})
	}, [getInfoList, changeInfoFilter])

	const pagination = () => {
		if (list_indexObj) {
			let last_index = list_indexObj.last_page;
			let initial_index = list_indexObj.from_page;
			let paginationArr = [];
			for (let i = initial_index; i <= last_index; i++) {
				paginationArr.push(i);
			}
			return paginationArr.map((obj, key) => {
				return <li key={key} className={list_indexObj.current_page === obj ? "active" : ""} onClick={() => applyFilter(obj)}>{obj}</li>;
			})
		}
	}

	const viewInformationList = () => {
		return infoList.map((obj, index) => {
			let note_substring;
			if (obj.note.length > 290) {
				note_substring = obj.note.substr(0, 290);
				note_substring += "...";
			}
			else {
				note_substring = obj.note
			}
			return (
				<li className={obj.is_read ? "read" : "unRead"} key={index}>
					<div className="infoBlock" onClick={() => { openInfoModal(obj) }}>
						<div className="infoHeader">
							<div className="infoTitle">{obj.title}</div>
							<div className="infoDate"><span><FontAwesomeIcon icon={faCalendarAlt} /></span>{obj.created}</div>
						</div>
						<div className="infoNote" dangerouslySetInnerHTML={{ __html: note_substring }}></div>
					</div>
				</li>
			)
		})
	}

	const handleDatePickerChange = (dates) => {
		setdateRange(dates)
	}

	const onSelectChange = (value) => {
		setselectType(value);
	}

	const applyFilter = (index = 1) => {
		setshowSpin(true);
		let final_filter;
		if (dateRange) {
			let dateFilter = {
				"created": {
					"filterType": "date",
					"date_from": moment(dateRange[0]).format("YYYY-MM-DD"),
					"date_to": moment(dateRange[1]).format("YYYY-MM-DD"),
					"type": "inRange"
				}
			}
			final_filter = dateFilter;
		}
		if (selectType !== null && selectType !== undefined) {
			let flagFilter = {
				value: selectType,
				filterType: "flag"
			}
			final_filter = { ...final_filter, is_read: flagFilter }
		}
		getInfoList(changeInfoFilter(index, final_filter)).then((data) => {
			setshowSpin(false);
			user.info_cnt = data.count;
			if (data.length === 0 && index > 1) {
				previousPage();
			}
		}).catch((erorr) => {
			setshowSpin(false);
		})
	}

	const previousPage = () => {
		let currentIndex = list_indexObj.current_page;
		if (currentIndex > 1) {
			currentIndex -= 1;
		}
		list_indexObj.current_page = currentIndex;
		applyFilter(list_indexObj.current_page);
	}

	const nextPage = () => {
		let currentIndex = list_indexObj.current_page;
		if (currentIndex !== list_indexObj.last_page) {
			currentIndex += 1;
		}
		list_indexObj.current_page = currentIndex;
		applyFilter(list_indexObj.current_page);
	}


	return (
		<>
			<div className="infoListWrap">
				<div className="infoListMain">
					<Row gutter={30} justify="end">
						<Col xs={{ span: 24 }} >
							<div className="infoFilterSec">
								<div className="infoFilter">
									<RangePicker
										format='DD/MM/YYYY'
										onChange={handleDatePickerChange}
										value={dateRange}
									/>
								</div>
								<div className="infoFilter">
									<Select
										key="2"
										value={selectType}
										style={{ width: "120px" }}
										onChange={onSelectChange}
									>
										<Select.Option value={1}>Read</Select.Option>
										<Select.Option value={0}>Unread</Select.Option>
										<Select.Option value={null}>All</Select.Option>
									</Select>
								</div>
								<div className="infoFilter">
									<Button
										type="primary"
										title={"Apply"}
										className=""
										size="small"
										htmlType="submit"
										onClick={() => applyFilter()}
									>
										Apply
									</Button>
								</div>
							</div>
						</Col>
					</Row>
					{showSpin ?
						<div className="fullscreen__spinner">
							<Spin />
						</div>
						:
						null
					}

					{infoList ? <ul className="infoSection">{viewInformationList()}</ul> : <p className="noRecord">No Record Found</p>}
				</div>

				{infoList ?
					<div className="customPagination">
						<span className="btnPrevNext" onClick={() => previousPage()}><FontAwesomeIcon icon={faChevronLeft} /></span>
						<ul>
							{pagination()}
						</ul>
						<span className="btnPrevNext" onClick={() => nextPage()}><FontAwesomeIcon icon={faChevronRight} /></span>
					</div>
					:
					null
				}
			</div>
			<Modal
				className="addModal"
				centered
				title={infoModalData?.title}
				visible={infoModal}
				closeIcon={<FontAwesomeIcon icon={faTimes} />}
				onCancel={close}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				footer={[
					<Button
						key="2"
						htmlType="button"
						className="cancelBtn"
						onClick={close}
					>
						Close
					</Button>
				]}
			>
				<div className="infoModal">
					<div className="infoModalDate"><span><FontAwesomeIcon icon={faCalendarAlt} /></span>{infoModalData?.created}</div>
					<div className="infoModalNote" dangerouslySetInnerHTML={{ __html: infoModalData?.note }}></div>
				</div>
			</Modal>
		</>
	);
});

export default InformationComponent;
